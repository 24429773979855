import { AsyncAction, Action, pipe, map, mutate } from "overmind"


import { mapArray } from "state/utils";
import { Extension } from "./state";

export const getExtensions: AsyncAction = async ({ state, effects }) => {
    state.extensions.loading = true;
    const { extensions } = await effects.gql.queries.getExtensions();
    state.extensions.items = mapArray(extensions,'id');
    state.extensions.loading = false;
}

export const selectExtension: Action<Extension['id']> = pipe(
    // Select Extension
    mutate(({state,effects}, extensionId) => {
        // Set It As The Current One
        state.extensions.currentItemId = extensionId;
        // Save Setting To Local Storage
        effects.extensions.saveCurrentExtension(extensionId);
    }),
    // Subscribe To Extension Notifications
    mutate(({actions})=>{
        actions.notifications.updateNotificationHooks();
    })
)

// Extensions Bootstrap Sequence
export const bootstrapExtensions: AsyncAction = pipe(
    // Set Extensions State To Loading
    mutate(({state})=>{
        state.extensions.loading = true;
    }),
    // Get Extensions From API
    mutate(async ({actions}) => {
        await actions.extensions.getExtensions();
    }),
    // Load Saved Extension For Next Step
    map(({state, effects})=>{
        // Get Saved Extension From LocalStorage
        let selectedExtension = effects.extensions.loadCurrentExtension();
        // If Setting Stored In LocalStorage
        if (selectedExtension) {
            // Return Number Only If Still Available
            return state.extensions.items[selectedExtension];
        } else {
            return null;
        }    
    }),
    // Set Current Extension
    mutate(({actions,state,effects}, selectedExtension)=>{
        // If Saved In Storage
        if (selectedExtension) {
            actions.extensions.selectExtension(selectedExtension.id);
        // If Not Previously Set
        } else {
            // If We Only Have One Extension Available
            if (Object.keys(state.extensions.items).length === 1) {
                // Get That One Extensions
                let defaultExtensionId = Object.keys(state.extensions.items)[0];
                let defaultExtension = state.extensions.items[defaultExtensionId];
                actions.extensions.selectExtension(defaultExtension.id);
            }
        }
    }),
    // Set Extensions State To Loaded
    mutate(({state})=>{
        state.extensions.loading = false;
    }),
);