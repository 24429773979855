import React from 'react';
import styled from 'styled-components';
import { H4, Card, Callout, Button } from '@blueprintjs/core';

import { useOvermind } from 'state';

const WrapperComponent = styled.div`
    display: grid;
    place-items: center center;
`;

const Container = styled(Card)`
    width: 400px;
`;

const EnableNotificationsPrompt: React.FC = () => {

    const { actions } = useOvermind();

    const handleShowNotificationsPrompt= () => {
        actions.notifications.showNotificationsPrompt();
    }

    return (
        <WrapperComponent>
            <Container elevation={2}>
                <H4>Please Enable Notifications</H4>
                <Callout icon={null} intent='primary' style={{marginBottom:10}}>
                    <strong>FastPBX Link Web</strong> uses notifications to inform you of incoming calls and texts. 
                    In order for your browser to receive these notifications, please enable them for this app.
                </Callout>
                <Button large fill intent='primary' text='Enable Incoming Notifications' onClick={handleShowNotificationsPrompt} />
            </Container>
        </WrapperComponent>
    )

}

export default EnableNotificationsPrompt;