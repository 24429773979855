export const getMediaElement = (id: string): HTMLMediaElement | null => {
    let mediaElement: HTMLMediaElement = document.getElementById(id) as HTMLMediaElement;
    if (mediaElement) {
        return mediaElement;
    } else {
        return null;
    }
}

export const playMediaElement = async (id: string): Promise<void> => {
    let mediaElement = getMediaElement(id);
    if (mediaElement) {
        await mediaElement.play();
    }
}

export const pauseMediaElement = (id: string): void => {
    let mediaElement = getMediaElement(id);
    if (mediaElement) {
        mediaElement.pause();
        mediaElement.currentTime = 0;
    }
}