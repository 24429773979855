import React from 'react';
import styled from 'styled-components';
import { Colors, Tooltip } from '@blueprintjs/core';
import classnames from 'classnames';

import { Entry, CallEntry, MessageEntry } from 'state/entries/state';
import { formatTimeStamp, formatTimeStampRelative } from 'utils/dates';

import Icon, { IconName } from 'components/ui/Icon';
import EntryTypeCall from './EntryTypeCall';
import EntryTypeMessage from './EntryTypeMessage';
import { useOvermind } from 'state';
import { capitalizeWords } from 'utils/strings';
import EntryTypeVoicemail from './EntryTypeVoicemail';
import EntryTypeFax from './EntryTypeFax';
import LoadingComponent from 'components/ui/LoadingComponent';

const EntryViewComponent= styled.div`
    display: flex;
    padding-bottom: 10px;
    &.out {
        flex-direction: row-reverse;
    }
    &:last-child {
        padding-bottom: 0;
    }
`;

const EntryViewWrapper= styled.div`
    max-width: 48%;
    display: flex;
    flex-direction: column;
    &.in {
        align-items: flex-start;
    }
    &.out {
        align-items: flex-end;
    }
    &.voicemail {
        width: 100%;
    }
`;

const EntryViewBubble= styled.div`
    border-radius: 6px;
    padding: 8px 10px;
    margin-bottom: 3px;
    &.in {
        color: ${Colors.BLACK};
        background: ${Colors.LIGHT_GRAY3};
    }
    &.out {
        color: ${Colors.WHITE};
        background: ${Colors.BLUE5};
    }
    &.voicemail {
        width: 100%;
    }
`;

const EntryTimeStamp = styled.div`
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted ${Colors.LIGHT_GRAY3};
    font-size: 13px;
    color: ${Colors.GRAY4};
`;

type EntryViewProps = {
    entry: Entry
}

const EntryIconComponent = styled.div`
    margin-right: 5px;
`;

const EntryFooter = styled.div`
    display: flex;
    align-items: center;
`;

export const EntryIcon= ({icon}: {icon: IconName}) => {
    return (
        <EntryIconComponent>
            <Icon name={icon} color={Colors.GRAY4} width={15} />
        </EntryIconComponent>
    )
}

export const EntrySendingIndicator = () => {
    return (
        <EntryViewComponent className="out">
            <EntryViewWrapper className="out">
                <EntryViewBubble className="out">
                    <LoadingComponent intent="none" size={30}/>
                </EntryViewBubble>
            </EntryViewWrapper>
        </EntryViewComponent>
    )
}

const EntryView: React.FC<EntryViewProps> = ({entry}) => {

    useOvermind();

    const renderEntryIcon = () => {
        switch (entry.type) {
            case 'call':
                return <EntryIcon icon="phone" />
            case 'text':
                return <EntryIcon icon="envelope" />
            case 'fax':
                return <EntryIcon icon="fax" />
            case 'voicemail':
                return <EntryIcon icon="voicemail" />
        }
    }

    const renderEntryBody = () => {
        switch (entry.type) {
            case 'call':
                return <EntryTypeCall entry={entry as CallEntry}/>
            case 'text':
                return <EntryTypeMessage entry={entry as MessageEntry}/>
            case 'fax':
                return <EntryTypeFax entry={entry as CallEntry}/>
            case 'voicemail':
                return <EntryTypeVoicemail entry={entry as MessageEntry}/>
        }
    }

    return (
        <EntryViewComponent className={entry.direction}>
            <EntryViewWrapper className={classnames(entry.direction, entry.type)}>
                <EntryViewBubble className={classnames(entry.direction, entry.type)}>
                    {renderEntryBody()} 
                </EntryViewBubble>
                <EntryFooter>
                    {renderEntryIcon()}
                    <Tooltip boundary="window" content={<span>{capitalizeWords(formatTimeStampRelative(entry.time_stamp, true))} - {formatTimeStamp(entry.time_stamp, 'long')} - {formatTimeStamp(entry.time_stamp)}</span>}>
                        <EntryTimeStamp>
                            {formatTimeStamp(entry.time_stamp)}
                        </EntryTimeStamp> 
                    </Tooltip>
                </EntryFooter>
            </EntryViewWrapper>
        </EntryViewComponent>
    );

}

export default EntryView;