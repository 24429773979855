import React from 'react';
import styled from 'styled-components';
import CustomScroll from 'react-custom-scroll';

import { useOvermind } from 'state';
import { CallLogEntry } from 'state/entries/state';
import { groupEntriesByDate } from 'state/entries/utils';

import { DateLabel, LoadMore } from 'components/entries/EntryList';
import { EntryItemAdvancedTable } from 'components/entries/EntryItemAdvanced';
import LoadingComponent from 'components/ui/LoadingComponent';

const Wrapper = styled.div`
    height: calc(100vh - 107px);
`;

const LoadingWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
`;

const CallDashboard = styled.div`
    height: calc(100vh - 107px);
    display: grid;
    grid-template-columns: 1fr;
`;

const CallLogPane = styled.div`
    height: calc(100vh - 107px);
`;

const ListComponentHeader = styled.tr`
    border: 1px solid ${props => props.theme.mainBorder};
    background: ${props => props.theme.lightBG};
`;

const ListComponent = styled.div`
    padding: 20px;
    & table {
        border-collapse: collapse;
        width:100%
    }
    & table th {
        text-align: left;
    }
    & table th {
        padding: 12px 6px;
    }
    & table td {
        padding: 12px 6px;
    }
    & table td.actions {
        padding: 12px;
    }
    & table th.right {
        text-align: right;
    }
    & table td.right {
        text-align: right;
    }
`;

const AdvacedCallLog = () => {

    const {  
        state: { entries: { itemsList: entries } }, 
        actions: {  entries: { getCallLogEntries, getMoreCallLogEntries } }, 
        reaction 
    } = useOvermind();

    const [isLoading, setLoading] = React.useState(true);
    const [isLoadingMore, setLoadingMore] = React.useState(false);

    React.useEffect(() => {
        return reaction(
            (state) => state.entries.loading,
            (loadingStatus) => {
                setLoading(loadingStatus);
            }
        )
    }, [reaction]);

    React.useEffect(() => {
        return reaction(
            (state) => state.entries.loadingMore,
            (loadingMore) => {
                setLoadingMore(loadingMore);
            }
        )
    }, [reaction]);

    React.useEffect(() => {
        getCallLogEntries();
    }, [getCallLogEntries]);

    let dates = groupEntriesByDate(entries);

    const handleClick = () => {
        console.log('hi')
    }

    return (
        <Wrapper>
            {isLoading ? (
                <LoadingWrapper><LoadingComponent /></LoadingWrapper>
            ) : (
                    <CallDashboard>
                        <CallLogPane>
                            <CustomScroll
                                heightRelativeToParent="100%" 
                            >
                                <ListComponent>
                                    <table>
                                    <thead>
                                        <ListComponentHeader>
                                            <th></th>
                                            <th></th>
                                            <th>Direction</th>            
                                            <th>Number</th>
                                            <th>Contact Number</th>
                                            <th>Contact Details</th>
                                            <th>Date</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th className="right">Duration</th>
                                            <th></th>
                                            <th></th>
                                        </ListComponentHeader>
                                    </thead>
                                    <tbody>
                                        {dates.map((date, dateKey) => {
                                            let callCount = date.entries.length;
                                            return (
                                                <React.Fragment key={dateKey}>
                                                    <tr>
                                                        <td colSpan={12} style={{padding:0}}>
                                                            <DateLabel position="left" data-label={`${date.label} - ${callCount} ${(callCount>1||callCount===0?'Calls':'Call')}`} />
                                                        </td>
                                                    </tr>
                                                    {date.entries.map((entry, entryKey) => {
                                                        return (
                                                            <EntryItemAdvancedTable key={entryKey} entry={entry as CallLogEntry} onClick={handleClick} className={entryKey===0?'first-row':''}/>
                                                        )
                                                    })}
                                                </React.Fragment>
                                            )
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={12}>
                                                <LoadMore loading={isLoadingMore} onLoadMore={getMoreCallLogEntries} />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                                </ListComponent>
                            </CustomScroll>
                        </CallLogPane>
                    </CallDashboard>
                )}
        </Wrapper>
    );

}

export default AdvacedCallLog;