import { gql, Query } from 'overmind-graphql';
import { GetAnsweredCallsLog, GetAnsweredCallsLogVariables } from './_types/GetAnsweredCallsLog';
import { GetEntries, GetEntriesVariables } from './_types/GetEntries';
import { GetFilteredEntries, GetFilteredEntriesVariables } from './_types/GetFilteredEntries';

export const getEntries: Query<GetEntries, GetEntriesVariables> = gql`
  query GetEntries (
    $conversation_id: String!,
    $message_type: String,
    $limit: Int,
    $offset: Int
  ) {
    entries: GetConversationMessages (
        conversation_id: $conversation_id,
        message_type: $message_type,
        limit: $limit,
        offset: $offset
    ) {
        id
        body
        type
        direction
        status
        time_stamp
        attachments {
          url
          filename
          extension
        }
    }
  }
` as any;

export const getFilteredEntries: Query<GetFilteredEntries, GetFilteredEntriesVariables> = gql`
  query GetFilteredEntries (
    $number_id: String!,
    $message_type: String,
    $direction: String,
    $limit: Int,
    $offset: Int
  ) {
    entries: GetNumberMessages (
        number_id: $number_id,
        message_type: $message_type,
        direction: $direction,
        limit: $limit,
        offset: $offset
    ) {
        id
        body
        type
        direction
        status
        time_stamp
        attachments {
          url
          filename
          extension
        }
        number {
          id
          label
          didnumber
        }
        conversation {
          id
          status
          contact_number
          contact_id
          unread_messages
        }
    }
  }
` as any;

export const getAnsweredCallsLog: Query<GetAnsweredCallsLog, GetAnsweredCallsLogVariables> = gql`
  query GetAnsweredCallsLog (
    $number_id: String!,
    $limit: Int,
    $offset: Int
  ) {
    entries: GetCallLog (
        number_id: $number_id,
        limit: $limit,
        offset: $offset
    ) {
        id
        body
        type
        direction
        status
        time_stamp
        attachments {
          url
          filename
          extension
        }
        conversation {
          id
          status
          contact_number
          contact_id
          unread_messages
        }
        number {
          id
          label
          didnumber
        }
    }
  }
` as any;