import { gql, Query } from 'overmind-graphql';
import { GetNumbers, GetNumbersVariables } from './_types/GetNumbers';

export const getNumbers: Query<GetNumbers, GetNumbersVariables> = gql`
  query GetNumbers($extension_id: String!) {
    numbers: GetExtensionNumbers(extension_id: $extension_id) {
        id
        extension_id
        number_id
        number {
          id
          label
          didnumber       
        }
    } 
  }
` as any;