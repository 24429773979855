import React from 'react';
import styled from 'styled-components';

import { MessageAttachments, VoicemailEntry } from 'state/entries/state';
import { Slider, Button } from '@blueprintjs/core';
import { formatTimeDuration } from 'utils/dates';

type EntryTypeProps = {
    entry: VoicemailEntry;
}

type VoicemailAttachmentsProps = {
    entry: VoicemailEntry,
    attachments: MessageAttachments[];
} 

type AudioPlayerProps = {
    entry: VoicemailEntry,
    attachment: MessageAttachments
}

const AudioPlayerComponent = styled.div``;

const AudioTrackWrapper = styled.div`
    padding: 0 6px;
    flex: 1;
`;

const AudioTrack = styled(Slider)`
    & .bp3-slider {
        height: 12px
    }
    & .bp3-slider-handle {
        border-radius: 50%;
        top: 2px;
        height: 12px;
        width: 12px;
    }
`;

const MediaButton = styled(Button)`
    border-radius: 50%;
    & .bp3-icon-play {
        padding-left: 2px;
    }
`;

const Player = styled.div`
    display: flex;
`;

const RightControls = styled.div``;

const PlayerBody = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
`;

const Duration = styled.small`
    padding: 0 6px;
    font-family: monospace;
`;

const LeftControls = styled.div``;

const AudioPlayer: React.FC<AudioPlayerProps> = ({entry, attachment}) => {

    const meta = entry.body ? JSON.parse(entry.body) : { duration: 0 };
    
    const [ playing, setPlaying ] = React.useState(false);
    const [ duration, setDuration ] = React.useState(0);
    const [ audioPosition, setAudioPosition ] = React.useState(0);

    const audio = React.useRef<HTMLMediaElement>(null);

    const playAudio = async () => {
        if(audio.current) {
            setPlaying(true);
            await audio.current.play();
        }
    }

    const pauseAudio = () => {
        if(audio.current) {
            setPlaying(false);
            audio.current.pause();
        }
    }

    const stopAudio = () => {
        if(audio.current) {
            setPlaying(false);
            audio.current.pause();
            audio.current.currentTime = 0;
        }
    }

    const onLoadMetadata = (event: Event) => {
        if(event.srcElement) {
            let element = event.srcElement as HTMLMediaElement;
            setDuration(element.duration);
            setAudioPosition(element.currentTime)
        }
    }

    const onPositionChange = (event: Event) => {
        if(event.srcElement) {
            let element = event.srcElement as HTMLMediaElement;
            setAudioPosition(element.currentTime)
        }
    }

    const onUpdatePosition = (time: number) => {
        if(audio.current) {
            audio.current.currentTime = time;
        }
    }

    React.useEffect(()=>{
        let audiElement = audio.current;
        if (audiElement) {
            audiElement.addEventListener('loadedmetadata', onLoadMetadata);
            audiElement.addEventListener('timeupdate', onPositionChange);
            return () => {
                if (audiElement) {
                    audiElement.removeEventListener('loadedmetadata', onLoadMetadata);
                    audiElement.addEventListener('timeupdate', onPositionChange);
                }
            }
        }
    },[audio])

    return (
        <AudioPlayerComponent>
            <audio ref={audio}>
                <source src={attachment.url} type="audio/mpeg" />
            </audio>
            <Player>
                <RightControls>
                    {playing?(
                        <MediaButton outlined icon="pause" onClick={pauseAudio} />
                    ):(
                        <MediaButton outlined icon="play" onClick={playAudio} />
                    )}
                </RightControls>
                <PlayerBody>
                    <Duration>{formatTimeDuration(audioPosition)}</Duration>
                    <AudioTrackWrapper>
                        <AudioTrack labelRenderer={false} max={meta.duration} value={audioPosition} onRelease={onUpdatePosition} onChange={onUpdatePosition}/>
                    </AudioTrackWrapper>
                    <Duration>{formatTimeDuration(duration)}</Duration>
                </PlayerBody>
                <LeftControls>
                    <MediaButton outlined icon="stop" onClick={stopAudio} />
                </LeftControls>
            </Player>
        </AudioPlayerComponent>
    )
}

const Attachments: React.FC<VoicemailAttachmentsProps> = ({entry, attachments}) => {

    return (
        <div>
            {attachments.map((attachment, key)=><AudioPlayer key={key} entry={entry} attachment={attachment}/>)}
        </div>
    )

}

const EntryTypeVoicemail: React.FC<EntryTypeProps> = ({entry}) => {
    
    const renderAttachments = () => {
        if (entry.attachments?.length) {
            return <Attachments entry={entry} attachments={entry.attachments}/>
        }
    }

    return (
        <div>
            {renderAttachments()}
        </div>
    );
}

export default EntryTypeVoicemail;