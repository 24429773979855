import { derived } from 'overmind';
import { GetExtensions_extensions } from '../effects/gql/extensions/_types/GetExtensions';

export type Extension = GetExtensions_extensions;

export type State = {
    loading: boolean;
    items: {
        [key: string]: Extension
    },
    itemsList: Extension[]
    currentItemId: string | null;
    currentItem: Extension | null;
}

export const state: State = {
    loading: false,
    items: {},
    itemsList: derived( (state: State) => Object.values(state.items) ),
    currentItemId: null,
    get currentItem() {
        return this.currentItemId ? this.items[this.currentItemId] : null
    }
}