import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Colors } from '@blueprintjs/core';
import { Routes, Route, useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import { useOvermind } from 'state';

import Icon from 'components/ui/Icon';

import LoadingComponent from 'components/ui/LoadingComponent';
import ErrorComponent from 'components/ui/ErrorComponent';

import ExtensionSelect from 'components/extensions/ExtensionSelect';
import NumberSelect from 'components/numbers/NumberSelect';
import EnableNotificationsPrompt from 'components/notifications/EnableNotificationsPrompt';
import { formatPhoneNumber } from 'utils/numbers';
import VoicemailLog from 'components/voicemail/VoicemailLog';
import FaxLog from 'components/faxes/FaxLog';
import ConversationView from 'components/conversations/ConversationView';
import NewEntryAction from 'components/entries/NewEntryAction';
import ProfileMenu from 'components/ui/ProfileMenu';
import AlertIcons from 'components/ui/AlertIcons';
import WhatsNew from 'components/ui/WhatsNew';
import AdvacedCallLog from 'components/calls/AdvancedCallLog';

const SkyAnimation = keyframes`
    0% { background-position: 0 0; }
    100% { background-position: 100% 0;}
`;

// Main Wrapper
const Wrapper = styled.div`
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 45px 1fr;
    grid-template-areas: "TopNav" "MainPanel";
`;

const LoadingWrapper = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    background: ${() => `url('${process.env.PUBLIC_URL}/clouds-bg.png') center top repeat-x`};
    animation: ${SkyAnimation} 180s linear;
    & .bp3-spinner {
        background: ${() => `url('${process.env.PUBLIC_URL}/icon-logo-white.png') center top repeat-x`};
        background-repeat: no-repeat;
        background-position: center center;
    }
    & .bp3-spinner .bp3-spinner-track {
        stroke: rgb(255 255 255);
    }
`;

// Top Navigation
const TopNav = styled.div`
    grid-area: TopNav;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #0d85c8;
`;
const Menu = styled.div`
    display: flex;
    align-items: flex-end;
    align-self: stretch;
`;
const MenuItem = styled.div`
    display: inline-block;
    position: relative;
    margin: 0 8px;
    &:before, &:after {
        position: absolute;
        content: " ";
        width: 8px; 
        height: 8px;
        background-color: ${Colors.LIGHT_GRAY1};
        bottom: 0;
        z-index: 1;
    }
    &:before {
        left: -8px;
    }
    &:after {
        right: -8px;
    }
    &.active:before, &.active:after {
        background-color: #fbfbfb;
    }
    & a.active {
        background-color: white;
        color: #565f67;
    }
`;
const MenuItemLink = styled.a`
    background-color: ${Colors.LIGHT_GRAY1};
    line-height: 35px;
    padding: 0 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: #656565;
    &:before, &:after {
        position: absolute;
        content: " ";
        width: 16px; 
        height: 16px;
        background-color: #0d85c8;
        border-radius: 50%;
        bottom: 0;
        z-index: 2;
    }
    &:before {
        left: -16px;
    }
    &:after {
        right: -16px;
    }
    &:hover {
        text-decoration: none;
    }
`;

const ActiveCallIcon = styled.span`
    display: flex;
    background-color: ${Colors.GREEN5};
    border-radius: 50%;
    margin-right: 5px;
`;

// Main Panel Nav
const MainPanel = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr calc(100vh - 107px);
`;
const NavWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid #E1E8ED;
    background: ${Colors.WHITE};
`;
const LeftNav = styled.div`
    
`;
const Brading = styled.div`
    display: flex;
    margin-right: 10px;
    margin-left: 15px;
`;

const BradingLogo = styled.img``;
const RightNav = styled.div`
`;
const Nav = styled.div`
    display: flex;
`;
const NavItem = styled.div`
    margin-right: 10px;
    padding: 17px 10px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    color: #738694;
    cursor: pointer;
    border-bottom: 3px solid white;
    &.active {
        border-bottom: 3px solid #0d85c9;
        color: black;
    }
    &:hover {
        border-bottom: 3px solid black;
    }
`;
const NavIcon = styled.div`
    margin-right: 5px;
`;
const NavLabel = styled.div`
    font-size: 15px;
`;

// Main Wrapper
const MainWindow = styled.div`
    background-color: #fbfbfb;
    padding: 0;
`;

const ActionArea = styled.div`
    margin-right: 10px;
    margin-left: auto;
    display: flex;
    align-items: center;
`;

const NumberTabs = () => {

    const { state, actions, reaction } = useOvermind();
    const [currentNumberId, setCurrentNumberId] = React.useState<string | null>(null);

    React.useEffect(() => {
        return reaction(
            (state) => state.numbers.currentItemId || null,
            (currentNumber) => {
                setCurrentNumberId(currentNumber);
            },
            { immediate: true }
        )
    }, [reaction]);

    const onNumberSelect = (numberId: string) => {
        actions.numbers.selectNumber(numberId);
    }

    let compactDisplay = state.numbers.itemsList.length >= 5;

    return (
        <Menu>
            {state.numbers.itemsList.map((item, key) => {
                let isActiveNumber = item.id === currentNumberId;
                let onActiveCall = state.phone.callStatus === 'connected' && state.phone.activeCallNumberId === item.number_id;
                return (
                    <MenuItem key={key} className={classnames({ active: isActiveNumber })}>
                        <MenuItemLink className={classnames({ active: isActiveNumber })} onClick={() => onNumberSelect(item.id)}>
                            {onActiveCall ? (<ActiveCallIcon><Icon block={true} name="circle" color="white" width={16} /></ActiveCallIcon>) : null}
                            {!compactDisplay ? (
                                <React.Fragment>
                                    <strong>{item.number?.label}&#160;-&#160;</strong>
                                    <small>{formatPhoneNumber(item.number?.didnumber)}</small>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <strong>{formatPhoneNumber(item.number?.didnumber)}</strong>
                                </React.Fragment>
                            )}

                        </MenuItemLink>
                    </MenuItem>
                )
            })}
        </Menu>
    )

}

const MainNav = () => {

    const navigate = useNavigate();
    const { actions, reaction } = useOvermind();
    const [currentRoute, setCurrentRoute] = React.useState('conversations');

    React.useEffect(() => {
        return reaction(
            (state) => state.app.route,
            (currentRoute) => setCurrentRoute(currentRoute),
            { immediate: true }
        )
    }, [reaction]);

    const onNavigate = async (route: string) => {
        await actions.app.updateNavigationState({ route });
    }

    React.useEffect(() => {
        return reaction(
            (state) => state.app.route,
            (route) => {
                let nextRoute = route === 'conversations' ? '' : route;
                navigate(`/${nextRoute}`);
            }
        )
    }, [reaction, navigate]);

    return (
        <NavWrapper>
            <LeftNav>
                <Nav>
                    <NavItem className={classnames({ active: currentRoute === 'conversations' })} onClick={() => onNavigate('conversations')}>
                        <NavIcon>
                            <Icon name="conversations" color={Colors.GRAY2} width={24} />
                        </NavIcon>
                        <NavLabel>Inbox</NavLabel>
                    </NavItem>
                    <NavItem className={classnames({ active: currentRoute === 'calls' })} onClick={() => onNavigate('calls')}>
                        <NavIcon>
                            <Icon name="phone" color={Colors.GRAY2} width={24} />
                        </NavIcon>
                        <NavLabel>Calls</NavLabel>
                    </NavItem>
                    {/* <NavItem className={classnames({ active: currentRoute==='voicemail' })} onClick={()=>onNavigate('voicemail')}>
                        <NavIcon>
                            <Icon name="voicemail" color={Colors.GRAY2} width={24} />
                        </NavIcon>
                        <NavLabel>Voicemail</NavLabel>
                    </NavItem>
                    <NavItem className={classnames({ active: currentRoute==='faxes' })} onClick={()=>onNavigate('faxes')}>
                        <NavIcon>
                        <Icon name="fax" color={Colors.GRAY2} width={24} />
                        </NavIcon>
                        <NavLabel>Faxes</NavLabel>
                    </NavItem> */}
                </Nav>
            </LeftNav>
            <RightNav>
                <NewEntryAction />
            </RightNav>
        </NavWrapper>
    )

}

type LayoutProps = {
    className?: string;
}

const Test: React.FC<LayoutProps> = ({ className }) => {

    const { state } = useOvermind();

    const renderError = (error: string) => (
        <LoadingWrapper className={className}>
            <ErrorComponent error={error} />
        </LoadingWrapper>
    );

    const renderLoading = () => (
        <LoadingWrapper className={className}>
            <LoadingComponent size={80} />
        </LoadingWrapper>
    );

    const renderLayout = () => (
        <Wrapper>
            <TopNav>
                <Brading>
                    <BradingLogo src={process.env.PUBLIC_URL + '/icon-logo-white.png'} />
                </Brading>
                <NumberTabs />
                <ActionArea>
                    <AlertIcons />
                    <ProfileMenu />
                    {!state.settings.sawUpdates ? <WhatsNew /> : null}
                </ActionArea>
            </TopNav>
            <MainPanel>
                <MainNav />
                <MainWindow>
                    <Routes>
                        <Route path="*">
                            <Route path="/" element={<ConversationView />} />
                            <Route path="/calls" element={<AdvacedCallLog />} />
                            <Route path="/voicemail" element={<VoicemailLog />} />
                            <Route path="/faxes" element={<FaxLog />} />
                        </Route>
                    </Routes>
                </MainWindow>
            </MainPanel>
        </Wrapper>
    );

    if (!state.user.loggedIn) {
        return renderLoading();
    }

    if (state.app.loading) {
        return renderLoading();
    }

    if (state.app.error) {
        return renderError(state.app.error);
    }

    switch (true) {
        case state.notifications.permission === 'default':
            return (
                <LoadingWrapper className={className}>
                    <EnableNotificationsPrompt />
                </LoadingWrapper>
            );
        case !state.extensions.currentItem:
            return (
                <LoadingWrapper className={className}>
                    <ExtensionSelect />
                </LoadingWrapper>
            );
        case !state.numbers.currentItem:
            return (
                <LoadingWrapper className={className}>
                    <NumberSelect />
                </LoadingWrapper>
            );
    }

    return state.extensions.loading ? renderLoading() : renderLayout();

}

export default Test;