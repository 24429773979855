import { gql, Query } from 'overmind-graphql';
import { CreateContact, CreateContactVariables } from './_types/CreateContact';
import { UpdateContactVariables, UpdateContact } from './_types/UpdateContact';
import { DeleteContact, DeleteContactVariables } from './_types/DeleteContact';
import { DeleteContactDetail, DeleteContactDetailVariables } from './_types/DeleteContactDetail';

export const createContact: Query<CreateContact, CreateContactVariables> = gql`
  mutation CreateContact ($input: WebContactInput!) {
      contact: CreateContactMutation(input: $input) {
        id
        first_name
        last_name
        contactdetails {
          id
          type
          label
          value
        }
      }
    }
` as any;

export const updateContact: Query<UpdateContact, UpdateContactVariables> = gql`
  mutation UpdateContact ($input: UpdateWebContactInput!) {
      contact: UpdateContactMutation(input: $input) {
        id
        first_name
        last_name
        contactdetails {
          id
          type
          label
          value
        }
      }
    }
` as any;

export const deleteContact: Query<DeleteContact, DeleteContactVariables> = gql`
  mutation DeleteContact ($id: String!) {
      contact: DeleteContactMutation(id: $id) {
        id
      }
    }
` as any;

export const deleteContactDetail: Query<DeleteContactDetail, DeleteContactDetailVariables> = gql`
  mutation DeleteContactDetail ($id: String!) {
      contactDetail: DeleteContactDetailMutation(id: $id) {
        id
        contact_id 
      }
    }
` as any;