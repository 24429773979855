import { gql, Query } from 'overmind-graphql';
import { CreateConversation, CreateConversationVariables } from './_types/CreateConversation';
import { ArchiveConversation, ArchiveConversationVariables } from './_types/ArchiveConversation';
import { DeleteConversation, DeleteConversationVariables } from './_types/DeleteConversation';
import { UnArchiveConversation, UnArchiveConversationVariables } from './_types/UnArchiveConversation';

export const createConversation: Query<CreateConversation, CreateConversationVariables> = gql`
  mutation CreateConversation ($number_id: String!, $number_to: String!) {
    conversation: CreateConversationMutation(number_id: $number_id, number_to: $number_to) {
      id
      status
      contact_number
      contact_id
      last_entry {
        id
        type
        status
        direction
        body
        time_stamp
      }
    }
  }
` as any;

export const archiveConversation: Query<ArchiveConversation, ArchiveConversationVariables> = gql`
  mutation ArchiveConversation ($conversation_id: String!) {
    conversation: ArchiveConversationMutation(conversation_id: $conversation_id) {
      id
      status
    }
  }
` as any;

export const unArchiveConversation: Query<UnArchiveConversation, UnArchiveConversationVariables> = gql`
  mutation UnArchiveConversation ($conversation_id: String!) {
    conversation: UnArchiveConversationMutation(conversation_id: $conversation_id) {
      id
      status
    }
  }
` as any;

export const deleteConversation: Query<DeleteConversation, DeleteConversationVariables> = gql`
  mutation DeleteConversation ($conversation_id: String!) {
    conversation: DeleteConversationMutation(conversation_id: $conversation_id) {
      id
    }
  }
` as any;