import { derived } from "overmind";
import { GetUser_user } from "state/effects/gql/user/_types/GetUser"

export type UserInfo = GetUser_user;

export type State = {
    loggedIn: boolean;
    info: UserInfo | null;
    settings: any;
    playerId: string | null;
}

export const state: State = {
    loggedIn: false,
    info: null,
    playerId: null,
    settings: derived( (state: State) => state.info && state.info?.user_data ? JSON.parse(state.info?.user_data) : {} ),
}