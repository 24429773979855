import { derived } from 'overmind';
import { filterByView, sortByActivity } from './utils';
import { Entry } from 'state/entries/state';

export type Conversation = {
    id: string;
    status: 'new' | 'read' | 'archived';
    contact_number: string;
    contact_id: string;
    last_entry: Entry | null
    unread_messages: number;
}

export type State = {
    loading: boolean;
    loadingItem: boolean;
    limit: number;
    offset: number;
    allLoaded: boolean;
    currentView: 'inbox' | 'unread' | 'archived',
    items: {
        [key: string]: Conversation
    },
    itemsList: Conversation[],
    currentItemId: string | null;
    preloadItemId: string | null;
    pendingAction: 'call' | 'text' | 'endcall' | null;
    currentItem: Conversation | null;   
    pendingActionDirection: 'in' | 'out' | null;
    listScrollToPosition: number;
}

export const state: State = {
    loading: true,
    loadingItem: false,
    limit: 10,
    offset: 0,
    allLoaded: false,
    currentView: 'inbox',
    items: {},
    itemsList: derived( (state: State) => sortByActivity(filterByView(state.items, state.currentView)) ),
    currentItemId: null,
    preloadItemId: null,
    pendingAction: null,
    pendingActionDirection: null,
    listScrollToPosition: 0,
    get currentItem() {
        return this.currentItemId ? this.items[this.currentItemId] : null
    }
}