import React from 'react';
import { Dialog, Classes, Colors } from '@blueprintjs/core';
import styled from 'styled-components';

import { useOvermind } from 'state';
import { formatPhoneNumber } from 'utils/numbers';
import { Number } from 'state/numbers/state';
import { GetNumbers_numbers } from 'state/effects/gql/numbers/_types/GetNumbers';
import { Extension } from 'state/extensions/state';
import { filterConfiguredExtensions } from 'state/extensions/utils';

const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;   
    cursor: pointer;
    border-bottom: 1px solid ${props => props.theme.altBorder};
    &:hover {
        background: ${props => props.theme.focusBG};
    }
    background: ${Colors.WHITE};
`;

type ExtensionSelectDialogProps = {
    isOpen: boolean;
    onClose(): void;
}

const ExtensionSelectDialog: React.FC<ExtensionSelectDialogProps> = ({isOpen,onClose}) => {

    const { state, actions, effects } = useOvermind();

    const [ extensionId, setExtensionId ] = React.useState<string|null>(null);
    const [ numbers, setNumbers ] = React.useState<(GetNumbers_numbers|null)[]>([]);

    const onExtensionSelected = async (extension: Extension) => {
        if (extension.extension?.id) {
            const { numbers } = await effects.gql.queries.getNumbers({extension_id: extension.extension?.id});
            if(numbers) {
                setNumbers(numbers);
            }
            setExtensionId(extension.id);
        }
    }

    const onNumberSelect = (number: Number) => {
        if(extensionId) {
            actions.extensions.selectExtension(extensionId);
        }
        if(number.id) {
            actions.numbers.selectNumber(number.id);
        }
        setExtensionId(null);
        onClose();
    }

    const renderExtensionSelect = () => {

        const renderNumbersCount = (numbers: any[] | null | undefined) => {
            if (!numbers) return '';
            if (numbers.length===1) {
                return `1 Number`;
            } else {
                return `${numbers.length} Numbers`;
            }
        }

        const configuredExtensions = filterConfiguredExtensions(state.extensions.itemsList);

        return (
            <div>
                {configuredExtensions.map((extension, k)=>{
                    return (
                        <ListItem key={k} onClick={()=>onExtensionSelected(extension)}>
                            <span>
                                <strong>{extension.extension?.label} </strong>
                                ({renderNumbersCount(extension.extension?.numbers)})
                            </span>
                            <span>{`#${extension.extension?.extnum}`}</span>
                        </ListItem>
                    )
                })}
            </div>
        )
    }
    
    const renderNumberSelect = () => {
        return (
            <div>
                {numbers.map((number, k)=>{
                    if(number){
                        return (
                            <ListItem key={k} onClick={()=>onNumberSelect(number)}>
                                <strong>{number.number?.label}</strong>
                                <span>{`${formatPhoneNumber(number.number?.didnumber)}`}</span>
                            </ListItem>
                        )
                    }
                    return null;
                })}
            </div>
        )
    }

    return (
        <Dialog
            title={!extensionId?'Select Extension':'Select Number'}
            isOpen={isOpen}
            onClose={onClose}
        >
            <div className={Classes.DIALOG_BODY} style={{marginBottom: 0}}>
                {!extensionId&&renderExtensionSelect()}
                {extensionId&&renderNumberSelect()}
            </div>
        </Dialog>
    );
}

export default ExtensionSelectDialog;