import { gql, Query } from 'overmind-graphql';
import { GetExtensions } from './_types/GetExtensions';

export const getExtensions: Query<GetExtensions> = gql`
  query GetExtensions {
    extensions: GetMyExtensions {
        id
        extension {
          id
          label
          extnum 
          remoteuser
          sipid
          numbers {
            id
            didnumber
            label
          }     
        }
    }
  }
` as any;