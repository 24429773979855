import areaCodesUSByState from './us_area_codes.json';

export const formatPhoneNumber = (number: string | null | undefined, showCode: boolean = false): string => {
  if (!number) return '';
  if (number.length < 9) return `#${number}`;
  var cleaned = ('' + number).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = showCode ? (match[1] ? '+1 ' : '') : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return 'Invalid Number';
}

export const extractAreaCode = (number: string): number | null => {

  let formattedNumber = formatPhoneNumber(number);
  if (formattedNumber === 'Invalid Number') return null;

  let regExp = /\(([^)]+)\)/;
  let match = regExp.exec(formattedNumber);

  if (!match) return null;
  if (!Array.isArray(match)) return null;
  if (match.length < 2) return null;

  return parseInt(match[1]);

}

export const lookUpAreaCode = (number: string): string => {

  let areaCodes: { [index: string]: number[] } = areaCodesUSByState;

  let numberAreaCode = extractAreaCode(number);

  if (!numberAreaCode) return 'Unknown Location';

  return Object.keys(areaCodes).filter((state: string) => {
    let currentState = areaCodes[state];
    if (numberAreaCode) {
      return currentState.includes(numberAreaCode);
    }
    return false;
  }).reduce((map, detail, index) => {
    return detail;
  }, '');

}