import React from 'react';
import styled from 'styled-components';
import { NonIdealState } from '@blueprintjs/core';
import { useOvermind } from 'state';

const ErrorWrapper = styled.div`
    display: grid;
    place-items: center center;
`;

type ErrorComponentProps = {
    error: string;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({error}) => {
    useOvermind();
    return (
        <ErrorWrapper>
            <NonIdealState
                icon="error"
                title="Oops"
                description={error}
            />
        </ErrorWrapper>
    );
}

export default ErrorComponent;