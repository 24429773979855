import React from 'react';
import { Button, Callout } from '@blueprintjs/core';
import { generateDeviceId } from 'utils/env';
import axios from 'axios';

import { LoginComponent, Heading, Container, InputField, LoginBrading, LoginBradingLogo, LoginLinkContainer } from './login.styles';
import Icon from 'components/ui/Icon';
import { Link } from 'react-router-dom';

const LoginAPI = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_REST_API_URL : process.env.REACT_APP_REST_API_URL_DEV,
    headers: {
        'api-key': process.env.REACT_APP_REST_API_KEY
    }
});

const Login = () => {

    const [ userEmail, setUserEmail ] = React.useState('');//davidmelo@desmdesigns.com
    const [ userPassword, setUserPassword ] = React.useState('');//nGsD3cvPfjgF
    const [ loading, setLoading ] = React.useState(false);
    const [ error, setError ] = React.useState<string|null>(null);
    const [ success, setSuccess ] = React.useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setError(null);
        if(userEmail.length>3&&userPassword.length>3) {

            setLoading(true);

            try {
                let response = await LoginAPI.post('/web-auth', {
                    device_id: generateDeviceId(),
                    email: userEmail,
                    password: userPassword
                });
                setLoading(false);
                if(response.data.success) {
                    localStorage.setItem('user.tk', response.data.token);
                    setSuccess(true);
                    window.location.hash = '/';
                    window.location.reload();
                } else {
                    setError(response.data.message);
                }

            } catch (e) {
                setLoading(false);
                setError(e.response.data.message);
            }

        } else {
            setError('Please Enter A Valid Email & Password');
        }
    }

    return (
        <LoginComponent>
            <LoginBrading>
                <LoginBradingLogo src={process.env.PUBLIC_URL + '/logo-white.png'} />
            </LoginBrading>
            <Heading>Sign In With Your Credentials</Heading>
            <Container>
                <form onSubmit={handleSubmit}>
                    <InputField 
                        fill 
                        large
                        placeholder="Email Address..."
                        value={userEmail}
                        onChange={(e: any) => setUserEmail(e.target.value)}
                        autoComplete="email"
                    />
                    <InputField 
                        fill 
                        large
                        type="password"
                        placeholder="Password"
                        value={userPassword}
                        onChange={(e: any) => setUserPassword(e.target.value)}
                        autoComplete="current-password"
                    />
                    <Button fill large intent="primary" text="Sign In" loading={loading} rightIcon={<Icon name="signIn" width={24} />} type="submit"/>
                </form>
                <LoginLinkContainer>
                    <Link to="scan"><small>Sign In With Mobile App</small></Link>
                </LoginLinkContainer>
            </Container>
            {error&&<Callout intent="danger">{error}</Callout>}
            {success&&<Callout intent="success">Successfully Signed In. Redirecting Now...</Callout>}

        </LoginComponent>
    );
}

export default Login;