import React from 'react';
import styled from 'styled-components';
import { H4, Drawer, Classes, Callout, Switch, Divider, Button, FormGroup, InputGroup } from '@blueprintjs/core';

import { useOvermind } from 'state';

const SettingsDivider = styled.div`
    margin: 25px 0;
`;

const NotificationsCallout = styled(Callout)`
    margin-top: 15px;
`;

type SettingsPanelProps = {
    isOpen: boolean;
    onClose: () => void;
}

const SettingsPanel: React.FC<SettingsPanelProps> = ({isOpen,onClose}) => {

    const { state, actions } = useOvermind();

    const [receiveCalls, toggleReceiveCalls] = React.useState(false);
    const [receiveTexts, toggleReceiveTexts] = React.useState(false);
    const [offlineNotifications, toggleOfflineNotifications] = React.useState(false);
    const [playCallSounds, togglePlayCallSounds] = React.useState(false);
    const [playTextSounds, togglePlayTextSounds] = React.useState(false);    
    const [dialingPrefix, setDialingPrefix] = React.useState<string>('');

    React.useEffect(()=>{
        toggleReceiveCalls(state.settings.receiveCalls);
        toggleReceiveTexts(state.settings.receiveTexts);
        toggleOfflineNotifications(state.settings.offlineNotifications);
        togglePlayCallSounds(state.settings.playCallSounds);
        togglePlayTextSounds(state.settings.playTextSounds);
        setDialingPrefix(state.settings.dialingPrefix);
    },[state.settings]);

    const onCancel = () => {
        toggleReceiveCalls(state.settings.receiveCalls);
        toggleReceiveTexts(state.settings.receiveTexts);
        toggleOfflineNotifications(state.settings.offlineNotifications);
        togglePlayCallSounds(state.settings.playCallSounds);
        togglePlayTextSounds(state.settings.playTextSounds);
        setDialingPrefix(state.settings.dialingPrefix);
        onClose();
    }

    const onSaveUserSettings = () => {
        actions.settings.saveUserSettings({
            receiveCalls,
            receiveTexts,
            offlineNotifications,
            playCallSounds,
            playTextSounds,
            dialingPrefix
        });
        onClose();
    }

    const onDialingPrefixChanged = (prefix: string) => {
        setDialingPrefix(prefix)
    }

    const onReceiveCallsChanged = (status: boolean) => {
        toggleReceiveCalls(status);
    }

    const onReceiveTextsChanged = (status: boolean) => {
        toggleReceiveTexts(status);
    }

    const onOfflineNotificationsChanged = (status: boolean) => {
        toggleOfflineNotifications(status);
    }

    const onPlayCallSoundsChanged = (status: boolean) => {
        togglePlayCallSounds(status);
    }

    const onPlayTextSoundsChanged = (status: boolean) => {
        togglePlayTextSounds(status);
    }

    return (
        <Drawer
            icon={null}
            title="Settings"
            size="500px"
            onClose={onCancel}
            isOpen={isOpen}
            canOutsideClickClose={false}
        >

            <div className={Classes.DRAWER_BODY}>
                <div className={Classes.DIALOG_BODY}>

                    <H4>Dialing Options</H4>
                    <FormGroup inline label="Dialing Prefix" labelFor="dialing-prefix">
                        <InputGroup autoComplete="nope" fill={false} id="dialing-prefix" value={dialingPrefix as string} onChange={(e: any)=>onDialingPrefixChanged(e.target.value)} style={{width:'30px'}}/>
                    </FormGroup>

                    <H4>Notifications</H4>

                    <Switch inline labelElement={"Show Incoming Call Notifications"} large checked={receiveCalls} onChange={(a: any)=>onReceiveCallsChanged(a.target.checked)} />
                    <Switch inline labelElement={"Show Incoming Text Notifications"} large checked={receiveTexts} onChange={(a: any)=>onReceiveTextsChanged(a.target.checked)} />
                    <Switch inline labelElement={"Show Notifications While Signed Out"} large checked={offlineNotifications} onChange={(a: any)=>onOfflineNotificationsChanged(a.target.checked)} />

                    <NotificationsCallout icon="warning-sign" intent="warning">
                        Disabling of specific notifications will prevent the app from updating when new calls or texts are received. If disabled, you will have to manually refresh the page to see any new items.
                    </NotificationsCallout>

                    <SettingsDivider>
                        <Divider/>
                    </SettingsDivider>

                    <H4>Sounds</H4>
                    <Switch inline labelElement={"Play Icoming Call Ringtone"} large checked={playCallSounds} onChange={(a: any)=>onPlayCallSoundsChanged(a.target.checked)} />
                    <Switch inline labelElement={"Play Icoming Text Sounds"} large checked={playTextSounds} onChange={(a: any)=>onPlayTextSoundsChanged(a.target.checked)} />

                </div>
            </div>

            <div className={Classes.DRAWER_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button large text="Save Settings" intent="success" onClick={onSaveUserSettings} />
                </div>  
            </div>   

        </Drawer>
    )

}

export default SettingsPanel;