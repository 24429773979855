import { AsyncAction, Action, pipe, mutate, map } from 'overmind';
import * as Sentry from '@sentry/browser';

import { State, Conversation } from './state';
import { getFirstConversationID } from './operators';

import { Contact, ContactDetail } from 'state/contacts/state';
import { getConversationByContactNumber } from './utils';
import { mapArray } from 'state/utils';
import { matchNumberToContactDetail, getMatchingDetails, getContactLabel } from 'state/contacts/utils';
import { playMediaElement } from 'utils/media';
import { NotificationToaster } from 'components/notifications/NotificationToaster';
import { formatPhoneNumber } from 'utils/numbers';
import { capitalizeWord } from 'utils/strings';
import { NotificationPayload } from 'state/notifications/state';

// Handle A Conversation Being Updated
export const onConversationUpdated: AsyncAction<NotificationPayload['data']> = pipe(
    mutate(({ state, actions }, notification) => {

        if (state.settings.playTextSounds) {
            playMediaElement('notification-audio');
        }

        let from = formatPhoneNumber(notification.from);
        let fromShort = formatPhoneNumber(notification.from);
        let contact = actions.contacts.findContactByNumber(notification.from);
        if (contact) {
            let contactDetail = getMatchingDetails(contact, notification.from);
            if (contactDetail) {
                from = `${getContactLabel(contact)} ${capitalizeWord(contactDetail.label)} - ${from}`;
                fromShort = `${getContactLabel(contact)} (${capitalizeWord(contactDetail.label)})`;
            }
        }
        NotificationToaster.show({
            icon: 'envelope',
            intent: 'success',
            message: `New Message From: ${from}`
        });
        actions.notifications.setBadgeCount(1);
        actions.notifications.flashTitleBarMessage({ message: `Message From: ${fromShort}`, duration: 0 });
    }),
    mutate(async ({ actions }, notification) => {
        actions.notifications.registerNotification(notification);
        // await actions.conversations.refreshConversation(notification.conversation_id);
    }),
    mutate(async ({ state, actions }, notification) => {
        // @TODO Needs to check or prevent switching from active conversation
        if (state.conversations.currentItemId === notification.conversation_id && ( window.location.hash === "#/" || window.location.hash === "" ) ) {
            await actions.conversations.loadConversation({ id: notification.conversation_id });
        } else {
        }
    })
)

// Refresh Specific Conversation
export const refreshConversation: AsyncAction<Conversation['id']> = pipe(
    mutate(({ state }, conversation_id) => {
        if (state.conversations.currentItemId === conversation_id) {
            state.conversations.loadingItem = true;
        }
    }),
    mutate(async ({ state, effects }, conversation_id) => {
        let number_id = state.numbers.currentItem?.number?.id;
        if (number_id) {
            let { conversation } = await effects.gql.queries.getConversation({ conversation_id, number_id });
            if (conversation) {
                state.conversations.items[conversation_id] = conversation as Conversation;
            }
        }
    }),
    mutate(({ state }, conversation_id) => {
        if (state.conversations.currentItemId === conversation_id) {
            state.conversations.loadingItem = false;
        }
    }),
)

export const archiveConversation: AsyncAction<Conversation['id']> = async ({ state, effects }, conversation_id) => {
    try {

        let { conversation: archivedConversation } = await effects.gql.mutations.archiveConversation({ conversation_id });
        if (archivedConversation) {
            state.conversations.items[archivedConversation.id] = {
                ...state.conversations.items[archivedConversation.id],
                status: archivedConversation.status as 'archived'
            }
            NotificationToaster.show({
                intent: 'success',
                message: 'Conversation Archived'
            });
        }

    } catch (e) {
        Sentry.captureException(e);
        NotificationToaster.show({
            icon: 'warning-sign',
            intent: 'danger',
            message: 'Error Archiving Conversation'
        });
    }
}

export const deleteConversation: AsyncAction<Conversation['id']> = pipe(
    // Delete The Conversation
    mutate(async ({ state, effects }, conversation_id) => {
        try {
            let { conversation: deletedConversation } = await effects.gql.mutations.deleteConversation({ conversation_id });
            if (deletedConversation) {
                delete state.conversations.items[deletedConversation.id];
                NotificationToaster.show({
                    intent: 'success',
                    message: 'Conversation Deleted'
                });
            }
        } catch (e) {
            Sentry.captureException(e);
            NotificationToaster.show({
                icon: 'warning-sign',
                intent: 'danger',
                message: 'Error Deleting Conversation'
            });
        }
    }),
    // Gets The ID Of The First Conversation
    getFirstConversationID(),
    // Runs The loadConversation Action
    mutate(async ({ actions }, conversationId) => {
        if (conversationId) {
            await actions.conversations.loadConversation({ id: conversationId })
        }
    }),
)

export const unArchiveConversation: AsyncAction<Conversation['id']> = async ({ state, effects }, conversation_id) => {
    try {

        let { conversation } = await effects.gql.mutations.unArchiveConversation({ conversation_id });
        if (conversation) {
            state.conversations.items[conversation.id] = {
                ...state.conversations.items[conversation.id],
                status: conversation.status as 'read'
            }
            NotificationToaster.show({
                intent: 'success',
                message: 'Conversation UnArchived'
            });
        }

    } catch (e) {
        Sentry.captureException(e);
        NotificationToaster.show({
            icon: 'warning-sign',
            intent: 'danger',
            message: 'Error UnArchiving Conversation'
        });
    }
}

// Fetch Conversations From API
export const getConversations: AsyncAction = async ({ state, effects }) => {
    let number_id = state.numbers.currentItem?.number?.id;
    if (number_id) {
        let { conversations } = await effects.gql.queries.getConversations({
            number_id,
            type_filter: 'all',
            limit: state.conversations.limit,
            offset: state.conversations.offset,
        });
        if (conversations) {
            let data = mapArray(conversations, 'id');
            if (state.conversations.offset > 0) {
                state.conversations.items = { ...state.conversations.items, ...data }
            } else {
                state.conversations.items = data;
            }
            if (!conversations.length) {
                state.conversations.allLoaded = true;
            }
        }
    }
}

export const resetPagination: Action = ({ state }) => {
    state.conversations.offset = 0;
}

export const loadMore: AsyncAction = async ({ state, actions }) => {
    state.conversations.offset = state.conversations.offset + state.conversations.limit;
    actions.conversations.getConversations();
}

// Loads All Conversations & Selects First Conversation As Current
export const loadConversations: AsyncAction = pipe(
    mutate(({ state }) => {
        state.conversations.loading = true;
    }),
    // Gets The Conversations
    mutate(async ({ actions }) => {
        await actions.conversations.getConversations();
    }),
    // Gets The ID Of The First Conversation
    getFirstConversationID(),
    // Runs The loadConversation Action
    mutate(async ({ actions }, conversationId) => {
        if (conversationId) {
            await actions.conversations.loadConversation({ id: conversationId })
        }
    }),
    mutate(({ state }) => {
        state.conversations.loading = false;
    })
)

// Loads The Selected View For Filterting Conversations
export const loadView: AsyncAction<State['currentView']> = pipe(
    mutate(({ state }) => {
        state.entries.loading = true;
    }),
    // Selects The Selected View As The Current View
    mutate(({ state }, selectedView) => {
        state.conversations.currentView = selectedView;
    }),
    // Gets The ID Of The First Conversation
    getFirstConversationID(),
    // Runs The loadConversation Action
    mutate(async ({ actions }, conversationId) => {
        if (conversationId) {
            await actions.conversations.loadConversation({ id: conversationId })
        }
    })
)

type LoadConversationInput = {
    id: Conversation['id'];
    skipEntries?: boolean;
}

// Loads A Specific Conversation, Gets The Entries, Selects The Contact
export const loadConversation: AsyncAction<LoadConversationInput, boolean> = pipe(
    mutate(({ state }) => {
        state.conversations.loadingItem = true;
    }),
    // Select The Conversation As The Current One
    mutate(({ state }, { id }) => {
        state.conversations.currentItemId = id;
        state.conversations.items[id].unread_messages = 0;
    }),
    // Runs The getConversationEntries Action
    mutate(async ({ state, actions }, { id, skipEntries }) => {
        if (!skipEntries) {
            await actions.entries.getConversationEntries(id);
        }

    }),
    // Gets The Contact ID Of The Selected Conversation
    map(({ state }) => {
        return state.conversations.currentItem?.contact_id;
    }),
    // Runs The Select Contact Conversation
    mutate(({ state, actions }, contactId) => {
        if (contactId) {
            actions.contacts.selectContact(contactId);
        } else {
            state.contacts.currentItemId = null;
        }
    }),
    mutate(({ state }, conversationId) => {
        state.conversations.loadingItem = false;
    }),
)

// Loads A Specific Conversation, Gets The Entries, Selects The Contact
export const loadExistingConversation: AsyncAction<string, boolean> = async ({ state, actions, effects }, destination_number) => {
    state.conversations.loadingItem = true;
    let number_id = state.numbers.currentItem?.number?.id;
    if (number_id) {
        let { conversation } = await effects.gql.queries.getExistingConversation({
            destination_number,
            number_id: number_id
        })
        if (conversation) {
            state.conversations.items = { [conversation.id]: conversation as Conversation, ...state.conversations.items };
            state.conversations.currentItemId = conversation.id;
            state.conversations.listScrollToPosition = 999;
            await actions.entries.getConversationEntries(conversation.id);
            if (state.conversations.currentItem?.contact_id) {
                actions.contacts.selectContact(state.conversations.currentItem?.contact_id);
            } else {
                state.contacts.currentItemId = null;
            }
            state.conversations.loadingItem = false;
            return true;
        }
        state.conversations.loadingItem = false;
        return false;
    }
    state.conversations.loadingItem = false;
    return false;
}

export const createConversation: AsyncAction<string, Conversation | null> = async ({ state, effects }, number_to) => {
    try {

        let number_id = state.numbers.currentItem?.number?.id;
        if (number_id) {
            let { conversation } = await effects.gql.mutations.createConversation({
                number_id,
                number_to
            });
            if (conversation) {
                state.conversations.items[conversation.id] = conversation as Conversation;
                // NotificationToaster.show({
                //     intent: 'success',
                //     message: 'Conversation Created'
                // });
                return conversation as Conversation;
            }
        }
        return null;

    } catch (e) {
        Sentry.captureException(e);
        // NotificationToaster.show({
        //     icon: 'warning-sign',
        //     intent: 'danger',
        //     message: 'Error Creating Conversation'
        // });
        return null;
    }
}

export type CreateConversationEntryInput = {
    type: 'call' | 'text';
    contact?: Contact | null;
    contactDetail?: ContactDetail | null;
    destinationNumber?: string;
    message?: string;
    dialingPrefix?: string;
}

export const createConversationEntry: AsyncAction<CreateConversationEntryInput> = async ({ state, effects, actions }, input) => {

    // If Entry Type Is Call, But Other Call Is Active, Ignore
    // @TODO Handle This Type Of Situation
    if (input.type === 'call' && state.phone.networkStatus !== 'offline' && state.phone.networkStatus !== 'disconnected') {
        return;
    }

    // Get Destination Number
    let destinationNumber = input.destinationNumber;

    // If We Have A Contact Details
    if (input.contactDetail) {
        // Set The Value As The Destination Number
        destinationNumber = input.contactDetail.value;
    } else if (input.destinationNumber) {
        // Let's Try And Match The Destination Number To A Contact
        let matchedDetail = matchNumberToContactDetail(state.contacts.itemsList, input.destinationNumber);
        // If We Have A Matched Details
        if (matchedDetail) {
            // Set The Value As The Destination Number
            destinationNumber = matchedDetail.value;
        }
    }

    // If We Dont Have A Destination Number, Let's Return
    if (!destinationNumber) return;

    // Get Local Conversation For Destination Number
    let conversation = getConversationByContactNumber(destinationNumber, state.conversations.items);

    // If There Is No Local Conversation
    if (!conversation && destinationNumber) {
        // Create A New Conversations
        conversation = await actions.conversations.createConversation(destinationNumber);
    }

    if (conversation) {

        if (window.location.hash.indexOf('/calls') > 0) {
            if (input.type === 'call') {
                await actions.entries.getCallLogEntries();
                await actions.conversations.loadConversation({ id: conversation.id, skipEntries: true });
            } else {
                state.conversations.currentItemId = conversation.id;
            }
        } else {
            // Load The Conversation To The UI
            await actions.conversations.loadConversation({ id: conversation.id });
        }

        // If New Entry Is Text Message
        if (input.type === 'text' && input.message?.length) {
            await actions.entries.createEntry({
                type: input.type,
                body: input.message
            });
        }

        // If New Entry Is Call
        if (input.type === 'call') {
            if (input.dialingPrefix) {
                state.phone.currentDialingPrefix = input.dialingPrefix;
            }
            await actions.entries.createEntry({
                type: input.type
            })
        }

    }

    // Set Pending Action For Next UI Interactions
    state.conversations.pendingAction = input.type;
    state.conversations.pendingActionDirection = 'out';

}

export const clearPendingAction: Action = ({ state }) => {
    state.conversations.pendingAction = null;
}

export const setPendingAction: Action<State['pendingAction']> = ({ state }, action) => {
    state.conversations.pendingAction = action;
}