import React from 'react';
import styled from "styled-components";
import { H3, Dialog, Classes, Button } from '@blueprintjs/core';

import { useOvermind } from 'state';
import { Contact } from 'state/contacts/state';

import { getContactLabel, getMatchingDetails } from 'state/contacts/utils';
import { capitalizeWord } from 'utils/strings';
import { formatPhoneNumber } from 'utils/numbers';

import { ControlledAudioComponent } from 'components/phone/PhoneSounds';

const CallDialog = styled(Dialog)`
    width: 300px;
    & .bp3-heading {
        text-align: center;
    }
    & .bp3-dialog-body {
        text-align: center;
    }
`;

const CallActions = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
`;

const IncomingCallDialog = () => {

    const { state, actions, reaction } = useOvermind();
    const [ isOpen, toggleOpen ] = React.useState(false);
    const [ fromNumber, setFromNumber ] = React.useState<string|null>(null);
    const [ toNumber, setToNumber ] = React.useState<string|null>(null);
    const [ toLabel, setToLabel ] = React.useState<string|null>(null);
    const [ contact, setContact ] = React.useState<Contact|null>(null)

    const ringtone = React.useRef<HTMLAudioElement>(null);

    const startRingTone = React.useCallback(async () => {
        if(ringtone.current){
            if (state.settings.playCallSounds) {
                try {
                    await ringtone.current.play();
                } catch (e) {
                    console.log('Could Not Play Sound')
                }
            }
        }
    },[state.settings.playCallSounds])

    const stopRingTone = React.useCallback(() => {
        if(ringtone.current) {
            if (state.settings.playCallSounds) {
                ringtone.current.pause();
                ringtone.current.currentTime = 0;
            }
        }
    },[state.settings.playCallSounds])

    React.useEffect(()=>{
        let timer: number | null = null;
        if(isOpen){
            startRingTone();
            timer = window.setTimeout(()=>{
                toggleOpen(false);
                stopRingTone();
                actions.phone.missedIncomingCall();
            },50000);
        } else if (timer) {
            stopRingTone();
            clearTimeout(timer);
        }
        return () => {
            if (timer) {
                stopRingTone();
                clearTimeout(timer);
            }
        }
    },[isOpen, actions.phone, startRingTone, stopRingTone]);

    React.useEffect(() => {
        return reaction(
            (state) => ({
                incomingCall: state.phone.incomingCall,
                fromNumber: state.phone.incomingCallFrom,
                toNumber: state.phone.incomingCallTo,
                toLabel: state.phone.incomingCallToLabel
            }),
            (inbound) => {
                if(inbound.incomingCall) {
                    toggleOpen(true);
                    setFromNumber(inbound.fromNumber);
                    setToNumber(inbound.toNumber);
                    setToLabel(inbound.toLabel);
                    if (inbound.fromNumber) {
                        let contact = actions.contacts.findContactByNumber(inbound.fromNumber);
                        let contactDetail = getMatchingDetails(contact, inbound.fromNumber);
                        setContact(contact);
                        let from = formatPhoneNumber(inbound.fromNumber)
                        if (contactDetail) {
                            from = `${getContactLabel(contact)} (${capitalizeWord(contactDetail.label)})`;
                        }
                        actions.notifications.flashTitleBarCall({message: `Call From: ${from}`, duration: 60000});
                    }
                } else {
                    toggleOpen(false);
                }
            }
        )
    }, [reaction, actions.contacts, actions.notifications])

    const handleAccept = () => {
        toggleOpen(false);
        stopRingTone();
        actions.phone.acceptIncomingCall();
    }

    const handleReject = () => {
        toggleOpen(false);
        stopRingTone();
        actions.phone.rejectIncomingCall();
    }

    const renderContact = () => {

        if (contact&&fromNumber) {
            let contactDetail = getMatchingDetails(contact, fromNumber);
            return (
                <div style={{marginBottom:10}}>
                    <small className='bp3-text-muted'>From</small>
                    <H3 style={{marginBottom:0}}>{getContactLabel(contact)}</H3>
                    {contactDetail&&(
                        <span>
                            <strong className='bp3-text-muted'>{capitalizeWord(contactDetail.label)}: </strong> 
                            {formatPhoneNumber(contactDetail.value)}
                        </span>
                    )}
                </div>
            )
        }

        return (
            <div style={{marginBottom:10}}>
                <small className='bp3-text-muted'>From</small>
                <H3 style={{marginBottom:0}}>{formatPhoneNumber(fromNumber)}</H3>
            </div>
        )

    }

    const renderTarget= () => {

        return (
            <div>
                <small className='bp3-text-muted'>To</small>
                <H3 style={{marginBottom:0}}>{toLabel}</H3>
                <strong className='bp3-text-muted'>{formatPhoneNumber(toNumber)}</strong> 
            </div>
        )

    }

    return (
        <React.Fragment>
            <CallDialog
                title='Incoming Call'
                isCloseButtonShown={false}
                isOpen={isOpen}
            >
                <div className={Classes.DIALOG_BODY}>
                    {renderContact()}
                    {renderTarget()}
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <CallActions>
                        <Button text='Decline' large intent='danger' onClick={handleReject} />
                        <Button text='Answer' large intent='success' onClick={handleAccept} />
                    </CallActions>
                </div>
            </CallDialog>
            <ControlledAudioComponent ref={ringtone} id="inbound-audio" src="ringtone.mp3" loop={true} />
        </React.Fragment>
    );

}

export default IncomingCallDialog;