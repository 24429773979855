import React from 'react';
import { Colors } from '@blueprintjs/core';
import styled from 'styled-components';

import { CallEntry } from 'state/entries/state';
import { generateCallSummary } from 'state/entries/utils';
import { DirectionalArrow } from './EntryPreview';

type EntryTypeProps = {
    entry: CallEntry;
}

const CallEntryComponent = styled.div`
    display: flex;
    align-items: center;
`;

const EntryTypeCall: React.FC<EntryTypeProps> = ({entry}) => {
    return (
        <CallEntryComponent>
            <DirectionalArrow direction={entry.direction} color={(entry.status==='missed'||entry.status==='declined')?Colors.RED3:Colors.GREEN3} />
            <span>{generateCallSummary(entry)}</span>
        </CallEntryComponent>
    );
}

export default EntryTypeCall;