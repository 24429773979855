import React from 'react';
import { Button, Spinner, Callout } from '@blueprintjs/core';
import { generateDeviceId } from 'utils/env';
import axios from 'axios';
import QRCode from 'qrcode.react';
import { setIntervalAsync } from 'set-interval-async/fixed';
import { clearIntervalAsync } from 'set-interval-async'; 

import { LoginComponent, Heading, Container, QRCodeContainer, LoginBrading, LoginBradingLogo, HelpText, LoginLinkContainer } from './login.styles';
import Icon from 'components/ui/Icon';
import { Link } from 'react-router-dom';

const LoginAPI = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_REST_API_URL : process.env.REACT_APP_REST_API_URL_DEV,
    headers: {
        'api-key': process.env.REACT_APP_REST_API_KEY
    }
});

const Login = () => {

    const [ QRdata, setQRdata ] = React.useState<string|null>(null);
    const [ loading, setLoading ] = React.useState(false);
    const [ error, setError ] = React.useState<string|null>(null);
    const [ requestSuccess, setRequestSuccess ] = React.useState(false);
    const [ pingSuccess, setPingSuccess ] = React.useState(false);

    React.useEffect(()=>{

        if ( QRdata ) {

            setLoading(true);

            const tokenPing = setIntervalAsync(async () => {
        
                try {
                    let response = await LoginAPI.post('/link-auth-web-ping', QRdata);
                    if(response.data.success) {
                        clearIntervalAsync(tokenPing);
                        setLoading(false);
                        setPingSuccess(true);
                        localStorage.setItem('user.tk', response.data.token);
                        window.location.hash = '/';
                        window.location.reload();
                    }
                } catch (e) {
                    setError(e.response.data.message);
                    clearIntervalAsync(tokenPing);
                    setLoading(false);
                    setPingSuccess(false);
                }

            }, 5000);

        }
        
    },[QRdata]);

    const handleSubmit = async () => {
        setError(null);
        setLoading(true);
        try {
            let response = await LoginAPI.post('/request-auth-web', {
                device_id: generateDeviceId()
            });
            setLoading(false);
            if(response.data.success) {
                setRequestSuccess(true);                    
                setQRdata(response.data);
            } else {
                setError(response.data.message);
            }

        } catch (e) { 
            setLoading(false);
            setError(e.response.data.message);
        }

    }

    const renderForm = () => {
        return (
            <LoginComponent>
                <LoginBrading>
                    <LoginBradingLogo src={process.env.PUBLIC_URL + '/logo-white.png'} />
                </LoginBrading>
                <Heading>Use The Mobile App To Login</Heading>
                <Container>
                    <Button fill large intent="primary" text="Get QR Code" loading={loading} rightIcon={<Icon name="qrcode" width={24}/>} onClick={handleSubmit} />
                    <LoginLinkContainer>
                        <Link to="/login"><small>Sign In With Password</small></Link>
                    </LoginLinkContainer>
                </Container>
                {error&&<Callout intent="danger">{error}</Callout>}
            </LoginComponent>
        )
    }

    const renderQR = () => {
        return (
            <LoginComponent>
                {pingSuccess?(
                    <div>
                        <Callout intent="success">Login Successfully Verified, Redirecting...</Callout>
                    </div>
                ):(
                    <div>
                        <Heading>Scan The QR Code With Your App</Heading>
                        <QRCodeContainer>
                            <QRCode value={JSON.stringify(QRdata)} size={300}/>    
                        </QRCodeContainer>              
                        <HelpText>Scan the QR Code above with your FastPBX Link Mobile app to be automatically signed in.</HelpText>                              
                        {loading&&<Spinner intent="primary" />}
                        {error&&<Callout intent="danger">{error}</Callout>}
                    </div>
                )}
                
            </LoginComponent>
        )
    }

    return requestSuccess && QRdata ? renderQR() : renderForm();

}

export default Login;