import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Colors, H4, Tag } from '@blueprintjs/core';

import { useOvermind } from 'state';
import { Conversation } from 'state/conversations/state';
import { formatTimeDuration, formatTimeStampRelative } from 'utils/dates';
import { getContactLabel, getMatchingDetails } from 'state/contacts/utils';
import EntryPreview, { DirectionalArrow, PreviewIcon, PreviewIcons, PreviewWrapperComponent } from 'components/entries/EntryPreview';
import { formatPhoneNumber } from 'utils/numbers';
import { capitalizeWord } from 'utils/strings';
import classnames from 'classnames';

type ConversationListItemProps = {
    conversation: Conversation
}

const ConversationListItemComponent = styled.div`
    padding: 15px;   
    cursor: pointer;
    border-bottom: 1px solid ${props => props.theme.altBorder};
    &:hover {
        background: ${props => props.theme.hoverBG}
    }
    &.active {
        background: ${props => props.theme.focusBG}
    }
    &.call-active {
        -moz-box-shadow:    inset 0 0 3px #21a16cc7;
        -webkit-box-shadow: inset 0 0 3px #21a16cc7;
        box-shadow:         inset 0 0 3px #21a16cc7;

    }
    &.active.call-active {

    }
    &.call-connecting {
        -moz-box-shadow:    inset 0 0 3px #d9842f;
        -webkit-box-shadow: inset 0 0 3px #d9842f;
        box-shadow:         inset 0 0 3px #d9842f;
    }
    &.active.call-connecting {

    }
`;

const ConversationHeading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
`;

const ConversationTitle = styled(H4)`
    margin-bottom: 0;
`;

const ConversationStatusBar = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
`;

type NotificationTagComponent = {
    current: number;
    previous: number;
    className?: string;
}

const NotificationTagComponent: React.FC<NotificationTagComponent> = ({className,current,previous}) => {
    return <Tag intent="success" round multiline className={className}>{current}</Tag>
}

const animationPopin = keyframes`
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

const animationPopup = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1);
    }
`;

const animationPopout = keyframes`
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
`;

const NotificationTag =  styled(NotificationTagComponent)`
    z-index: 20;
    height: 24px;
    width: 24px;
    text-align: center;
    padding: 0;
    opacity: 0;
    ${ props =>  {
        if (props.current>0&&(props.previous===0||props.previous===props.current)) {
            return css`
                animation: ${animationPopin} 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
                opacity: 1;
            `;
        } else if (props.current>0&&(props.previous>0&&props.previous<props.current)) {
            return css`
                animation: ${animationPopup} 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
                opacity: 1;
            `;
        } else if (props.current===0&&(props.previous>0&&props.previous>props.current)) {
            return css`
                animation: ${animationPopout} 150ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
            `;
        }
    } }
`;

const ConversationListItem: React.FC<ConversationListItemProps> = ({ conversation }) => {

    const { state, actions, reaction } = useOvermind();
    const [ active, setActive ] = React.useState(false);
    const previousNotificationCount = React.useRef(0);
    const realNotificationCount = React.useRef(0);

    const contact = state.contacts.items[conversation.contact_id];

    React.useEffect(() => {
        return reaction(
            (state) => state.conversations.currentItemId,
            (currentItemId) => setActive(currentItemId === conversation.id),
            { immediate: true }
        )
    }, [reaction, conversation.id])

    React.useEffect(() => {
        previousNotificationCount.current = conversation.unread_messages;
        if(conversation.unread_messages) {
            realNotificationCount.current = conversation.unread_messages + 1;
        }
    }, [conversation.unread_messages])

    const selectConversation = () => {
        actions.conversations.loadConversation({id:conversation.id});
    }

    let contactLabel = contact ? getContactLabel(contact) : formatPhoneNumber(conversation.contact_number);

    let contactDetail = null;
    if (contact && contact.contactdetails.length > 1) {
        contactDetail = getMatchingDetails(contact, conversation.contact_number);
    }

    let isCallActive = state.phone.activeCallConversationId === conversation.id;
    let callConnected = state.phone.callStatus==='connected';

    const renderActiveCallState = () => {
        let previewColor = callConnected ? Colors.GREEN3 : Colors.ORANGE5;
        return ( 
            <PreviewWrapperComponent>
                <PreviewIcons>
                    <DirectionalArrow direction={state.phone.activeCallDirection} color={previewColor} />
                    <PreviewIcon icon={callConnected?'phoneOn':'phone'} color={previewColor} />
                </PreviewIcons>
                <span className="bp3-text-success" style={{color: previewColor}}>
                    <strong>Current Call:</strong> {callConnected?formatTimeDuration(state.phone.callTimer):'Connecting...'}
                </span>
            </PreviewWrapperComponent>
        )
    }

    return (
        <ConversationListItemComponent onClick={selectConversation} className={classnames({ 'active': active, 'call-active': isCallActive, 'call-connecting': isCallActive && !callConnected })}>
            <ConversationHeading>
                <ConversationTitle>{contactLabel} {contactDetail && `(${capitalizeWord(contactDetail.label)})`}</ConversationTitle>
                <div>{formatTimeStampRelative(conversation.last_entry?.time_stamp)}</div>
            </ConversationHeading>
            <ConversationStatusBar>
                {isCallActive?(
                    <React.Fragment>
                        {renderActiveCallState()}
                    </React.Fragment>
                ):(
                    <React.Fragment>
                        {conversation.last_entry && <EntryPreview entry={conversation.last_entry} />}
                        <NotificationTag current={conversation.unread_messages} previous={previousNotificationCount.current}/>
                    </React.Fragment>
                )}
            </ConversationStatusBar>
        </ConversationListItemComponent>
    );
}

export default ConversationListItem;