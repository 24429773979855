import React from 'react';
import styled from 'styled-components';
import { Dialog, Classes, Button, InputGroup, H3, ControlGroup, TextArea, Colors } from '@blueprintjs/core';
import DialPad from 'components/phone/DialPad';
import { useOvermind } from 'state';
import { ContactDetail } from 'state/contacts/state';
import { filterContactDetails, FilterContactsByDetailsResult } from 'state/contacts/utils';
import ContactSearchResultList from 'components/contacts/ContactSearchResultList';
import Icon from 'components/ui/Icon';
import PhoneWraper from 'components/phone/Phone';

const CallDialog = styled(Dialog)`
    width: 350px;
`;

const ActionTitle = styled(H3)`
    text-align: center;
`;

const ActionDescription = styled.div`
    margin-bottom: 15px;
    text-align: center;
`;

const ActionGroup = styled(ControlGroup)`
    font-family: monospace;
`;

const ActionInput = styled(InputGroup)`
    font-family: monospace;
`;

const DialPadWraper = styled.div`
    margin: 15px 0;
    margin-bottom: 30px;
`;

const ActionItems = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
`;

const PhoneContainer= styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

const PhoneHeader = styled.div`
    
`

const PhoneFooter = styled.div`
    margin-top: 15px;
`

const NumberInputWrapper = styled.div`
    margin: 30px 0;
`

type ActionMode = 'call' | 'text';

type NewEntryActionProps = {
    mode?: 'call' | 'text' | 'both';
    showDialpad?: boolean;
}

const NewEntryAction: React.FC<NewEntryActionProps> = ({mode="both",showDialpad=false}) => {

    const { state, actions } = useOvermind();
    const [ showDialPad, toggleDialpad ] = React.useState(showDialpad);
    const [ actionaScreenOpen, setActionaScreenOpen ] = React.useState(false);
    const [ actionMode, setActionMode ] = React.useState<ActionMode>('call');
    const [ destinationNumber, setDestinationNumber ] = React.useState('');
    const [ currentDialingPrefix, setCurrentDialingPrefix ] = React.useState('');
    const [ contactLookUpResults, setContactLookUpResults ] = React.useState<FilterContactsByDetailsResult[]>([]);
    const [ selectedContactDetail, setSelectedContactDetail ] = React.useState<ContactDetail|null>(null);
    const [ showComposeScreen, setShowComposeScreen ] = React.useState(false);
    const [ newMessage, setNewMessage ] = React.useState('');

    React.useEffect(()=>{
        if(state.settings.dialingPrefix) {
            setCurrentDialingPrefix(state.settings.dialingPrefix as string)
        }
    },[state.settings.dialingPrefix])

    React.useEffect(()=>{
        if (destinationNumber.length>=1) {
            let results = filterContactDetails(state.contacts.itemsList, destinationNumber);
            setContactLookUpResults(results);            
        } else {
            setContactLookUpResults([]);
        }
    },[destinationNumber,state.contacts.itemsList])

    const handleaActionOpen = (mode: ActionMode) => {
        setActionMode(mode);
        setActionaScreenOpen(true);
        setShowComposeScreen(false);
    };

    const handleaActionClose = () => {
        setActionaScreenOpen(false);
    };

    const handleDialingPrefixChange = (event: any) => {
        setCurrentDialingPrefix(event.target.value);
    }

    const handleInputChange = (event: any) => {
        setDestinationNumber(event.target.value);
    }

    const handleDigitAdd = (value: number | string): void => {
        setDestinationNumber(destinationNumber+value);
    }

    const handleDigitRemove = () => {
        setDestinationNumber(destinationNumber.substring(0, destinationNumber.length - 1));
    }

    const handleToggleDialpad = () => {
        toggleDialpad(!showDialPad);
    }

    const handleNewMessage = async (selection: FilterContactsByDetailsResult | null = null) => {
        if (selection||destinationNumber) {
            let existingConversation = false;
            if (selection) {
                existingConversation = await actions.conversations.loadExistingConversation(selection.detail.value);
            } else if (destinationNumber) {
                existingConversation = await actions.conversations.loadExistingConversation(destinationNumber);
            }
            if (existingConversation) {
                setDestinationNumber('');
                setSelectedContactDetail(null);
                setActionaScreenOpen(false);
                setShowComposeScreen(false);
            } else {
                setShowComposeScreen(true);
            }
        } else {
            setShowComposeScreen(true);
        }
    }

    const handleContactDetailSelect = (selection: FilterContactsByDetailsResult) => {
        setDestinationNumber('');
        setSelectedContactDetail(selection.detail);
        if(actionMode==='call') {
            handleLaunchAction(selection.detail);
        } else {
            handleNewMessage(selection);
        }
    }

    const handleLaunchAction = async (contactDetail?: ContactDetail) => {
        try {
            await actions.conversations.createConversationEntry({
                type: actionMode,
                contactDetail: contactDetail ? contactDetail : selectedContactDetail,
                destinationNumber,
                message: newMessage,
                dialingPrefix: currentDialingPrefix
            });
            setDestinationNumber('');
            setNewMessage('');
            setSelectedContactDetail(null);
            setActionaScreenOpen(false);
            setShowComposeScreen(false);
            if(state.settings.dialingPrefix) {
                setCurrentDialingPrefix(state.settings.dialingPrefix as string)
            }
        } catch (e) {
            console.log('CouldNotLaunchConversationEntry');
        }
    }

    const renderContactSelect = () => {
        return (
            <React.Fragment>

                <PhoneContainer className={mode!=='call'?Classes.DIALOG_BODY:''}>

                    <PhoneHeader>
                        <ActionTitle>
                            {actionMode === 'call' ? 'Make a New Call' : showComposeScreen ? 'Compose New Message' : 'Send a New Message'}
                        </ActionTitle>
                        <ActionDescription>Type a phone number or a name of an existing contact.</ActionDescription>
                    </PhoneHeader>

                    <NumberInputWrapper>
                        
                        <ActionGroup>
                            <Button large onClick={handleToggleDialpad}>
                                <Icon name="keypad" color={Colors.GRAY1} width={24}/>
                            </Button>
                            {actionMode === 'call'?<ActionInput   
                                large      
                                placeholder=""
                                value={currentDialingPrefix}
                                onChange={handleDialingPrefixChange}
                                style={{width:'40px',textAlign:'center'}}
                            />:null}
                            <ActionInput   
                                large          
                                fill               
                                autoFocus 
                                placeholder="Name or Number"
                                value={destinationNumber}
                                onChange={handleInputChange}
                                rightElement={<Button minimal icon="key-backspace" onClick={handleDigitRemove}/>}
                            />
                        </ActionGroup>

                        {contactLookUpResults.length?(
                            <ContactSearchResultList numberQuery={destinationNumber} searchResults={contactLookUpResults} onContactDetailSelect={handleContactDetailSelect}/>
                        ):''}  

                    </NumberInputWrapper>

                       

                    {showDialPad&&(
                        <DialPadWraper>
                            <DialPad onDigit={handleDigitAdd}/>
                        </DialPadWraper>
                    )}    

                </PhoneContainer>

                <div className={mode!=='call'?Classes.DIALOG_FOOTER:''}>
                    <PhoneFooter>
                        {actionMode === 'call' ? 
                            (<Button large fill rightIcon={<Icon name="phone" color="#FFF" width={24}/>} text="Make Call" intent="success" disabled={destinationNumber.length<3} onClick={()=>handleLaunchAction()} />) : 
                            (<Button large fill rightIcon={<Icon name="compose" color="#FFF" width={24}/>}  text="Compose Message" intent="success" disabled={destinationNumber.length<3} onClick={()=>handleNewMessage()} />)
                        }
                    </PhoneFooter>
                </div>

            </React.Fragment>
        )
    }

    const renderMessageCompose = () => {
        return (
            <React.Fragment>

                <div className={Classes.DIALOG_BODY}>

                    <ActionTitle>Compose New Message</ActionTitle>

                    <TextArea
                        fill
                        growVertically={true}
                        large={true}
                        onChange={(e: any)=>setNewMessage(e.target.value)}
                        value={newMessage}
                    />

                </div>

                <div className={Classes.DIALOG_FOOTER}>
                    <Button large fill rightIcon={<Icon name="send" color="#FFF" height={24}/>} text="Send New Message" intent="success" disabled={newMessage.length<1} onClick={()=>handleLaunchAction()} />
                </div>

            </React.Fragment>
        )
    }
 
    switch (mode) {

        case 'both':
            return (
                <React.Fragment>
                    <ActionItems>
                        <PhoneWraper handleNewCall={()=>handleaActionOpen('call')}/>
                        <Button rightIcon={<Icon name="compose" color="#FFF" height={24}/>} text="New Message"  intent="primary" onClick={()=>handleaActionOpen('text')}/>   
                    </ActionItems>      
                    <CallDialog lazy isOpen={actionaScreenOpen} onClose={handleaActionClose} >
                        {showComposeScreen?renderMessageCompose():renderContactSelect()}
                    </CallDialog>
                </React.Fragment>
            );

        case 'call':
            return (
                <React.Fragment>
                    {renderContactSelect()}
                </React.Fragment>
            );

        default:
            return (
                <React.Fragment>
                    <ActionItems>
                        <PhoneWraper handleNewCall={()=>handleaActionOpen('call')}/>
                        <Button rightIcon={<Icon name="compose" color="#FFF" height={24}/>} text="New Message"  intent="primary" onClick={()=>handleaActionOpen('text')}/>   
                    </ActionItems>      
                    <CallDialog lazy={false} isOpen={actionaScreenOpen} onClose={handleaActionClose} >
                        {showComposeScreen?renderMessageCompose():renderContactSelect()}
                    </CallDialog>
                </React.Fragment>
            );

    }

    

}

export default NewEntryAction;