import styled from 'styled-components';
import { InputGroup, H4, Colors } from '@blueprintjs/core';

export const LoginComponent = styled.div`
    width: 350px;
    & .bp3-spinner .bp3-spinner-track {
        stroke: rgb(255 255 255);
    }
`;

export const Heading = styled(H4)`
    text-align: center;
`;

export const QRCodeContainer = styled.div`
    margin: 15px 0;
    text-align: center;
    border-color: ${props => props.theme.brandPrimary};
    border-width: 2px;
    border-style: dashed;
    border-radius: 6px;
    background: ${Colors.WHITE};
    padding: 20px;
`;

export const Container = styled.div`
    margin-bottom: 10px;
`;

export const InputField = styled(InputGroup)`
    margin-bottom: 5px;
`;

export const LoginLinkContainer = styled.div`
    text-align: center;
    display: block;
    margin: 10px;
`;

export const HelpText = styled.div`
    box-sizing: inherit;
    text-align: center;
    margin-bottom: 15px;
`;

export const LoginBrading = styled.div`
    margin-bottom: 30px;
`;

export const LoginBradingLogo = styled.img``;