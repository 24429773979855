import { SipPhone } from "./SipPhone"

export type CallStatusPayload = {
    status: string;
    callEntryId: string | null;
    duration?: number;
}

type InitOptions = {
    onNetworkStatusUpdate(status: string): void;
    onCallStatusUpdate(payload: CallStatusPayload): void;
}

type SipOptions = {
    destination: string;
    source: string;
    callEntryId: string | null;
    getSipUser(): any;
}

export const client = (()=>{

    let client: SipPhone;

    return {
        initialize: (options: InitOptions) => {
            client = new SipPhone();
            client.onNetworkStatusUpdate = options.onNetworkStatusUpdate;
            client.onCallStatusUpdate = options.onCallStatusUpdate;
        },
        createAgent: (options: SipOptions) => {
            let sipDetails = options.getSipUser();
            client.createAgent({
                realm: sipDetails.domain,
                uri: `sip:${sipDetails.username}@${sipDetails.domain}`,
                user: sipDetails.username,
                pass: sipDetails.password,
                server: 'wss://rtcdev.fpool.org/ws',
                displayName: options.source
            });
            client.setCurrentCallId(options.callEntryId);
            client.setDestination(options.destination);
            client.onCallStatusUpdate({
                status: 'starting',
                callEntryId: options.callEntryId
            })
        },
        connectAgent: () => {
            client.connectAgent();
        },
        connectCall: () => {
            client.connectCall();
        },
        transferCall: (destination: string) => {
            client.transferCall(destination);
        },
        sendDTMF: (digit: number) => {
            client.sendDTMF(digit)
        },
        muteCall: () => {
            client.muteCall();
        },
        unMuteCall: () => {
            client.unMuteCall();
        },
        endCall: () => {
            client.endCall();
        },
        holdCall: () => {
            client.setHold(true);
        },
        unHoldCall: () => {
            client.setHold(false);
        }
    }

})()