import { gql, Query } from 'overmind-graphql';
import { SubscribeExtensionMutation, SubscribeExtensionMutationVariables } from './_types/SubscribeExtensionMutation';

export const subscribeToExtension: Query<SubscribeExtensionMutation, SubscribeExtensionMutationVariables> = gql`
mutation SubscribeExtensionMutation (
    $extension_id: String!, 
    $player_id: String!
    $ignored_numbers: [IgnoredNumbersInput],
    $mute_calls: Boolean,
    $mute_texts: Boolean,
    $show_offline: Boolean,
    $online: Boolean,
    $environment: String
) {
    SubscribeExtensionMutation (
        extension_id: $extension_id, 
        player_id: $player_id
        ignored_numbers: $ignored_numbers,
        mute_calls: $mute_calls,
        mute_texts: $mute_texts,
        show_offline: $show_offline,
        online: $online,
        environment: $environment
    ) {
        id
    }
}
` as any;