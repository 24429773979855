import React from 'react';
import { Icon as BlockIcon, InlineIcon, addCollection } from '@iconify/react';

// Eva Icons
import keypad from '@iconify-icons/eva/keypad-outline';
import home from '@iconify-icons/eva/home-outline';
import print from '@iconify-icons/eva/printer-outline';
import arrowLeft from '@iconify-icons/eva/arrow-back-outline';
import arrowRight from '@iconify-icons/eva/arrow-forward-outline';

// LineAwesome Icons
import fax from '@iconify-icons/la/fax';
import paperclip from '@iconify-icons/la/paperclip';
import smile from '@iconify-icons/la/smile-wink';
import notificationOn from '@iconify-icons/la/bell';
import notificationOff from '@iconify-icons/la/bell-slash';
import envelope from '@iconify-icons/la/envelope';
import micOn from '@iconify-icons/la/microphone';
import micOff from '@iconify-icons/la/microphone-slash';
import trash from '@iconify-icons/la/trash-alt';
import voicemail from '@iconify-icons/la/voicemail';
import link from '@iconify-icons/la/external-link-square-alt';
import user from '@iconify-icons/la/user';
import file from '@iconify-icons/la/file';
import eye from '@iconify-icons/la/eye';
import save from '@iconify-icons/la/save';
import close from '@iconify-icons/la/window-close';
import send from '@iconify-icons/la/paper-plane';
import edit from '@iconify-icons/la/edit';
import signOut from '@iconify-icons/la/sign-out-alt';
import signIn from '@iconify-icons/la/sign-in-alt';
import version from '@iconify-icons/la/cubes';
import extension from '@iconify-icons/la/phone-square';
import sliders from '@iconify-icons/la/sliders-h';
import addContact from '@iconify-icons/la/user-plus';
import editContact from '@iconify-icons/la/user-edit';
import qrcode from '@iconify-icons/la/qrcode';
import settings from '@iconify-icons/la/cog';

// Phosphor Icons
import archive from '@iconify-icons/ph/archive-box';
import restore from '@iconify-icons/ph/upload-simple';
import compose from '@iconify-icons/ph/note-pencil';
import circle from '@iconify-icons/ph/circle-bold';
import warning from '@iconify-icons/ph/warning';
import error from '@iconify-icons/ph/warning-octagon';
import info from '@iconify-icons/ph/warning-circle';
import success from '@iconify-icons/ph/check-circle';

// Material Design Icons
import conversations from '@iconify/icons-mdi/comment-multiple';
import phone from '@iconify/icons-mdi/phone';
import phoneOn from '@iconify/icons-mdi/phone-in-talk';
import phoneHold from '@iconify/icons-mdi/phone-paused';
import phoneActive from '@iconify/icons-mdi/phone-check';
import hangup from '@iconify/icons-mdi/phone-off';

const evaIcons = {
    home: home,
    keypad: keypad,
    print: print,
    smile: smile,
    fax: fax,
    paperclip: paperclip,
    notificationOn: notificationOn,
    notificationOff: notificationOff,
    conversations: conversations,
    envelope: envelope,
    micOn: micOn,
    micOff: micOff,
    phone: phone,
    phoneOn: phoneOn,
    phoneHold: phoneHold,
    phoneActive: phoneActive,
    circle: circle,
    hangup: hangup,
    trash: trash,
    voicemail: voicemail,
    link: link,
    user: user,
    file: file,
    eye: eye,
    close: close,
    save: save,
    arrowLeft: arrowLeft,
    arrowRight: arrowRight,
    send: send,
    edit: edit,
    signOut: signOut,
    signIn: signIn,
    version: version,
    extension: extension,
    sliders: sliders,
    addContact: addContact,
    editContact: editContact,
    archive: archive,
    restore: restore,
    compose: compose,
    warning: warning,
    error: error,
    info: info,
    success: success,
    qrcode: qrcode,
    settings: settings
}

export type IconName = keyof typeof evaIcons;

addCollection({
    prefix: 'eva',
    icons: evaIcons,
    width: 24,
    height: 24
});
export interface IconProps {
    className?: string;
    name: IconName;
    color?: string;
    width?: number;
    height?: number;
    block?: boolean;
    style?: any;
}

const Icon: React.FC<IconProps> = (props: IconProps) => {
    const styleProps: { width?: number, height?: number, color?: string } = {};
    if (props.width) {
        styleProps.width = props.width;
    }
    if (props.height) {
        styleProps.height = props.height;
    }
    if (props.color) {
        styleProps.color = props.color;
    }
    let style = {};
    if (props.style) {
        style = props.style;
    }
    if (props.block) {
        return <BlockIcon className={props.className} icon={`eva:${props.name}`} {...styleProps} style={style}/>
    } else {
        return <InlineIcon className={props.className} icon={`eva:${props.name}`} {...styleProps} style={style}/>
    }
}; 

Icon.defaultProps = {
    name: 'home',
    block: false
};

export default Icon;