import React from 'react';
import styled from 'styled-components';
import CustomScroll from 'react-custom-scroll';
import ResizeObserver from 'resize-observer-polyfill';
import { Button, Colors } from '@blueprintjs/core';

import { useOvermind } from 'state';
import { groupEntriesByDate } from 'state/entries/utils';

import EntryView, { EntrySendingIndicator } from 'components/entries/EntryView';
import LoadingComponent from 'components/ui/LoadingComponent';

const LoadingWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
`;

const EntryListComponent = styled.div`
    & > * {
        margin-bottom: 5px;
    }
    & > *:last-child {
        margin-bottom: 0;
    }
`;

export const DateGroup = styled.div`
    &:first-of-type .date-label {
        margin-top: 0;
    }
`;

type DateLabelProps = {
    position?: 'center' | 'left';
}

export const DateLabel = styled.div.attrs<DateLabelProps>(() => ({
    className: 'date-label'
})) <DateLabelProps>`
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: ${props => props.position || 'center'};
    height: 1.5em;
    margin-top: 15px;
    margin-bottom: 15px;
    &:before {
        content: '';
        background:  linear-gradient(to right, ${Colors.LIGHT_GRAY5}, ${Colors.LIGHT_GRAY4}, ${Colors.LIGHT_GRAY5});
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
    }
    &:after {
        content: attr(data-label);
        position: relative;
        display: inline-block;
        color: black;
        padding: 0 .5em;
        line-height: 1.5em;
        color: ${Colors.GRAY2};
        background-color: #fbfbfb;
    }
`;

type LoadMoreWrapperProps = {
    styled: boolean;
    position: 'top' | 'bottom';
}

const LoadMoreWrapper = styled.div.attrs<LoadMoreWrapperProps>(({ styled, position }) => ({
    className: ([
        styled ? 'styled' : '',
        position
    ]).join(' ')
})) <LoadMoreWrapperProps>`
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    z-index: 1;
    &.top {
        margin-bottom: 15px;
    }
    &.bottom {
        margin-top: 15px;
    }
    &.styled:before {
        content: '';
        background:  linear-gradient(to right, ${Colors.LIGHT_GRAY5}, ${Colors.LIGHT_GRAY4}, ${Colors.LIGHT_GRAY5});
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
        z-index: -1;
    }
`;

const LoadMoreButtonWrapper = styled.span`
    padding: 0 10px;
    background: #fbfbfb;
`;

type LoadMoreProps = {
    loading: boolean;
    onLoadMore: () => void;
    styled?: boolean;
    position?: 'top' | 'bottom';
}

export const LoadMore: React.FC<LoadMoreProps> = ({ loading, onLoadMore, styled = true, position = 'bottom' }) => {

    const { state: { entries: { itemsList: entries, moreLoaded, limit, offset } } } = useOvermind();

    let hasMaxPageSize = entries.length >= limit + offset;
    let hasLoadedMore = moreLoaded === null ? false : true;
    let previousLoadCount = hasLoadedMore ? hasLoadedMore : 0;

    if (loading || (hasMaxPageSize && !hasLoadedMore) || (hasMaxPageSize && hasLoadedMore && previousLoadCount > 0)) {
        return (
            <LoadMoreWrapper styled={styled} position={position}>
                <LoadMoreButtonWrapper>
                    <Button minimal outlined loading={loading} onClick={onLoadMore}>Load More</Button>
                </LoadMoreButtonWrapper>
            </LoadMoreWrapper>
        )
    } else {
        return null;
    }

}

const EntryList = () => {

    const { state: { entries: { itemsList: entries, moreLoaded, limit, offset } }, actions: { entries: { getMoreConversationEntries } }, reaction } = useOvermind();

    const [isSending, setSending] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [isLoadingMore, setLoadingMore] = React.useState(false);

    const [scrollPosition, setScrollPosition] = React.useState(0);
    const baseHeight = React.useRef(0);
    const previousContainerHeight = React.useRef(0);
    const previousScrollPosition = React.useRef(0);
    const currentScrollPosition = React.useRef(0);
    const previousNewContentSize = React.useRef(0);
    const scrollContainer = React.useRef<any>(null);
    const divRef = React.createRef<HTMLDivElement>();

    const observer = React.useRef(
        new ResizeObserver((entries: ReadonlyArray<ResizeObserverEntry>) => {

            // console.log('-------------------------------------------------------------')

            // Get Client View Height
            let { clientHeight } = scrollContainer.current.innerContainerRef.current;

            // Get Current Full Content Height
            const { height: newContainerHeight } = entries[0].contentRect;

            // If baseHeight Not Set And Client View Height Is Ready
            if (baseHeight.current === 0 && clientHeight > 30) {
                baseHeight.current = clientHeight;
            }
            // If Base Height Is Set But Its Changed
            else if (baseHeight.current !== 0 && (clientHeight > 30 && baseHeight.current !== clientHeight)) {
                baseHeight.current = clientHeight;
            }

            let isInitialLoad = previousContainerHeight.current === 0;

            // console.log('currentScrollPosition: ' + currentScrollPosition.current)
            // console.log('baseHeight: ' + baseHeight.current);
            // console.log('previousContainerHeight: ' + previousContainerHeight.current);

            // Initial Load Set Initial Full Content Height
            if (isInitialLoad) {
                previousContainerHeight.current = newContainerHeight;
            }

            //console.log('newContainerHeight: ' + newContainerHeight);

            // If View Has Rendered and We Have Heights
            if (baseHeight.current > 0 && newContainerHeight > 0) {

                // Initialize New Possible Scroll Position Variable
                let newScrollPosition = 0;

                // Check To See If Content Size Has Changed
                let newContentHeight = newContainerHeight - previousContainerHeight.current;

                //console.log('newContentHeight: ' + newContentHeight);

                // Lets Do Initial Scroll To Bottom If Needed
                if (isInitialLoad && newContainerHeight > baseHeight.current) {
                    newScrollPosition = newContainerHeight - baseHeight.current;
                    previousScrollPosition.current = newScrollPosition;
                    setScrollPosition(newScrollPosition);
                }

                // If Content Size Has Changed And It's Bigger That The Client View Window
                if (newContentHeight > 0 && newContainerHeight > baseHeight.current) {

                    // Store New Content Size
                    previousNewContentSize.current = newContentHeight;

                    //console.log('Content Size has Changed')

                    // Lets Get The Difference of The New Content
                    //let newContainerDifference = newContainerHeight - newContentHeight;

                    // Check To See If We Are Loading More, Meaning We Are Near The Top
                    let isAtTop = currentScrollPosition.current <= 60;

                    // If We Are Loading More Then We Have To Scroll To The Previous Top Before Loading The More Items ( Plus The Button Height)
                    if (isAtTop) {

                        //console.log('isAtTop: ' + (isAtTop ? 'Yes' : 'No'))
                        newScrollPosition = newContentHeight;
                        if (newScrollPosition === previousScrollPosition.current) {
                            newScrollPosition = newScrollPosition + 1;
                        }
                        //console.log('scrollPosition: ' + scrollPosition)
                        //console.log('isLoadingMoreNewHeight: ' + newScrollPosition)

                        previousScrollPosition.current = newScrollPosition;
                        setScrollPosition(newScrollPosition);

                    }

                }

                // If Initial Content Size is Greater Than Base View Size
                else if (newContainerHeight > baseHeight.current && newContentHeight > 0) {
                    // Then We Have To Scroll To The Bottom
                    newScrollPosition = newContainerHeight - baseHeight.current;
                    previousScrollPosition.current = newScrollPosition;
                    setScrollPosition(newScrollPosition);
                }

                // If Container Size Has Changed
                if (previousContainerHeight.current < newContainerHeight) {
                    previousContainerHeight.current = newContainerHeight;
                }

            }

        })
    );

    React.useEffect(() => {
        if (isSending) {
            setScrollPosition(previousContainerHeight.current);
        }
    }, [isSending])

    React.useEffect(() => {
        let containerRef = divRef.current;
        let observerRef = observer.current;
        if (containerRef) {
            observerRef.observe(containerRef as unknown as Element);
        }
        return () => {
            if (containerRef) {
                observerRef.unobserve(containerRef as unknown as Element);
            }
        };
    }, [divRef, observer]);

    React.useEffect(() => {
        return reaction(
            (state) => state.entries.sending,
            (sendingStatus) => {
                setSending(sendingStatus);
            }
        )
    }, [reaction]);

    React.useEffect(() => {
        return reaction(
            (state) => state.entries.loading,
            (loadingStatus) => {
                setLoading(loadingStatus);
            }
        )
    }, [reaction]);

    React.useEffect(() => {
        return reaction(
            (state) => state.entries.loadingMore,
            (loadingMore) => {
                setLoadingMore(loadingMore);
            }
        )
    }, [reaction, previousContainerHeight, scrollPosition]);

    let dates = groupEntriesByDate(entries);

    const renderLoadMore = () => {

        let hasMaxPageSize = entries.length >= limit + offset;
        let hasLoadedMore = moreLoaded === null ? false : true;
        let previousLoadCount = hasLoadedMore ? hasLoadedMore : 0;

        // If It's Loading More OR
        // If Has The Maxium, And Hasnt Loaded More OR
        // If Has The Maxium, Has Already Loaded More, Previusly Loaded Some More Items
        if (isLoadingMore || (hasMaxPageSize && !hasLoadedMore) || (hasMaxPageSize && hasLoadedMore && previousLoadCount > 0)) {
            return <LoadMore loading={isLoadingMore} onLoadMore={getMoreConversationEntries} styled={false} position="top" />;
        } else {
            return null
        }

    }

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingWrapper><LoadingComponent /></LoadingWrapper>
            ) : (
                <CustomScroll
                    heightRelativeToParent="100%"
                    keepAtBottom
                    ref={scrollContainer}
                    scrollTo={scrollPosition}
                    onScroll={() => {currentScrollPosition.current = scrollContainer.current.state.scrollPos}}
                >
                    <EntryListComponent ref={divRef}>
                        {renderLoadMore()}
                        {dates.map((date, dateKey) => {
                            return (
                                <DateGroup key={dateKey}>
                                    <DateLabel data-label={date.label} />
                                    <div>
                                        {date.entries.map((entry, entryKey) => {
                                            return (
                                                <EntryView key={entryKey} entry={entry} />
                                            )
                                        })}
                                    </div>
                                </DateGroup>
                            )
                        })}
                        {isSending && <EntrySendingIndicator />}
                    </EntryListComponent>
                </CustomScroll>
            )}
        </React.Fragment>
    );
}

export default EntryList;