import React from 'react';
import styled from 'styled-components';
import { H4, Card, Callout } from '@blueprintjs/core';

import { useOvermind } from 'state';
import LoadingComponent from 'components/ui/LoadingComponent';
import { Extension } from 'state/extensions/state';
import { filterConfiguredExtensions } from 'state/extensions/utils';

const WrapperComponent = styled.div`
    display: grid;
    place-items: center center;
`;

const ExtensionListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;   
    cursor: pointer;
    border-bottom: 1px solid ${props => props.theme.altBorder};
    &:hover {
        background: ${props => props.theme.focusBG};
    }
    background: ${props => props.theme.altBG};
`;

const Container = styled(Card)`
    width: 400px;
`;

type ExtensionSelectListItemProps = {
    extension: Extension;
}

const ExtensionSelectListItem: React.FC<ExtensionSelectListItemProps> = ({extension}) => {

    let { extension: e } = extension;

    const { actions } = useOvermind();

    const onExtensionSelect = () => {
        actions.extensions.selectExtension(extension.id);
    }

    const renderNumbersCount = (numbers: any[] | null | undefined) => {
        if (!numbers) return '';
        if (numbers.length===1) {
            return `1 Number`;
        } else {
            return `${numbers.length} Numbers`;
        }
    }

    return (
        <ExtensionListItem onClick={onExtensionSelect}>
            <span>
                <strong>{e?.label} </strong>
                ({renderNumbersCount(extension.extension?.numbers)})
            </span>
            <span>{`#${e?.extnum}`}</span>
        </ExtensionListItem>
    )

}

const ExtensionSelect: React.FC = () => {

    const { state } = useOvermind();

    const configuredExtensions = filterConfiguredExtensions(state.extensions.itemsList);

    return (
        <WrapperComponent>
            <Container elevation={2}>
                <H4>Select An Extension</H4>
                {state.extensions.loading&&<LoadingComponent/>}
                {configuredExtensions.length===0&&(
                    <Callout icon={null} intent='primary'>
                        Your account does not have any configured extensions. Please contact FastPBX Support so that your account can be configured to use the FastPBX Link Web App.
                    </Callout>
                )}
                {configuredExtensions.map((extension, k)=>{
                    return (
                        <ExtensionSelectListItem key={k} extension={extension}/>
                    )
                })}
            </Container>
        </WrapperComponent>
    )

}

export default ExtensionSelect;