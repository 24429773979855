import { Action, AsyncAction } from "overmind";
import * as Sentry from '@sentry/browser';

import { NotificationPayload } from "./state";
import { FlashOptions } from "./tabs";

export const showNotificationsPrompt: Action = ({effects}) => {
    effects.notifications.agent.showNativePrompt();
}

export const syncSubscriptionStatus: AsyncAction<boolean> = async ({state,effects}, subscriptionStatus) => {
    console.log('Synced Subscription Status', subscriptionStatus);
    //state.user.playerId = await effects.notifications.agent.getPlayerID();
}

export const syncNotificationStatus: Action<'default'|'granted'|'denied'> = ({state,effects}, notificationStatus) => {
    console.log('Synced Status', notificationStatus);
    state.notifications.permission = notificationStatus;
}

export const registerNotification: Action<NotificationPayload['data']> = ({state,effects}, notification) => {
    if( state.conversations.items[notification.conversation_id] ) {
        state.conversations.items[notification.conversation_id].unread_messages = state.conversations.items[notification.conversation_id].unread_messages + 1;
        if(state.conversations.items[notification.conversation_id].last_entry) {
            //@ts-ignore
            state.conversations.items[notification.conversation_id].last_entry = state.conversations.items[notification.conversation_id].last_entry != null ? {...state.conversations.items[notification.conversation_id].last_entry, time_stamp: Date.now() } : null;
        }
    }
}

export const registerVisibilityListener: Action = ({actions}) => {
    document.addEventListener("visibilitychange", actions.notifications.handleVisibilityChange, false);
}

export const handleVisibilityChange: Action = ({state, actions}) => {
    switch (document.visibilityState) {
        case 'hidden':
            state.notifications.windowVisible = false;
            break;
        case 'visible':
            state.notifications.windowVisible = true;
            actions.notifications.clearBadgeCount();
            actions.notifications.resetTitleBar();
            break;
    }
} 

export const setBadgeCount: Action<number> = ({state,effects}, count) => {
    if (!state.notifications.windowVisible) {
        effects.notifications.setBadgeCount(count);
    }
}

export const clearBadgeCount: Action = ({effects}) => {
    effects.notifications.clearBadgeCount();
}

export const flashTitleBarMessage: Action<FlashOptions> = ({state,effects}, options) => {
    if (!state.notifications.windowVisible) {
        effects.notifications.flashTitleBar(options);
    }
}

export const flashTitleBarCall: Action<FlashOptions> = ({state,effects}, options) => {
    if (!state.notifications.windowVisible) {
        effects.notifications.flashTitleBar(options);
        effects.notifications.showIncomingCallIcon();
    }
}

export const resetTitleBar: Action = ({effects}) => {
    effects.notifications.resetTitleBar();
}

export const updateNotificationHooks: AsyncAction = async ({state,effects}) => {
    try {

        //let extension_id = state.extensions.currentItem?.extension?.id;
        //let player_id = state.user.playerId;

        // let mute_calls = !state.settings.receiveCalls;
        // let mute_texts = !state.settings.receiveTexts;
        // let show_offline = state.settings.offlineNotifications;
        // let online = state.user.loggedIn;
        // let environment = state.app.environment;

        let sipKey = `sip_${state.extensions.currentItem?.extension?.remoteuser}`
        let didKeys = state.numbers.itemsList.map((number)=>{
            return `did_${number.number?.didnumber}`
        })

        // if (extension_id&&player_id) {
        //     await effects.gql.mutations.subscribeToExtension({
        //         extension_id,
        //         player_id,
        //         mute_calls,
        //         mute_texts,
        //         show_offline,
        //         online,
        //         environment
        //     })
        // }

        effects.notifications.agent.updateSubscription({
            sipKeys: [sipKey],
            didKeys: didKeys
        })

    } catch (e) {
        Sentry.captureException(e);
        console.error(e);
    }
}