import React from 'react';
import styled from 'styled-components';
import CustomScroll from 'react-custom-scroll';

import { useOvermind } from 'state';
import { FilteredEntry } from 'state/entries/state';
import { groupEntriesByDate } from 'state/entries/utils';

import { DateGroup, DateLabel, LoadMore } from 'components/entries/EntryList';
import EntryItem from 'components/entries/EntryItem';
import LoadingComponent from 'components/ui/LoadingComponent';


const Wrapper = styled.div`
    padding: 20px;
    height: calc(100vh - 107px);
`;

const ListComponent = styled.div`

`;

const LoadingWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
`;

const VoicemailLog = () => {

    const {  
        state: { entries: { itemsList: entries } }, 
        actions: {  entries: { getNumberEntries, getMoreNumberEntries } }, 
        reaction 
    } = useOvermind();

    const [isLoading, setLoading] = React.useState(true);
    const [isLoadingMore, setLoadingMore] = React.useState(false);

    React.useEffect(() => {
        return reaction(
            (state) => state.entries.loading,
            (loadingStatus) => {
                setLoading(loadingStatus);
            }
        )
    }, [reaction]);

    React.useEffect(() => {
        return reaction(
            (state) => state.entries.loadingMore,
            (loadingMore) => {
                setLoadingMore(loadingMore);
            }
        )
    }, [reaction]);

    React.useEffect(() => {
        getNumberEntries({
            message_type: 'voicemail',
            direction: 'in'
        });
    }, [getNumberEntries]);

    let dates = groupEntriesByDate(entries);

    return (
        <Wrapper>
            {isLoading ? (
                <LoadingWrapper><LoadingComponent /></LoadingWrapper>
            ) : (
                    <CustomScroll
                        heightRelativeToParent="100%"
                    >
                        <ListComponent>
                            {dates.map((date, dateKey) => {
                                return (
                                    <DateGroup key={dateKey}>
                                        <DateLabel data-label={date.label} />
                                        <div>
                                            {date.entries.map((entry, entryKey) => {
                                                return (
                                                    <EntryItem key={entryKey} entry={entry as FilteredEntry}/>
                                                )
                                            })}
                                        </div>
                                    </DateGroup>
                                )
                            })}
                            <LoadMore loading={isLoadingMore} onLoadMore={getMoreNumberEntries} />
                        </ListComponent>
                    </CustomScroll>
                )}
        </Wrapper>
    );

}

export default VoicemailLog;