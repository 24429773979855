import { NotificationToaster } from 'components/notifications/NotificationToaster';

import axios from 'axios';
declare global {
    interface Window {
        platform: 'web' | 'desktop';
    }
}

interface EnvironmentConfig {
    url: string;
}

export interface SubscriptionSettings {
    didKeys: string[];
    sipKeys: string[];
}

interface NotificationAgentParams {
    statusSyncHandler(status: 'default' | 'granted' | 'denied'): void
    subscriptionSyncHandler(status: boolean): void
    textNotificationHandler(data: WebNotificationPayload): void;
    callNotificationHandler(data: WebNotificationPayload): void;
    callAnsweredNotificationHandler(data: WebNotificationPayload): void;
}

abstract class NotificationAgent {

    abstract devConfig: EnvironmentConfig;
    abstract prodConfig: EnvironmentConfig;
    abstract loadedConfig: EnvironmentConfig | null;

    abstract initialize(): Promise<boolean>;
    abstract showPrompt(): void;
    abstract registerNotificationListener(): void;

    didKeys: string[] = [];
    sipKeys: string[] = [];
    lastTs: number = 0;

    statusSyncHandler: NotificationAgentParams['statusSyncHandler'];
    subscriptionSyncHandler: NotificationAgentParams['subscriptionSyncHandler'];
    textNotificationHandler: NotificationAgentParams['textNotificationHandler'];
    callNotificationHandler: NotificationAgentParams['callNotificationHandler'];
    callAnsweredNotificationHandler: NotificationAgentParams['callAnsweredNotificationHandler'];

    constructor(options: NotificationAgentParams) {
        this.statusSyncHandler = options.statusSyncHandler;
        this.subscriptionSyncHandler = options.subscriptionSyncHandler;
        this.textNotificationHandler = options.textNotificationHandler;
        this.callNotificationHandler = options.callNotificationHandler;
        this.callAnsweredNotificationHandler = options.callAnsweredNotificationHandler;
    }

    updateSubscription(settings: SubscriptionSettings): void {
        this.didKeys = settings.didKeys;
        this.sipKeys = settings.sipKeys;
        this.lastTs = 0;
    }

    loadConfig(): void {
        console.log('Loading Config: ', process.env.NODE_ENV);
        switch (process.env.NODE_ENV) {
            case 'development':
                this.loadedConfig = this.devConfig;
                break;
            case 'production':
                this.loadedConfig = this.prodConfig;
                break;
        }
    }

}

interface WebNotificationPayload {
    subject: string;
    body: string;
    type: 'InboundText' | 'InboundCall' | 'InboundCallAnswered',
    conversation_id: string;
    number_id: string;
    message_id: string;
    to: string;
    from: string;
    call_uuid?: string;
    to_label: string;
    ts: number;
    ttl: number;
}

interface WebResponsePayload {
    success: boolean;
    message?: string;
    ts: number;
    notifications: WebNotificationPayload[]
}

class WebNotificationAgent extends NotificationAgent {

    devConfig: EnvironmentConfig = {
        url: 'https://pbxlink.gql.fastpbx.io/api/gateway_notifications'
    };

    prodConfig: EnvironmentConfig = {
        url: 'https://pbxlink.gql.fastpbx.io/api/gateway_notifications',
    };

    online: boolean = false;
    errorCount: number = 0;
    errorTimeOut: number = 15;

    constructor(options: NotificationAgentParams) {
        super(options);
        this.loadConfig();
    }

    loadedConfig: EnvironmentConfig | null = null;

    initialize(): Promise<boolean> {

        console.log('WebNotificationAgent Initializing In Mode:', process.env.NODE_ENV);

        let self = this;

        return new Promise((resolve, reject) => {

            try {

                console.log('Init Polling Notifications');

                let status = Notification.permission;

                console.log('Get Base Permission:', status);
                self.statusSyncHandler(status);

                if (status !== 'granted') {
                    Notification.requestPermission().then((status) => {
                        if (navigator.onLine) {
                            this.online = true;
                            console.log('Notification Network Status: Online')
                        }
                        self.statusSyncHandler(status);
                    })
                }

                return resolve(true);

            } catch (e) {

                console.error(e);
                return reject(e);

            }

        })

    };

    async subscribeToNotifications() {

        if (this.loadedConfig) {

            let subscriptionOptions = {
                ts: this.lastTs,
                sip: this.sipKeys,
                did: this.didKeys
            }

            try {

                const response = await axios.post(this.loadedConfig.url, subscriptionOptions);

                if (response.status === 502) {
                    this.subscribeToNotifications()
                } else if (response.status !== 200) {
                    this.processNotificationError(response.statusText);
                    this.networkErrorReconnect()
                } else {
                    let payload: WebResponsePayload = await response.data;
                    if (!payload.success) {
                        this.processNotificationError(payload.message || 'Notification Error')
                    } else {
                        this.processNotificationPayload(payload)
                    }
                }

            } catch (e) {
                this.networkErrorReconnect()
            }

        }

    }

    networkErrorReconnect() {
        this.errorCount++;
        let currentTimeout = this.errorTimeOut * this.errorCount;
        console.log(`Notification Network Error [${this.errorCount}] - Waiting: ${currentTimeout} Seconds`)
        if (this.errorCount < 5) {
            NotificationToaster.show({
                intent: 'danger',
                message: 'Network Error, Attempting To Reconnect...',
                timeout: currentTimeout * 1000
            });
        } else {
            NotificationToaster.show({
                intent: 'danger',
                action: {
                    href: "/",
                    target: "_self",
                    text: 'Reload',
                },
                message: 'You Appear To Be Offline'
            });
        }
        setTimeout(() => {
            if (this.errorCount < 5) {
                this.subscribeToNotifications()
            }
        }, currentTimeout * 1000);
    }

    processNotificationError(error: string) {
        console.error('NotificationAgentSubscriptionError:' + error)
    }

    processNotificationPayload(payload: WebResponsePayload) {

        this.lastTs = payload.ts;

        payload.notifications.forEach(notification => {

            let currentTimeStamp = new Date();
            let originalTimeStamp = new Date(notification.ts);

            let receivedOn = `${currentTimeStamp.getHours()}:${currentTimeStamp.getMinutes()}:${currentTimeStamp.getSeconds()}`;
            let sentOn = `${originalTimeStamp.getHours()}:${originalTimeStamp.getMinutes()}:${originalTimeStamp.getSeconds()}`;

            console.log(`[Notification Received][${notification.type}] | Sent: ${sentOn} | Received: ${receivedOn}`);

            new Notification(notification.subject, {
                icon: 'https://www.fastpbx.com/assets/img/favicon.ico',
                body: notification.body
            });

            switch (notification.type) {
                case 'InboundText':
                    this.textNotificationHandler(notification);
                    break;
                case 'InboundCall':
                    this.callNotificationHandler(notification);
                    break;
                case 'InboundCallAnswered':
                    this.callAnsweredNotificationHandler(notification);
                    break;
                default:
                    console.warn('IncomingNotification:', notification);
            }

        });

        this.subscribeToNotifications()

    }

    registerNotificationListener() {
        this.subscribeToNotifications()
        window.addEventListener('offline', () => {
            if (this.online) {
                this.online = false;
                console.log('Notification Network Status: Offline')
            }
        });
        window.addEventListener('online', () => {
            if (!this.online) {
                this.online = true;
                console.log('Notification Network Status: Online')
            }
        });
    }

    showPrompt() {
        Notification.requestPermission().then((status) => {
            if (navigator.onLine) {
                this.online = true;
                console.log('Notification Network Status: Online')
            }
            this.statusSyncHandler(status);
        })
    };

}

export {
    WebNotificationAgent
}