import React from 'react';
import styled from 'styled-components';
import CustomScroll from 'react-custom-scroll';

import { getContactLabel, FilterContactsByDetailsResult } from 'state/contacts/utils';
import { formatPhoneNumber } from 'utils/numbers';
import { capitalizeWord } from 'utils/strings';

const SearchResultItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;   
    cursor: pointer;
    border-bottom: 1px solid ${props => props.theme.altBorder};
    &:hover {
        background: ${props => props.theme.focusBG};
    }
    background: ${props => props.theme.altBG};
`;

type ContactSearchResultListProps = {
    searchResults: FilterContactsByDetailsResult[];
    className?: string;
    numberQuery: string;
    onContactDetailSelect(contactDetail: FilterContactsByDetailsResult): void
}

const ContactSearchResultList: React.FC<ContactSearchResultListProps> = ({numberQuery, searchResults, className, onContactDetailSelect}) => {
    return (
        <div className={className}>
            <CustomScroll heightRelativeToParent="100%">
                {searchResults.map((result, k)=>{
                    return (
                        <SearchResultItem key={k} onClick={()=>onContactDetailSelect(result)}>
                            <strong>{getContactLabel(result.contact)}</strong>
                            <span>{`${capitalizeWord(result.detail.label)}: ${formatPhoneNumber(result.detail.value)}`}</span>
                        </SearchResultItem>
                    )                    
                })}
            </CustomScroll> 
        </div>
    );
}

export default styled(ContactSearchResultList)`
    border: 1px solid #cacaca;
    margin-top: 20px;
    height: ${props => props.searchResults.length > 3 ? '75px' :  (props.searchResults.length * 50) + 'px'};
`;