import { AsyncAction, Action, pipe, map, mutate } from "overmind"
import { mapArray } from "state/utils";
import { Number } from "./state";
import { Extension } from "state/extensions/state";

export const getNumbers: AsyncAction<Extension['id']> = async ({ state, effects }, extensionId) => {
    state.numbers.loading = true;
    const { numbers } = await effects.gql.queries.getNumbers({extension_id: extensionId});
    state.numbers.items = mapArray(numbers,'id');
    state.numbers.loading = false;
}

export const selectNumber: Action<Number['id']> = ({state,effects}, numberId) => {
    state.numbers.currentItemId = numberId;
    effects.numbers.saveCurrentNumber(numberId);
}

// Numbers Bootstrap Sequence
export const bootstrapNumbers: AsyncAction = pipe(
    // Set Numbers State To Loading
    mutate(({state})=>{
        state.numbers.loading = true;
    }),
    // Get Current Extension For Next Step
    map(({state})=>{
        return state.extensions.currentItem;
    }),
    // Load Extension Numbers If We Have An Extension Selected
    mutate(async ({actions}, currentExtension) => {
        if(currentExtension){
            let {extension} = currentExtension;
            if (extension) {
                // Load Numbers From API
                await actions.numbers.getNumbers(extension.id);
            }
        }
    }),
    // Load Saved Number For Next Step
    map(({state, effects})=>{
        // Get Saved Number From LocalStorage
        let selectedNumber = effects.numbers.loadCurrentNumber();
        // If Setting Stored In LocalStorage
        if (selectedNumber) {
            // Return Number Only If Still Available
            return state.numbers.items[selectedNumber];
        } else {
            return null;
        }    
    }),
    // Set Current Number
    mutate(({state,effects}, selectedNumber)=>{
        // If Saved In Storage
        if (selectedNumber) {
            state.numbers.currentItemId = selectedNumber.id;
        // If Not Previusly Set
        } else {
            // If We Only Have One Number Available
            if (Object.keys(state.numbers.items).length === 1) {
                // Get That One Number
                let defaultNumberId = Object.keys(state.numbers.items)[0];
                let defaultNumber = state.numbers.items[defaultNumberId];
                // Set It As The Current One
                state.numbers.currentItemId = defaultNumber.id;
                // Save Setting To Local Storage
                effects.numbers.saveCurrentNumber(defaultNumber.id);
            }
        }
    }),
    // Set Numbers State To Loaded
    mutate(({state})=>{
        state.numbers.loading = false;
    }),
);