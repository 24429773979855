import { NotificationToaster } from 'components/notifications/NotificationToaster';
import { UserAgent, UserAgentOptions, Inviter, Session, Referral, SessionState, Info, Notification, InviterOptions } from 'sip.js';
import { SessionDescriptionHandler } from 'sip.js/lib/platform/web';
import { CallStatusPayload } from './effects';
import { playMediaElement, pauseMediaElement } from 'utils/media';

export type SipConfig = {
    realm: string;
    uri: string;
    user: string;
    pass: string;
    server: string;
    displayName: string;
}
export class SipPhone {

    protected agent: UserAgent | null = null;
    private currentSession: Session & Inviter | null = null;
    private destination: string | null = null;
    private pendingInvite: boolean = false;
    private connected: boolean = false;
    private currentConfig: SipConfig | null = null;
    private currentCallId: string | null = null;
    private currentCallDuration: number = 0;
    private currentCallTimer: number | null = null;
    private currentCallOnHold: boolean = false;
    private currentCallOnMute: boolean = false;
    private callStatus: 'init' | 'idle' | 'connecting' | 'connected' | 'ended' = 'init';

    createAgent(config: SipConfig) {
        this.currentConfig = config;
        const uri = UserAgent.makeURI(config.uri);
        const userAgentOptions: UserAgentOptions = {
            uri,
            transportOptions: {
                traceSip: true,
                server: config.server
            },
            displayName: config.displayName,
            authorizationUsername: config.user,
            authorizationPassword: config.pass,
            logLevel: 'warn'
        }
        try {
            this.callStatus = 'idle';
            this.agent = new UserAgent(userAgentOptions);
            this.agent.delegate = this.agentListener();
        } catch (e) {
            this.flushClient('createAgent', e);
        }
    }

    setCurrentCallId(callEntryId: string | null) {
        if (callEntryId) {
            this.currentCallId = callEntryId;
        }
    }

    setDestination(destination: string) {
        this.destination = destination;
    }

    async connectAgent() {
        if (this.connected) {
            console.warn('-> Cant Reconnect Already Connected');
            return;
        }
        if (this.agent) {
            try {
                await this.agent.start();
                this.connected = false;
            } catch (e) {
                this.flushClient('connectAgent', e);
            }
        }
    }

    // Handle Socket Closing Clearing Call Status

    async stopAgent() {
        if (this.agent) {
            await this.agent.stop();
            this.onNetworkStatusUpdate('disconnected');
            this.connected = false;
        }
    }

    onNetworkStatusUpdate(status: string) {
        console.log('networkUpdate', status)
    }

    onCallStatusUpdate(payload: CallStatusPayload) {
        console.warn('callupdate', payload.status, payload.callEntryId)
    }

    agentListener() {
        return {
            onConnect: () => {
                this.onNetworkStatusUpdate('connected');
            },
            onDisconnect: (error?: Error) => {
                this.onNetworkStatusUpdate('disconnected');
                console.log("Network Disconnected");
                this.onCallStatusUpdate({
                    status: 'ended',
                    callEntryId: this.currentCallId
                });
                if (!error) {
                    console.log("User agent stopped");
                }
            },
            onInvite: (invitation: any) => {
                console.log("INVITE received");
                //invitation.accept();
            },
            onMessage: (invitation: any) => {
                console.log("MESSAGE received");
                //message.accept();
            },
            onNotify: (invitation: any) => {
                console.log("NOTIFY received");
                //notification.accept();
            },
            onRefer: (invitation: any) => {
                console.log("REFER received");
                //referral.accept();
            },
            onRegister: () => {
                console.log("User Agent Registered");
                //referral.accept();
            },
            onSubscribe: () => {
                console.log("User Agent Subscribed");
                //referral.accept();
            },
        }
    }

    async transferCall(destination: string) {
        if (this.currentSession && destination) {
            const target = UserAgent.makeURI(`sip:${destination}@n321.fpool.io`);
            if (target) {
                await this.currentSession.refer(target)
            }
        }
    }

    async connectCall() {

        try {

            if (this.agent && this.destination) {

                if (this.pendingInvite) {
                    console.warn('-> Cant Make Call With Pending Invite');
                    return;
                }

                // Create SIP Target
                const target = UserAgent.makeURI(`sip:${this.destination}@n321.fpool.io`);//pbx201.fastpbx.io:6050

                if (!target) {
                    throw new Error("Failed to create target URI.");
                }

                const inviterOptions: InviterOptions = {
                    sessionDescriptionHandlerOptions: {
                        constraints: { audio: true, video: false }
                    },
                    extraHeaders: [`X-PID2: ${this.currentConfig?.displayName}`]
                }
                // Create a new Inviter
                const inviter = new Inviter(this.agent, target, inviterOptions);

                // An Inviter is a Session
                this.currentSession = inviter;

                // Setup outgoing session delegate
                this.currentSession.delegate = {
                    // Handle incoming REFER request.
                    onRefer(referral: Referral): void {
                        console.log('onRefer', referral)
                    },
                    onNotify(notification: Notification): void {
                        console.log('onNotify', notification)
                    },
                    onInfo(info: Info): void {
                        console.log('onInfo', info)
                    }
                };

                this.playConnect();

                // Handle outgoing session state changes.
                this.currentSession.stateChange.addListener((newState: SessionState) => {
                    console.log(newState);
                    switch (newState) {
                        case SessionState.Establishing:
                            this.startRinging();
                            this.callStatus = 'connecting';
                            this.onCallStatusUpdate({
                                status: 'connecting',
                                callEntryId: this.currentCallId
                            });
                            break;
                        case SessionState.Established:
                            this.stopRinging();
                            this.callStatus = 'connected';
                            this.onCallStatusUpdate({
                                status: 'connected',
                                callEntryId: this.currentCallId
                            });
                            this.startCallTimer();
                            this.setupRemoteMedia();
                            break;
                        case SessionState.Terminated:
                            this.playDisconnect();
                            this.callStatus = 'ended';
                            this.onCallStatusUpdate({
                                status: 'ended',
                                callEntryId: this.currentCallId,
                                duration: this.currentCallDuration
                            });
                            this.stopCallTimer();
                            this.cleanupMedia();
                            this.stopAgent();
                            break;
                        default:
                            break;
                    }
                });

                console.log('sendinginvite',this.currentSession.state)
                await this.currentSession.invite()
                this.pendingInvite = false;
            }

        } catch (e) {
            this.flushClient('connectCall', e);
        }
    }

    async endCall() { 
        try {
            if (this.currentSession) {
                if (this.currentSession.state === SessionState.Establishing || this.currentSession.state === SessionState.Initial) {
                    await this.currentSession.cancel();
                    this.stopRinging();
                } else if (this.currentSession.state !== SessionState.Terminated) {
                    await this.currentSession.bye();
                }
                await this.currentSession.dispose();
                this.flushClient('callEnded')
            }
        } catch (e) {
            this.flushClient('endCall', e);
        }
    }

    startRinging() {
        playMediaElement('outbound-audio');
    }

    stopRinging() {
        pauseMediaElement('outbound-audio');
    }

    playConnect() {
        playMediaElement('connect-audio');
    }

    playDisconnect() {
        playMediaElement('disconnect-audio');
    }

    async setHold(hold: boolean) {

        try {

            if (this.currentSession) {

                const sessionDescriptionHandler = this.currentSession.sessionDescriptionHandler;

                const options: any = {
                    requestDelegate: {
                        onAccept: () => {
                            this.currentCallOnHold = hold;
                        },
                        onReject: () => {
                            this.flushClient(`setHoldReInviteRejected`, null);
                        }
                    }
                };

                // Use hold modifier to produce the appropriate SDP offer to place call on hold
                if (hold && sessionDescriptionHandler) {
                    options.sessionDescriptionHandlerModifiers = [
                        (description: any) => sessionDescriptionHandler.holdModifier(description)
                    ];

                }

                // Send re-INVITE
                await this.currentSession.invite(options);
                
                if (this.currentCallOnMute) {
                    this.muteCall()
                } else {
                    this.unMuteCall()
                }

            }

        } catch (e) {
            this.flushClient('setHold', e);
        }

    }

    async sendDTMF(digit: number) {
        try {
            let mediaElement: HTMLMediaElement = document.getElementById('dtmf-audio') as HTMLMediaElement;
            if (this.currentSession) {
                const options = {
                    requestOptions: {
                        body: {
                            contentDisposition: "render",
                            contentType: "application/dtmf-relay",
                            content: `Signal=${digit.toString()}\r\nDuration=160`
                        }
                    }
                };
                await this.currentSession.info(options);
                await mediaElement.play();
            }
        } catch (e) {
            this.flushClient('sendDTMF', e);
        }
    }

    muteCall() {
        try {
            if (this.currentSession) {
                let session: SessionDescriptionHandler = this.currentSession.sessionDescriptionHandler as unknown as SessionDescriptionHandler;
                if (session.peerConnection) {
                    session.peerConnection.getSenders().forEach((sender: RTCRtpSender) => {
                        if (sender.track) {
                            sender.track.enabled = false;
                            this.currentCallOnMute = true;
                        }
                    });
                }
            }
        } catch (e) {
            this.flushClient('muteCall', e);
        }
    }

    unMuteCall() {
        try {
            if (this.currentSession) {
                let session: SessionDescriptionHandler = this.currentSession.sessionDescriptionHandler as unknown as SessionDescriptionHandler;
                if (session.peerConnection) {
                    session.peerConnection.getSenders().forEach((sender: RTCRtpSender) => {
                        if (sender.track) {
                            sender.track.enabled = true;
                            this.currentCallOnMute = false;
                        }
                    });
                }
            }
        } catch (e) {
            this.flushClient('unMuteCall', e);
        }
    }

    async setupRemoteMedia() {

        try {

            const remoteStream = new MediaStream();
            let mediaElement: HTMLMediaElement = document.getElementById('call-audio') as HTMLMediaElement;

            if (this.currentSession && mediaElement) {
                let session: SessionDescriptionHandler = this.currentSession.sessionDescriptionHandler as unknown as SessionDescriptionHandler;
                if (session.peerConnection) {
                    session.peerConnection.getReceivers().forEach((receiver: RTCRtpReceiver) => {
                        if (receiver.track) {
                            remoteStream.addTrack(receiver.track);
                        }
                    });
                    mediaElement.srcObject = remoteStream;
                    await mediaElement.play();
                } else {
                    console.warn('NoPeerConnection');
                }

            }

        } catch (e) {
            this.flushClient('setupRemoteMedia', e);
        }
    }

    cleanupMedia() {
        let mediaElement: HTMLMediaElement = document.getElementById('call-audio') as HTMLMediaElement;
        mediaElement.srcObject = null;
        mediaElement.pause();
    }

    startCallTimer() {
        this.currentCallDuration = 0;
        this.currentCallTimer = window.setInterval(() => {
            this.currentCallDuration = this.currentCallDuration + 1;
        }, 1000);
    }

    stopCallTimer() {
        if (this.currentCallTimer) {
            clearInterval(this.currentCallTimer);
        }
    }

    flushClient(log: string, e?: any) {
        console.log('Flushing Phone Client: ' + log)
        if (e) {
            console.error(e);
            NotificationToaster.show({
                intent: 'danger',
                message: 'Error Establishing Call Connection'
            });
        }
        this.stopCallTimer();
        this.cleanupMedia();
        if (this.agent && this.agent.state !== 'Stopped') {
            this.stopAgent();
        }
        if ( this.callStatus === 'connecting' || this.callStatus === 'connected' || this.callStatus === 'idle' ) {
            this.callStatus = 'ended';
            this.onCallStatusUpdate({
                status: 'ended',
                callEntryId: this.currentCallId,
                duration: this.currentCallDuration
            });
        }
        this.agent = null;
        this.currentSession = null;
        this.pendingInvite = false;
        this.callStatus = 'init';
    }

}