import React from 'react';
import styled, { keyframes } from 'styled-components';

import LoginQRcodeComponent from 'components/login/LoginQRcodeComponent';
import LoginPasswordComponent from 'components/login/LoginPasswordComponent';

const SkyAnimation = keyframes`
    0% { background-position: 0 0; }
    100% { background-position: 100% 0;}
`;

const LoginWrapper = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${() => `url(${process.env.PUBLIC_URL}/clouds-bg.png) center top repeat-x`};
    animation: ${SkyAnimation} 180s linear;
`;

export const CodeLogin = () => {
    return (
        <LoginWrapper>
            <LoginQRcodeComponent/>
        </LoginWrapper>
    );
}

export const PasswordLogin = () => {
    return (
        <LoginWrapper>
            <LoginPasswordComponent/>
        </LoginWrapper>
    );
}