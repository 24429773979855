import { AsyncAction, Action } from 'overmind';
import { GetUser_user } from 'state/effects/gql/user/_types/GetUser';

export const logOut: Action = ({state,effects,actions}) => {
    state.user.loggedIn = false;
    effects.user.removeToken();
    window.location.hash = '/login';
    actions.notifications.updateNotificationHooks();
}

export const loadUser: AsyncAction<void, GetUser_user|null> = async ({state,effects}) => {
    try {
        let { user } = await effects.gql.queries.getUser();
        state.user.info = user;
        return Promise.resolve(user);
    } catch (e) {
        let error = e.response ? e.response.message : 'Error Loading User'
        state.app.error = error;
        return Promise.reject(error);
    }
}