import React from 'react';
import { ThemeProvider } from 'styled-components';

import { lightTheme } from 'utils/theme';

import Navigation from 'Navigation';
import ErrorBoundary from 'components/ui/ErrorBoundary';

const App = () => {
    return (
        <ErrorBoundary>
            <ThemeProvider theme={lightTheme}>
                <Navigation/>
            </ThemeProvider>
        </ErrorBoundary>
    );
}

export default App;