import React from 'react';

import { MessageEntry, MessageAttachments } from 'state/entries/state';
import { filterImageAttachments, scanForLinks } from 'state/entries/utils';
import ImageAttachments from './ImageAttachments';

type EntryTypeProps = {
    entry: MessageEntry;
}

type MessageAttachmentsProps = {
    attachments: MessageAttachments[];
} 

const Attachments: React.FC<MessageAttachmentsProps> = ({attachments}) => {

    const imageAttachments = filterImageAttachments(attachments);

    return (
        <div>
            {imageAttachments.length&&<ImageAttachments images={imageAttachments}/>}
        </div>
    )

}

const EntryTypeMessage: React.FC<EntryTypeProps> = ({entry}) => {
    
    const renderAttachments = () => {
        if (entry.attachments?.length) {
            return <Attachments attachments={entry.attachments}/>
        }
    }

    return (
        <div>
            <div dangerouslySetInnerHTML={{__html: scanForLinks(entry.body)}}></div>
            {renderAttachments()}
        </div>
    );
}

export default EntryTypeMessage;