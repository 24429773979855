import React from 'react';
import styled from 'styled-components'
import { Colors, H4, Overlay, Button, Tooltip } from '@blueprintjs/core';

import Icon from 'components/ui/Icon';
import { FaxEntry } from 'state/entries/state';
import FaxPreview from './FaxPreview';

const FaxComponent = styled.div`
    background-color: ${Colors.LIGHT_GRAY5};
    height: 116px;
    width: 90px;
    border-radius: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
`;

const FaxLabel = styled(H4)`
    margin: 10px 0 0;
    padding: 0;
    color: ${Colors.GRAY1};
`;

type EntryTypeProps = {
    entry: FaxEntry;
    mode?: 'full' | 'small';
}

const EntryTypeFax: React.FC<EntryTypeProps> = ({entry,mode='full'}) => {
    
    let attachments = entry.attachments ? entry.attachments : [];

    const [ isOpen, toggleOpen ] = React.useState(false);
    const onClose = () => {
        toggleOpen(!isOpen)
    }

    const renderFullPreview = () => {
        return (
            <FaxComponent className="bp3-elevation-1" onClick={()=>toggleOpen(true)}>
                <Icon name="file" color={Colors.GRAY1} width={10} />
                <FaxLabel>Fax</FaxLabel>
            </FaxComponent>
        )
    };

    const renderIconPreview = () => {
        return (
            <Tooltip boundary="window" content="View Fax">
                <Button small outlined icon={<Icon name="eye"/>} onClick={()=>toggleOpen(true)}/>
            </Tooltip>
        )
    };

    return (
        <div>
            {attachments.map((attachment, key)=>{
                if (attachment.extension==='pdf') {
                    return (
                        <React.Fragment key={key}>
                            {mode==='full'?renderFullPreview():renderIconPreview()}
                            <Overlay isOpen={isOpen} onClose={onClose} portalClassName="fax-preview-portal">
                                <FaxPreview fileUrl={attachment.url} onClose={onClose}/>
                            </Overlay>
                        </React.Fragment>
                    );
                } else {
                    return null;
                }
            })}
        </div>
    );
}

export default EntryTypeFax;