import React from 'react';
import styled from 'styled-components';
import { Spinner, Intent } from '@blueprintjs/core';

const LoadingWrapper = styled.div`
    display: grid;
    place-items: center center;
`;

type LoadingComponentProps = {
    intent?: Intent;
    size?: number;
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({intent='primary', size=60}) => {
    return (
        <LoadingWrapper>
            <Spinner intent={intent} size={size} />
        </LoadingWrapper>
    );
}

export default LoadingComponent;