import { gql, Query } from 'overmind-graphql';
import { GetMyContacts } from 'state/effects/gql/contacts/_types/GetMyContacts';

export const getContacts: Query<GetMyContacts> = gql`
  query GetMyContacts {
    contacts: GetMyContacts {
        id
        address_book_id
        device_id
        record_id
        first_name
        last_name
        address
        city
        state
        zip
        contactdetails {
            id
            type
            label
            value
        }
    }
}
` as any;