import { AsyncAction, pipe, mutate } from 'overmind';
import * as Sentry from '@sentry/browser';
import {print, ASTNode} from 'graphql'

import { Conversation } from 'state/conversations/state';
import { mapArray } from 'state/utils';
import { generateDeviceId } from 'utils/env';
import { createEntry as createEntryQuery } from 'state/effects/gql/entries/mutations';
import { Entry, State } from 'state/entries/state';
import { NotificationToaster } from 'components/notifications/NotificationToaster';
import { GetEntries } from 'state/effects/gql/entries/_types/GetEntries';
import { GetFilteredEntries } from 'state/effects/gql/entries/_types/GetFilteredEntries';
import { Number } from 'state/numbers/state';
import { GetAnsweredCallsLog } from 'state/effects/gql/entries/_types/GetAnsweredCallsLog';

export const getEntries: AsyncAction<Conversation['id'], GetEntries> = async ({state,effects}, conversation_id) => {
    let { limit, offset } = state.entries;
    return await effects.gql.queries.getEntries({ conversation_id, limit, offset });
};

export const getAnsweredCallsLog: AsyncAction<Number['number_id'], GetAnsweredCallsLog> = async ({state,effects}, number_id) => {
    let { limit, offset } = state.entries;
    return await effects.gql.queries.getAnsweredCallsLog({ number_id, limit, offset });
};

export const getMoreConversationEntries: AsyncAction = pipe(
    mutate(({state}) => {
        state.entries.loadingMore = true;
        state.entries.offset = state.entries.offset + state.entries.limit;
    }),
    mutate(async ({state,actions}) => {
        if (state.conversations.currentItemId) {
            let { entries } = await actions.entries.getEntries(state.conversations.currentItemId);
            if (entries) {
                let data = mapArray(entries,'id');
                state.entries.items = {...data, ...state.entries.items };
                state.entries.moreLoaded = entries.length;
            };
        }
    }),
    mutate(({state}) => {
        state.entries.loadingMore = false;
    })
);

export const getConversationEntries: AsyncAction<Conversation['id']> = pipe(
    mutate(({state}) => {
        state.entries.loading = true;
        state.entries.offset = 0;
        state.entries.moreLoaded = null;
        state.entries.displayOrder = 'newestOnBottom';
    }),
    mutate(async ({state,actions}, conversation_id) => {
        let { entries } = await actions.entries.getEntries(conversation_id);
        if (entries) {
            let data = mapArray(entries,'id');
            state.entries.items = data;
        };
    }),
    mutate(({state}) => {
        state.entries.loading = false;
    })
);

type GetNumberEntriesInput = {
    message_type: State['message_type'];
    direction: State['direction'];
}

export const getFilteredEntries: AsyncAction<Number['number_id'], GetFilteredEntries> = async ({state,effects}, number_id) => {
    let { message_type, direction, limit, offset } = state.entries;
    return await effects.gql.queries.getFilteredEntries({ number_id, message_type, direction, limit, offset });
};

export const getNumberEntries: AsyncAction<GetNumberEntriesInput> = pipe(
    mutate(({state}, { message_type, direction }) => {
        state.entries.loading = true;
        state.entries.offset = 0;
        state.entries.moreLoaded = null;
        state.entries.message_type = message_type;
        state.entries.direction = direction;
        state.entries.displayOrder = 'newestOnTop';
    }),
    mutate(async ({state,effects,actions}) => {
        let number_id = state.numbers.currentItem?.number?.id;
        if(number_id){
            let { entries } = await actions.entries.getFilteredEntries(number_id);
            if (entries) {
                let data = mapArray(entries,'id');
                state.entries.items = data;
            }
        }
    }),
    mutate(({state}) => {
        state.entries.loading = false;
    })
);

export const getMoreNumberEntries: AsyncAction = pipe(
    mutate(({state}) => {
        state.entries.loadingMore = true;
        state.entries.offset = state.entries.offset + state.entries.limit;
    }),
    mutate(async ({state,actions}) => {
        let number_id = state.numbers.currentItem?.number?.id;
        if(number_id) {
            let { entries } = await actions.entries.getFilteredEntries(number_id);
            if (entries) {
                let data = mapArray(entries,'id');
                state.entries.items = {...data, ...state.entries.items };
            };
        }
    }),
    mutate(({state}) => {
        state.entries.loadingMore = false;
    })
);

export const getCallLogEntries: AsyncAction = pipe(
    mutate(({state}) => {
        state.entries.loading = true;
        state.entries.offset = 0;
        state.entries.moreLoaded = null;
        state.entries.displayOrder = 'newestOnTop';
    }),
    mutate(async ({state,effects,actions}) => {
        let number_id = state.numbers.currentItem?.number?.id;
        if(number_id){
            let { entries } = await actions.entries.getAnsweredCallsLog(number_id);
            if (entries) {
                let data = mapArray(entries,'id');
                state.entries.items = data;
            }
        }
    }),
    mutate(({state}) => {
        state.entries.loading = false;
    })
);

export const getMoreCallLogEntries: AsyncAction = pipe(
    mutate(({state}) => {
        state.entries.loadingMore = true;
        state.entries.offset = state.entries.offset + state.entries.limit;
    }),
    mutate(async ({state,actions}) => {
        let number_id = state.numbers.currentItem?.number?.id;
        if(number_id) {
            let { entries } = await actions.entries.getAnsweredCallsLog(number_id);
            if (entries) {
                let data = mapArray(entries,'id');
                state.entries.items = {...data, ...state.entries.items };
            };
        }
    }),
    mutate(({state}) => {
        state.entries.loadingMore = false;
    })
);

type CreateEntryInput = {
    type: 'text' | 'call' | 'fax';
    body?: string;
    file?: File | null;
}

export const createEntry: AsyncAction<CreateEntryInput> = pipe(
    mutate(({state}) => {
        state.entries.sending = true;
    }),
    mutate(async ({state,actions,effects}, input) => {

        if (input.file) {
            await actions.entries.createEntryWithAttachment({
                type: input.type as 'text' | 'fax',
                body: input.body,
                file: input.file
            });
        } else {
            await actions.entries.createSimpleEntry(input);
        }
    
    }),
    mutate(({state}) => {
        state.entries.sending = false;
    }),
)

export const createSimpleEntry: AsyncAction<CreateEntryInput> = async ({state,effects}, input) => {

    let { type, body } = input;

    let currentConversationId = state.conversations.currentItemId;
    let number_from = state.numbers.currentItem?.number?.didnumber;
    let number_to = state.conversations.currentItem?.contact_number;

    try {

        if (currentConversationId&&number_to&&number_from) {
            let { entry } = await effects.gql.mutations.createEntry({
                input: {
                    type,
                    number_from,
                    number_to,
                    body
                }
            });
            let createdEntry = entry as Entry;
            if (entry) {

                if ( window.location.hash.indexOf('/calls') > 0 ) {
                    if (createdEntry.type==='call') {
                        state.entries.items[entry?.id] = entry as Entry; 
                        state.conversations.items[currentConversationId].last_entry = entry as Entry;
                    }
                } else {
                    state.entries.items[entry?.id] = entry as Entry; 
                    state.conversations.items[currentConversationId].last_entry = entry as Entry;
                }
    
                if (createdEntry.type==='call') {
                    state.phone.outboundCallEntryId = createdEntry.id;
                    state.phone.activeCallConversationId = currentConversationId || null;
                    state.phone.activeCallDirection = 'out';
                    state.phone.activeCallNumberId = state.numbers.currentItem?.number_id || null;
                } else {
                    NotificationToaster.show({
                        intent: 'success',
                        message: 'Message Sent'
                    });
                }
    
            }
        } else {
            console.log(4)
        }

    } catch (e) {
        Sentry.captureException(e);
        NotificationToaster.show({
            icon: 'warning-sign',
            intent: 'danger',
            message: type === 'call' ? 'Error Initializing Call' : 'Error Creating Message'
        });
    }  

}

type CreateEntryInputRich = {
    type: 'text' | 'fax'
    body?: string;
    file: File;
}

export const createEntryWithAttachment: AsyncAction<CreateEntryInputRich> = async ({state,effects}, input) => {
    
    let currentConversationId = state.conversations.currentItemId;
    let number_from = state.numbers.currentItem?.number?.didnumber;
    let number_to = state.conversations.currentItem?.contact_number;

    if (currentConversationId&&number_to&&number_from) {

        let token = effects.user.getToken();

        let bodyFormData = new FormData();

        let query: ASTNode = createEntryQuery as unknown as ASTNode;

        bodyFormData.append('operations', JSON.stringify({
            query: print(query),
            variables: {
                input: {
                    type: input.type,
                    number_from: number_from,
                    number_to: number_to,
                    body: input.body
                },
                file: null
            }
        }));

        bodyFormData.append('map', JSON.stringify({'file':['variables.file']}));
        bodyFormData.append('file', input.file);

        let response = await fetch((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_GQL_API_URL : process.env.REACT_APP_GQL_API_URL_DEV) || '', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`, 
                'Device-Id': generateDeviceId()
            },
            body: bodyFormData
        });

        let json = await response.json();
        let entry = json.data.entry as Entry;

        if (entry) {
            state.entries.items[entry?.id] = entry; 
            state.conversations.items[currentConversationId].last_entry = entry;
        }

    }

}