export const saveOfflineNotificationsSetting = (enabled: boolean) => {
    localStorage.setItem('settings.offlineNotifications', enabled?'1':'0');
}

export const saveCallNotificationsSetting = (enabled: boolean) => {
    localStorage.setItem('settings.receiveCalls', enabled?'1':'0');
}

export const saveTextNotificationsSetting = (enabled: boolean) => {
    localStorage.setItem('settings.receiveTexts', enabled?'1':'0');
}

export const saveCallSoundsSetting = (enabled: boolean) => {
    localStorage.setItem('settings.playCallSounds', enabled?'1':'0');
}

export const saveDialingPrefixSetting = (prefix: string|number) => {
    localStorage.setItem('settings.dialingPrefix', prefix.toString());
}

export const saveTextSoundsSetting = (enabled: boolean) => {
    localStorage.setItem('settings.playTextSounds', enabled?'1':'0');
}

export const saveSawUpdatesSetting = (enabled: boolean) => {
    localStorage.setItem('settings.sawUpdates', enabled?'1':'0');
}

type LocalStorageUserSettings = {
    offlineNotifications: boolean;
    receiveCalls: boolean;
    receiveTexts: boolean;
    playCallSounds: boolean;
    playTextSounds: boolean;
    sawUpdates: boolean;
    dialingPrefix: string;
}

export const loadUserSettings = (): LocalStorageUserSettings => {

    let offlineNotificationsRaw = localStorage.getItem('settings.offlineNotifications');
    let receiveCallsRaw = localStorage.getItem('settings.receiveCalls');
    let receiveTextsRaw = localStorage.getItem('settings.receiveTexts');
    let playCallSoundsRaw = localStorage.getItem('settings.playCallSounds');
    let playTextSoundsRaw = localStorage.getItem('settings.playTextSounds');
    let dialingPrefix = localStorage.getItem('settings.dialingPrefix') || '9';
    let sawUpdatesRaw = localStorage.getItem('settings.sawUpdates');
    
    let offlineNotifications = offlineNotificationsRaw ? !!parseInt(offlineNotificationsRaw) : true;
    let receiveCalls = receiveCallsRaw ? !!parseInt(receiveCallsRaw) : true;
    let receiveTexts = receiveTextsRaw ? !!parseInt(receiveTextsRaw) : true;
    let playCallSounds = playCallSoundsRaw ? !!parseInt(playCallSoundsRaw) : true;
    let playTextSounds = playTextSoundsRaw ? !!parseInt(playTextSoundsRaw) : true;
    let sawUpdates = sawUpdatesRaw ? !!parseInt(sawUpdatesRaw) : false;

    return {
        offlineNotifications,
        receiveCalls,
        receiveTexts,
        playCallSounds,
        playTextSounds,
        sawUpdates,
        dialingPrefix
    }

}