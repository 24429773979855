import React from 'react';
import styled from 'styled-components';
import { Colors, Button, Tooltip } from '@blueprintjs/core';
import classnames from 'classnames';

import { useOvermind } from 'state';
import { getContactLabel, getMatchingDetails } from 'state/contacts/utils';
import { FilteredEntry, VoicemailEntry, MessageEntry } from 'state/entries/state';

import { formatPhoneNumber, lookUpAreaCode } from 'utils/numbers';
import { capitalizeWord } from 'utils/strings';
import { formatTimeStamp } from 'utils/dates';

import { RenderPreviewIcon } from 'components/entries/EntryPreview';
import Icon from 'components/ui/Icon';
import EntryTypeVoicemail from 'components/entries/EntryTypeVoicemail';
import EntryTypeFax from 'components/entries/EntryTypeFax';

const EntryItemComponent = styled.div`
    background-color: ${Colors.LIGHT_GRAY3};
    border-radius: 6px;
    margin-bottom: 1px;
    padding: 10px;
    grid-gap: 5px;
    display: grid;
    grid-template-columns: 20px 80px auto max-content;
    &.voicemail {
        grid-template-columns: 20px 80px 30% auto max-content;
    }
    & .center-v {
        align-self: center;
    }
    & .center-h {
        justify-self: center;
    }
    &.clickable {
        cursor: pointer;
    }
    &.clickable:hover {
        background-color: ${Colors.LIGHT_GRAY1};
    }
`;

const EntryActions = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
    & .bp3-popover-wrapper:first-child {
        margin-left:0;
    }
    & .bp3-popover-wrapper {
        margin-left: 5px;
    }
`;

type EntryItemProps = {
    entry: FilteredEntry;
    onClick?(): void;
}

const EntryItem: React.FC<EntryItemProps> = ({entry,onClick}) => {

    const { state, actions } = useOvermind();

    const hasOnClickProp = onClick&&typeof onClick === 'function';

    const contact = entry.conversation && entry.conversation.contact_id ? state.contacts.items[entry.conversation.contact_id] : null;

    const fromNumber = entry.conversation ? formatPhoneNumber(entry.conversation.contact_number) : 'Unknown Number';

    const sourceLocation = entry.conversation && entry.conversation.contact_number ? lookUpAreaCode(entry.conversation.contact_number ) : 'Unknown Location';

    let contactLabel = contact ? getContactLabel(contact) : entry.conversation ? fromNumber : 'Unknown Contact';

    let contactDetail = null;
    if ( entry.conversation && contact && contact.contactdetails.length > 1 ) {
        contactDetail = getMatchingDetails(contact, entry.conversation.contact_number); 
    }

    const navigateToConversation = () => {
        if (entry.conversation) {
            actions.app.updateNavigationState({
                route: 'conversations',
                conversation_id: entry.conversation.id
            })
        }
    }

    const initiateCall = () => {
        if (entry.conversation) {
            actions.app.updateNavigationState({
                route: 'conversations',
                conversation_id: entry.conversation.id,
                callback: () => { 
                    if(entry.conversation){
                        actions.conversations.createConversationEntry({
                            type: 'call',
                            destinationNumber: entry.conversation.contact_number
                        });
                    }
                }
            }) 
        }
    }

    const handleClick = () => {
        if(onClick&&hasOnClickProp) {
            onClick()
        }
    }

    const genericEntryActions = () => {
        return (
            <React.Fragment>
                <Tooltip boundary="window" content="Go To Conversation">
                    <Button small outlined icon={<Icon name="link" width={18}/>} onClick={navigateToConversation}/>
                </Tooltip>
                <Tooltip boundary="window" content="Call">
                    <Button small outlined icon={<Icon name="phone" width={18}/>} onClick={initiateCall}/>
                </Tooltip>
                <Tooltip boundary="window" content="Send Message">
                    <Button small outlined icon={<Icon name="envelope" width={18}/>} onClick={navigateToConversation}/>
                </Tooltip>
                {/* <Tooltip boundary="window" content={`Delete ${capitalizeWord(entry.type)}`}>
                    <Button disabled={true} small outlined icon={<Icon name="trash" color={Colors.RED1}/>} onClick={navigateToConversation}/>
                </Tooltip> */}
            </React.Fragment>
        )
    }

    const renderEntryActions = () => {
        switch(entry.type) {
            case 'call':
                return null;
            case 'voicemail':
                return genericEntryActions();
            case 'fax':
                return (
                    <React.Fragment>
                        <EntryTypeFax entry={entry as MessageEntry} mode="small"/>
                        {genericEntryActions()}
                    </React.Fragment>
                );
        }
    }

    return (
        <EntryItemComponent onClick={handleClick} className={classnames(entry.type,{ clickable: hasOnClickProp })}>
            <div className="center-v center-h" style={{paddingLeft: "5px"}}><RenderPreviewIcon direction={entry.direction} type={entry.type} status={entry.status} /></div>
            <div className="center-v center-h">{formatTimeStamp(entry.time_stamp)}</div>
            <div className="center-v"><strong>{contactLabel} {contactDetail&&`(${capitalizeWord(contactDetail.label)})`}</strong><small>{contact?` - ${fromNumber}`:` - ${sourceLocation}`}</small></div>
            {(()=>{
                switch(entry.type) {
                    case 'voicemail':
                        return <EntryTypeVoicemail entry={entry as VoicemailEntry} />;
                }
            })()}
            <EntryActions>
                {renderEntryActions()}
            </EntryActions>
        </EntryItemComponent> 
    )
}

export default EntryItem;