import { gql, Query } from 'overmind-graphql';
import { GetConversations, GetConversationsVariables } from 'state/effects/gql/conversations/_types/GetConversations';
import { GetConversation, GetConversationVariables } from './_types/GetConversation';
import { GetExistingConversation, GetExistingConversationVariables } from './_types/GetExistingConversation';

export const getConversations: Query<GetConversations, GetConversationsVariables> = gql`
  query GetConversations (
    $number_id: String!,
    $type_filter: String!
    $limit: Int,
    $offset: Int
  ) {
    conversations: GetNumberConversations (
        number_id: $number_id,
        type_filter: $type_filter,
        limit: $limit,
        offset: $offset
    ) {
        id
        status
        contact_number
        contact_id
        unread_messages
        last_entry {
          id
          status
          type
          direction
          body
          time_stamp
        }
    }
  }
` as any;

export const getConversation: Query<GetConversation, GetConversationVariables> = gql`
  query GetConversation (
    $conversation_id: String!,
    $number_id: String!
  ) {
    conversation: GetConversation (
        conversation_id: $conversation_id,
        number_id: $number_id
    ) {
        id
        status
        contact_number
        contact_id
        unread_messages
        last_entry {
          id
          status
          type
          direction
          body
          time_stamp
        }
    }
  }
` as any;

export const getExistingConversation: Query<GetExistingConversation, GetExistingConversationVariables> = gql`
  query GetExistingConversation (
    $destination_number: String!,
    $number_id: String!
  ) {
    conversation: GetExistingConversation (
        destination_number: $destination_number,
        number_id: $number_id
    ) {
        id
        status
        contact_number
        contact_id
        unread_messages
        last_entry {
          id
          status
          type
          direction
          body
          time_stamp
        }
    }
  }
` as any;