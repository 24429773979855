import { State, Conversation } from './state';
import { Contact } from 'state/contacts/state';

type ConversationViewFilter = { 
    [key in State['currentView']]: string[];
}

export const filterByView = (items: State['items'], view: State['currentView']): Conversation[] => {
    let filters: ConversationViewFilter  = {
        inbox: ['new', 'read'],
        unread: ['new'],
        archived: ['archived']
    };  
    return Object.values(items).filter((conversation)=>{
        return filters[view].some((status)=>{
            return status === conversation.status;
        })
    });
}

export const sortByActivity = (items: Conversation[]) => {
    return items.sort((a,b)=>{
        if (a.last_entry?.time_stamp && b.last_entry?.time_stamp) {
            return b.last_entry?.time_stamp - a.last_entry?.time_stamp;
        } else {
            return 0;
        }
    });
}

export const getConversationByContact = (contact: Contact, conversations: State['items']): Conversation | null => {
    let matchedConversation = null;
    Object.keys(conversations).forEach((conversationId)=>{
        let conversation = conversations[conversationId];
        if ( conversation.status !== 'archived' && conversation.contact_id === contact.id ) {
            matchedConversation = conversation;
        }
    });
    return matchedConversation;
}

export const getConversationByContactNumber = (contactNumber: string, conversations: State['items']): Conversation | null => {
    let matchedConversation = null;
    Object.keys(conversations).forEach((conversationId)=>{
        let conversation = conversations[conversationId];
        if ( conversation.status !== 'archived' && conversation.contact_number === contactNumber ) {
            matchedConversation = conversation;
        }
    });
    return matchedConversation;
}