import React from 'react';
import styled from 'styled-components';
import { H4, Card } from '@blueprintjs/core';

import { useOvermind } from 'state';
import LoadingComponent from 'components/ui/LoadingComponent';
import { Number } from 'state/numbers/state';
import { formatPhoneNumber } from 'utils/numbers';

const WrapperComponent = styled.div`
    display: grid;
    place-items: center center;
`;

const Container = styled(Card)`
    width: 400px;
`;

const NumberListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;   
    cursor: pointer;
    border-bottom: 1px solid ${props => props.theme.altBorder};
    &:hover {
        background: ${props => props.theme.focusBG};
    }
    background: ${props => props.theme.altBG};
`;

type NumberSelectListItemProps = {
    number: Number;
}

const ExtensionSelectListItem: React.FC<NumberSelectListItemProps> = ({number}) => {

    let { number: e } = number;

    const { actions } = useOvermind();

    const onNumberSelect = () => {
        actions.numbers.selectNumber(number.id);
    }

    return (
        <NumberListItem onClick={onNumberSelect}>
            <strong>{e?.label}</strong>
            <span>{`${formatPhoneNumber(e?.didnumber)}`}</span>
        </NumberListItem>
    )

}

const NumberSelect: React.FC = () => {

    const { state, actions } = useOvermind();

    React.useEffect(()=>{
        if(state.extensions.currentItem){
            let {extension} = state.extensions.currentItem;
            if(extension) {
                actions.numbers.getNumbers(extension.id);
            }
        }
    },[state.extensions.currentItem, actions.numbers]);


    return (
        <WrapperComponent>
            <Container elevation={2}>
                <H4>Select An Number</H4>
                {state.numbers.loading&&<LoadingComponent/>}
                {state.numbers.itemsList.map((number, k)=>{
                    return (
                        <ExtensionSelectListItem key={k} number={number}/>
                    )
                })}
            </Container>
        </WrapperComponent>
    )

}

export default NumberSelect;