import React from 'react';
import styled from 'styled-components';
import { H4, Colors, Drawer, Button, FormGroup, InputGroup, Classes, ControlGroup, HTMLSelect, Callout, Popover, H5 } from '@blueprintjs/core';
import { string, object, array } from 'yup';

import { useOvermind } from 'state';
import { getContactAvatar } from 'state/contacts/utils';
import { Contact } from 'state/contacts/state';
import { setObjectByString, getObjectByString, getObjectsDiff } from 'utils/object';
import Icon from 'components/ui/Icon';

const ContactAvatar = styled.div`
    background: ${Colors.BLUE5};
    color: ${Colors.WHITE};
    font-weight: 900;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
`;

export const renderContactAvatar = (contact: Contact) => {
    let avatar = getContactAvatar(contact);
    if ( !avatar ) return <Icon name="user" width={10} color={Colors.BLUE5}/>;
    return <ContactAvatar>{avatar}</ContactAvatar>;
}

interface contactFormState {
    id?: string | undefined;
    first_name: string | '';
    last_name: string | '';
    contactdetails: {
        id?: string | undefined;
        type: 'phone' | 'email';
        label: string | '';
        value: string | '';
    }[]
}

type contactFormAction =
| { type: 'loadNewContact', number: string }
| { type: 'loadExistingContact', contact: Contact }
| { type: 'setDetail', field: string, value: any }
| { type: 'addContactDetail' }
| { type: 'removeContactDetail', index: number }
| { type: 'setContactDetail', index: number, field: string, value: any };  

const initialContactFormState: contactFormState = {
    first_name: '',
    last_name: '',
    contactdetails: []
}

const contactFormReducer = (state: contactFormState, action: contactFormAction): contactFormState => {
    switch (action.type) {
        case 'loadNewContact':
            return {
                first_name: '',
                last_name: '',
                contactdetails: [
                    {
                        type: 'phone',
                        label: 'Primary',
                        value: action.number
                    }
                ]
            };
        case 'loadExistingContact':
            let { id, first_name, last_name, contactdetails } = action.contact;
            return {
                id,
                first_name: first_name as string,
                last_name: last_name as string,
                contactdetails
            };
        case 'setDetail':
            return {
                ...state,
                [action.field]: action.value
            };
        case 'addContactDetail': 
            return {
                ...state,
                contactdetails: [
                    ...state.contactdetails,
                    {
                        type: 'phone',
                        label: '',
                        value: ''
                    }
                ]
            }
        case 'setContactDetail': 
            return {
                ...state,
                contactdetails: [
                    ...state.contactdetails.slice(0, action.index),
                    {
                        ...state.contactdetails[action.index],
                        [action.field]: action.value
                    },
                    ...state.contactdetails.slice(action.index + 1)
                ]
            }
        case 'removeContactDetail':
            return {
                ...state,
                contactdetails: [
                    ...state.contactdetails.slice(0, action.index),
                    ...state.contactdetails.slice(action.index + 1)
                ]
            }
        default:
            return state;
    }

}

const schema = object().shape({
    first_name: string().required('Contact First Name is required'),
    last_name: string(),
    contactdetails: array().required('Contact needs at least one detail').of(object().shape({
        label: string().required('Detail Label is required'),
        value: string().required('Detail Value is required')
    }))
});

type ContactViewProps = {
    setEditContactHandler(handler: ()=>void): void;
    contactNumber?: string;
}

const ContactView: React.FC<ContactViewProps> = ({setEditContactHandler,contactNumber}) => {

    const { state, actions } = useOvermind();
    const [ editorOpen, toggleEditor ] = React.useState(false);
    const [ formState, formSet ] = React.useReducer(contactFormReducer, initialContactFormState);
    const [ validation, setValidation ] = React.useState<{[index: string]: any}>({});
    const [ isValid, setValid ] = React.useState(false);

    const onAddContactDetail = () => {
        formSet({ type: 'addContactDetail' });
    };

    const onSaveContact = () => {
        if (isValid) {
            let contactPayload: any = formState;
            if(formState.id&&state.contacts.currentItem) {
                contactPayload = getObjectsDiff(state.contacts.currentItem, formState, false, true, ['contactdetails']);
            }
            actions.contacts.saveContact({
                conversationId: state.conversations.currentItemId,
                contact: contactPayload
            });
            toggleEditor(false);
        }
    };

    React.useEffect(() => {
        let validationState: any = {};
        schema.validate(formState, { abortEarly: false }).then(()=>{
            setValid(true);
            setValidation({});
        }).catch((errors)=>{
            errors.inner.forEach((i: any)=>{
                setObjectByString(validationState, i.path, i.message);
            });
            setValidation(validationState);
            setValid(false);
        });     
    },[formState])

    const getFieldValidation = (name: string) => {
        let fieldValidation: any = getObjectByString(validation, name);
        if (!fieldValidation) return null;
        if (fieldValidation && Array.isArray(fieldValidation)) return null; 
        if (fieldValidation && typeof fieldValidation === 'object') return Object.values(fieldValidation).join(', ');
        return fieldValidation;
    }

    const editContact = React.useCallback(() => {
        if (state.contacts.currentItem) {
            formSet({ type: 'loadExistingContact', contact: state.contacts.currentItem })
        } else {
            formSet({ type: 'loadNewContact', number: contactNumber ? contactNumber : state.conversations.currentItem ? state.conversations.currentItem.contact_number: '' })
        }
        toggleEditor(true)
    },[state.contacts.currentItem,state.conversations.currentItem,contactNumber])

    React.useEffect(()=>{
        setEditContactHandler(editContact);
    },[setEditContactHandler,editContact])

    const onDeleteContactDetail = async (detailId: string) => {
        await actions.contacts.deleteContactDetail(detailId);
        if (state.contacts.currentItem) {
            formSet({ type: 'loadExistingContact', contact: state.contacts.currentItem })
        }
    }
    
    return (
        <Drawer
            icon={null}
            title="Save Contact"
            size="500px"
            onClose={()=>toggleEditor(false)}
            isOpen={editorOpen}
        >
            <div className={Classes.DRAWER_BODY}>
                <div className={Classes.DIALOG_BODY}>

                    <H4>Basic Details</H4>

                    <FormGroup label="First Name" labelFor="first-name" helperText={getFieldValidation('first_name')} intent={getFieldValidation('first_name')===null?'none':'danger'}>
                        <InputGroup autoComplete="nope" id="first-name" value={formState.first_name} onChange={(e: any)=>formSet({type: 'setDetail', field: 'first_name', value: e.target.value})} intent={getFieldValidation('first_name')===null?'none':'danger'}/>
                    </FormGroup>

                    <FormGroup label="Last Name" labelFor="last-name">
                        <InputGroup autoComplete="nope" id="last-name" value={formState.last_name} onChange={(e: any)=>formSet({type: 'setDetail', field: 'last_name', value: e.target.value})} />
                    </FormGroup>

                    <H4>Contact Details</H4>
                    {getFieldValidation('contactdetails')&&(
                        <Callout intent="danger" icon={null} style={{marginBottom: 10}}>
                            {getFieldValidation('contactdetails')}
                        </Callout>
                    )}

                    {formState.contactdetails.map((detail, k)=>(
                        <FormGroup key={k} helperText={getFieldValidation(`contactdetails[${k}]`)} intent={getFieldValidation(`contactdetails[${k}]`)===null?'none':'danger'}>
                            <ControlGroup fill={true}>
                                <HTMLSelect value={detail.type} onChange={(e: any)=>formSet({type: 'setContactDetail', index: k, field: 'type', value: e.target.value})}>
                                    <option value="phone">Phone</option>
                                    <option value="email">Email</option>
                                </HTMLSelect>
                                <InputGroup id={`l${k}`} autoComplete="nope" placeholder="Label" value={detail.label} onChange={(e: any)=>formSet({type: 'setContactDetail', index: k, field: 'label', value: e.target.value})} intent={getFieldValidation(`contactdetails[${k}].label`)===null?'none':'danger'}/>
                                <InputGroup id={`v${k}`} autoComplete="nope" className={Classes.MONOSPACE_TEXT} placeholder={detail.type==='phone'?'Phone Number':'Email Address'} value={detail.value} onChange={(e: any)=>formSet({type: 'setContactDetail', index: k, field: 'value', value: e.target.value})} intent={getFieldValidation(`contactdetails[${k}].value`)===null?'none':'danger'}/>
                                {!detail.id?(
                                    <Button icon={<Icon name="trash" color="#FFF" height={20}/>} intent="danger" onClick={()=>formSet({type: 'removeContactDetail', index: k})}/>
                                ):(
                                    <Popover position="auto" popoverClassName={Classes.POPOVER_CONTENT_SIZING}>
                                        <Button icon={<Icon name="trash" color="#FFF" height={20}/>} intent="danger"/>
                                        <div>
                                            <H5>Confirm deletion</H5>
                                            <p>Are you sure you want to delete these items? You won't be able to recover them.</p>
                                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
                                                <Button className={Classes.POPOVER_DISMISS} style={{ marginRight: 10 }}>
                                                    Cancel
                                                </Button>
                                                <Button intent="danger" className={Classes.POPOVER_DISMISS} onClick={()=>{if(detail.id) onDeleteContactDetail(detail.id);}}>
                                                    Delete
                                                </Button>
                                            </div>
                                        </div>
                                    </Popover>
                                )}
                            </ControlGroup>
                        </FormGroup>
                    ))}

                    <Button minimal outlined text="Add Contact Detail" onClick={onAddContactDetail} />

                </div>
            </div>
            <div className={Classes.DRAWER_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button large text="Save Contact" intent="success" disabled={!isValid} onClick={onSaveContact} />
                </div>  
            </div>            
        </Drawer>
    )  

};

export default ContactView;