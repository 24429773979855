import React from 'react';
import styled from 'styled-components';

import EntryList from 'components/entries/EntryList';
import ConversationViewHeader from 'components/conversations/ConversationViewHeader';
import ConversationInput from './ConversationInput';
import ConversationTabs from 'components/conversations/ConversationTabs';
import { useOvermind } from 'state';
import LoadingComponent from 'components/ui/LoadingComponent';

const LoadingWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ConversationViewLayout = styled.div`
    grid-area: ViewLayout;
    height: calc(100vh - 107px);
    display: grid;
    grid-template-areas: "ConversationTabs ConversationLayout";
    grid-template-columns: 400px 1fr;
    @media (max-width: 1300px) {
        grid-template-columns: 360px 1fr;
    }
`;

const ConversationLayout = styled.div`
    grid-area: ConversationLayout;
    display: grid;
    grid-template-areas: "ConversationView ContextPanel";
    grid-template-columns: 1fr;
    @media (max-width: 1500px) {
        grid-template-columns: 1fr;
    }
`;

const ConversationViewComponent = styled.div`
    z-index: 1;
    padding: 15px 20px 20px;
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: calc(100vh - 107px);
    background: #fbfbfb;
    grid-area: ConversationView;
`;

const ConversationView: React.FC = () => {

    const { state: { conversations: { loadingItem, currentItemId: currentConversationId } } } = useOvermind();

    return (
        <ConversationViewLayout>

            <ConversationTabs />

            {currentConversationId ? (
                <ConversationLayout>
                    {loadingItem ? (
                        <LoadingWrapper><LoadingComponent /></LoadingWrapper>
                    ) : (
                        <ConversationViewComponent>
                            <ConversationViewHeader />
                            <EntryList key={currentConversationId} />
                            <ConversationInput />
                        </ConversationViewComponent>
                    )}
                </ConversationLayout>
            ) : null}


        </ConversationViewLayout>
    );
}

export default ConversationView;