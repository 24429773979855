import { Action, AsyncAction} from "overmind";

export type UserSettings = {
    receiveCalls: boolean;
    receiveTexts: boolean;
    offlineNotifications: boolean;
    playCallSounds: boolean;
    playTextSounds: boolean;
    dialingPrefix: string;
}

export const saveSawUpdatesSetting: Action<boolean> = ({state,actions,effects}, acknowledged) => {
    state.settings.sawUpdates = acknowledged;
    effects.settings.saveSawUpdatesSetting(acknowledged);
}

export const saveUserSettings: Action<UserSettings> = ({state,actions,effects}, settings) => {

    state.settings.receiveCalls = settings.receiveCalls;
    effects.settings.saveCallNotificationsSetting(settings.receiveCalls);

    state.settings.receiveTexts = settings.receiveTexts;
    effects.settings.saveTextNotificationsSetting(settings.receiveTexts);

    state.settings.offlineNotifications = settings.offlineNotifications;
    effects.settings.saveOfflineNotificationsSetting(settings.offlineNotifications);

    state.settings.playCallSounds = settings.playCallSounds;
    effects.settings.saveCallSoundsSetting(settings.playCallSounds);

    state.settings.dialingPrefix = settings.dialingPrefix;
    effects.settings.saveDialingPrefixSetting(settings.dialingPrefix);
 
    state.settings.playTextSounds = settings.playTextSounds;
    effects.settings.saveTextSoundsSetting(settings.playTextSounds);

    actions.notifications.updateNotificationHooks();
}

export const loadUserSettings: AsyncAction = ({state,effects}) => {
    return new Promise(resolve=>{
        let { offlineNotifications, receiveCalls, receiveTexts, playCallSounds, playTextSounds, sawUpdates, dialingPrefix } = effects.settings.loadUserSettings();
        state.settings.offlineNotifications = offlineNotifications;
        state.settings.receiveCalls = receiveCalls;
        state.settings.receiveTexts = receiveTexts;
        state.settings.playCallSounds = playCallSounds;
        state.settings.playTextSounds = playTextSounds;
        state.settings.dialingPrefix = dialingPrefix;
        state.settings.sawUpdates = sawUpdates;
        return resolve();
    })
}