import { derived } from 'overmind';
import { GetNumbers_numbers } from '../effects/gql/numbers/_types/GetNumbers';

export type Number = GetNumbers_numbers;

export type State = {
    loading: boolean;
    items: {
        [key: string]: Number
    },
    itemsList: Number[]
    currentItemId: string | null;
    currentItem: Number | null;
}

export const state: State = {
    loading: false,
    items: {},
    itemsList: derived( (state: State) => Object.values(state.items) ),
    currentItemId: null,
    get currentItem() {
        return this.currentItemId ? this.items[this.currentItemId] : null
    }
}