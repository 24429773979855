import { IConfig, IContext } from 'overmind';
import { namespaced, merge } from 'overmind/config';
import { createHook } from 'overmind-react';

import { onInitialize } from './onInitialize'

import { gql } from './effects/gql';

import * as app from './app';
import * as user from './user';
import * as extensions from './extensions';
import * as numbers from './numbers';
import * as contacts from './contacts';
import * as conversations from './conversations';
import * as entries from './entries';
import * as phone from './phone';
import * as notifications from './notifications';
import * as settings from './settings';

export const config = merge(
    {
        onInitialize,
        effects: {
            gql
        }
    },
    namespaced({
        app,
        user,
        extensions,
        numbers,
        contacts,
        conversations,
        entries,
        phone,
        notifications,
        settings
    })
)

declare module 'overmind' {
    interface Config extends IConfig<typeof config> {}
}

export interface StateConfig extends IConfig<{
    state: typeof config.state,
    actions: typeof config.actions,
    effects: typeof config.effects
}> {}


export interface Context extends IContext<StateConfig> {}
export type RootAppState = Context['state']

export const useOvermind = createHook<typeof config>();