import { Colors } from "@blueprintjs/core";

export const darkTheme = {
    mainBG: Colors.DARK_GRAY5,
    altBG: Colors.DARK_GRAY2,
    focusBG: Colors.DARK_GRAY4,
    hoverBG: Colors.DARK_GRAY3,
    mainBorder: Colors.DARK_GRAY1,
    altBorder: Colors.DARK_GRAY3
};

export const lightTheme = {
    brandPrimary: '#0d85c8',
    mainBG: Colors.WHITE,
    altBG: Colors.LIGHT_GRAY3,
    focusBG: Colors.LIGHT_GRAY4,
    lightBG: Colors.LIGHT_GRAY5,
    hoverBG: Colors.WHITE,
    mainBorder: Colors.LIGHT_GRAY3,
    altBorder: Colors.LIGHT_GRAY1
};