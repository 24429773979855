import { derived } from 'overmind';

export type ContactDetail = {
    id: string;
    type: 'email' | 'phone',
    label: string;
    value: string;
}

export type Contact = {
    id: string;
    first_name: string | null;
    last_name: string | null;
    company_name?: string;
    contactdetails: ContactDetail[];
}

export type State = {
    items: {
        [key: string]: Contact
    },
    itemsList: Contact[],
    currentItemId: string | null;
    currentItem: Contact | null;
}

export const state: State = {
    items: {},
    itemsList: derived( (state: State) => Object.values(state.items) ),
    currentItemId: null,
    get currentItem() {
        return this.currentItemId ? this.items[this.currentItemId] : null
    }
}