import moment from 'moment';

export const formatTimeDuration = (d: number) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h < 10 ? h.toString().padStart(2, '0') : h;
    var mDisplay = m < 10 ? m.toString().padStart(2, '0') : m;
    var sDisplay = s < 10 ? s.toString().padStart(2, '0') : s;

  	var timeArray = [mDisplay,sDisplay];
  
  	if (h > 0) timeArray.splice(0,0,hDisplay);
  
    return timeArray.join(':'); 
}

export const formatTimeStamp = (timestamp: number, format: 'short' | 'medium' | 'long' = 'short'): string => {
    switch (format) {
        case 'short':
            return moment.unix(timestamp).format('h:mm a');
        case 'medium':
            return moment.unix(timestamp).format('h:mm:ss a');
        case 'long':
            return moment.unix(timestamp).format('dddd, MMMM Do, YYYY');
    }
}

export const formatTimeStampRelative = (timestamp: number | undefined, relative: boolean = false, today: boolean = false): string => {
    if (!timestamp) return '';
    // If Today Return Just Time
    if ( !today && moment.unix(timestamp).isSame(moment(),'day') ) return moment.unix(timestamp).format('h:mm a');
    // If Today Return Just Word
    if ( today && moment.unix(timestamp).isSame(moment(),'day') ) return 'Today';
    // If Yesterday Return Yesterday
    if ( moment.unix(timestamp).isSame(moment().subtract(1,'days'),'day') ) return 'Yesterday';
    // If Before Yesterday Return Date
    return relative ? moment.unix(timestamp).fromNow() : moment.unix(timestamp).format('MMM D');
}