export type State = {
    networkStatus: string;
    callStatus: string;
    currentDialingPrefix: string | null;
    incomingCall: boolean
    incomingCallId: string | null;
    incomingCallFrom: string | null;
    incomingCallTo: string | null;
    incomingCallContactId: string | null;
    incomingCallToLabel: string | null;
    inboundCallEntryId: string | null;
    outboundCallEntryId: string | null;
    activeCallConversationId: string | null;
    activeCallNumberId: string | null;
    activeCallDirection: 'in' | 'out' | '';
    callTimer: number;
}

export const state: State = {
    networkStatus: 'offline',
    callStatus: 'disconnected',
    currentDialingPrefix: null,
    callTimer: 0,
    incomingCall: false,
    incomingCallId: null,
    incomingCallFrom: null,
    incomingCallTo: null,
    incomingCallContactId: null,
    incomingCallToLabel: null,
    inboundCallEntryId: null,    
    outboundCallEntryId: null,
    activeCallConversationId: null,
    activeCallDirection: '', 
    activeCallNumberId: null
} 