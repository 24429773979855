import React from 'react';
import styled from 'styled-components';
import { Colors, H3, Dialog, Classes, Button, ButtonGroup, Tooltip } from '@blueprintjs/core';
import classnames from 'classnames';

import { useOvermind } from 'state';
import { formatTimeDuration } from 'utils/dates';
import { formatPhoneNumber } from 'utils/numbers';
import { capitalizeWord } from 'utils/strings';

import Icon from 'components/ui/Icon';
import DialPad from 'components/phone/DialPad';
import PhoneSounds from 'components/phone/PhoneSounds';
import IncomingCallDialog from 'components/phone/IncomingCallDialog';

const PhoneComponent = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    place-items: center;
    padding: 0 20px;
`;

const PhoneButton = styled.div`
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Colors.LIGHT_GRAY1};
    cursor: pointer;
    &.connected {
        background: ${Colors.RED1};
    }
    &.active {
        background: ${Colors.GRAY3};
        color: '#FFFFFF';
    }
`;

const DialPadWraper = styled.div`
    margin-bottom: 20px;
    padding: 0 40px 20px;
    border-bottom: 1px solid ${props => props.theme.mainBorder};
`;

const CallSummary = styled.div`
    margin-bottom: 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid ${props => props.theme.mainBorder};
`;

const ConnectedNumber = styled(H3)`
    text-align: center;
    margin: 0;
`;

const CallStatus = styled.div`
    text-align: center;
    margin-bottom: 5px;
`;

const CallTimer = styled.div`
    text-align: center;
    margin-top: 5px;
`;

const PhoneDialog = styled(Dialog)`
    width: 330px;
    & .bp3-heading {
        text-align: center;
    }
    & .bp3-dialog-body {
        text-align: center;
    }
`;

const PhoneToolBarButton = styled(Button)`
    width: 38px;
`;

type PhoneProps = {
    handleNewCall: () => void
}

const Phone: React.FC<PhoneProps> = ({handleNewCall}) => {

    const { state, actions, reaction } = useOvermind();

    const [ connected, setConnected ] = React.useState(false);
    const [ showDialPad, toggleDialpad ] = React.useState(false);
    const [ showDialog, toggleDialog ] = React.useState(false);
    const [ muted, toggleMuted ] = React.useState(false);
    const [ onHold, toggleOnHold ] = React.useState(false);
    const [ time, updateTimer ] = React.useState(0);
    const [ callStatus, setCallStatus ] = React.useState('establishing');
    const [ contactNumber, setContactNumber ] = React.useState('');
    const [ contactName, setContactName ] = React.useState<string|null>(null); 

    React.useEffect(() => {
        if (connected) {
            const interval = window.setInterval(() => {
                updateTimer(time => time + 1);
            }, 1000);
            return () => clearInterval(interval);
        }       
    }, [connected]);

    React.useEffect(() => {
        if (connected) {
            actions.phone.updateCallTimer(time);
        }       
    }, [connected,time,actions.phone]);

    React.useEffect(() => {
        return reaction(
            (state) => state.phone.callStatus,
            (callStatus) => {
                setCallStatus(callStatus);
                if (callStatus==='connected') {
                    setConnected(true);
                }
                if (callStatus==='ended') {
                    setConnected(false);
                    actions.phone.resetCallTimer()
                }
            }
        )
    }, [reaction,actions.phone]);

    React.useEffect(() => {
        if (!connected) {
            
            if (state.phone.incomingCall&&state.conversations.pendingActionDirection==='in') {
                let currentNumber = state.phone.incomingCallFrom ? state.phone.incomingCallFrom : null;
                if (currentNumber) {
                    let contactLabel = actions.contacts.findContactLabelByNumber(currentNumber);
                    if (contactLabel) {
                        setContactName(contactLabel)
                    }
                }
                setContactNumber(currentNumber || '');
                actions.phone.connectInboundCall();
            }
            if (state.conversations.pendingActionDirection==='out') {
                let currentNumber = state.conversations.currentItem?.contact_number ? state.conversations.currentItem?.contact_number : null;
                if(currentNumber){
                    let contactLabel = actions.contacts.findContactLabelByNumber(currentNumber);
                    if (contactLabel) {
                        setContactName(contactLabel)
                    }
                    setContactNumber(currentNumber || '');
                    actions.phone.makeOutBoundCall(currentNumber);
                }
            }

        }
    }, [
        actions.phone,
        actions.contacts,
        connected,
        state.conversations.currentItem,
        state.conversations.pendingActionDirection,
        state.phone.incomingCall,
        state.contacts,
        state.phone.incomingCallFrom
    ])

    const handleEndCall = () => {
        actions.phone.endCall();
    }

    const handleToggleDialpad = () => {
        toggleDialpad(!showDialPad);
    }

    const handleToggleMute = () => {
        if (!muted) {
            toggleMuted(true);
            actions.phone.muteCall();
        } else {
            toggleMuted(false);
            actions.phone.unMuteCall();
        }
    }

    const handleToggleHold = () => {
        if (!onHold) {
            toggleOnHold(true);
            actions.phone.holdCall();
        } else {
            toggleOnHold(false);
            actions.phone.unHoldCall();
        }
    }

    const onDigitPress = (digit: number) => {
        actions.phone.sendDTMF(digit);
    }

    const handleShowDialpad = () => {
        handleToggleDialpad();
        toggleDialog(true);
    }
    
    const CallPreview = () => {
        return (
            <React.Fragment>
                {connected?(
                    <React.Fragment>
                        <span>{`${contactName?contactName:formatPhoneNumber(contactNumber)} `}</span>
                        <small className={Classes.MONOSPACE_TEXT}>{`${connected?`${formatTimeDuration(time)}`:''}`}</small>
                    </React.Fragment>
                ):(
                    <span>{`${capitalizeWord(callStatus)}...`}</span>
                )}
            </React.Fragment>
        )
    }

    return (
        <div>
            <ButtonGroup>
                
                <Tooltip content="Show Call Screen" position="top">
                    <Button icon={<Icon name={onHold?'phoneHold':'phoneOn'} color={Colors.WHITE} width={24}/>} text={<CallPreview/>} intent={!connected?'warning':onHold?'warning':'success'} onClick={()=>toggleDialog(true)}/>
                </Tooltip>
                
                <Tooltip content="Show Dialpad" position="top">
                    <PhoneToolBarButton icon={<Icon name="keypad" color={Colors.GRAY1} width={24}/>} onClick={handleShowDialpad}/>
                </Tooltip>
                
                <Tooltip content={onHold?'Remove From Hold':'Place On Hold'} intent={muted?'warning':'none'} position="top">
                    <PhoneToolBarButton intent={onHold?'warning':'none'} icon={<Icon name={onHold?'phoneActive':'phoneHold'} color={onHold?Colors.WHITE:Colors.GRAY1} width={24}/>} onClick={handleToggleHold}/>
                </Tooltip>

                <Tooltip content={muted?'UnMute Microphone':'Mute Microphone'} intent={muted?'warning':'none'} position="top">
                    <PhoneToolBarButton intent={muted?'warning':'none'} icon={<Icon name={muted?'micOn':'micOff'} color={muted?Colors.WHITE:Colors.GRAY1} width={24}/>} onClick={handleToggleMute}/>
                </Tooltip>
                
                <Tooltip content="End Call" intent="danger" position="top">
                    <PhoneToolBarButton intent="danger" icon={<Icon name="hangup" color={Colors.WHITE} width={24}/>} onClick={handleEndCall}/>
                </Tooltip>

            </ButtonGroup>
            <PhoneDialog
                title={`Current Call ${onHold?'(On Hold)':muted?'(Muted)':''}`}
                isCloseButtonShown={true}
                isOpen={showDialog}
                onClose={()=>toggleDialog(false)}
            >
                <div className={Classes.DIALOG_BODY}>
                    <CallSummary>
                        <CallStatus className="bp3-text-muted">{capitalizeWord(callStatus)}</CallStatus>
                        {contactName?(
                            <React.Fragment>
                                <ConnectedNumber>{contactName}</ConnectedNumber>    
                                <CallStatus className="bp3-text-muted">{formatPhoneNumber(contactNumber)}</CallStatus>       
                            </React.Fragment>
                        ):(
                            <ConnectedNumber>{formatPhoneNumber(contactNumber)}</ConnectedNumber>    
                        )}
                                
                        <CallTimer className="bp3-text-muted">{connected&&formatTimeDuration(time)}</CallTimer>
                    </CallSummary>
                    {showDialPad&&(
                        <DialPadWraper>
                            <DialPad onDigit={onDigitPress}/>
                        </DialPadWraper>
                    )}
                    <PhoneComponent>
                        <Tooltip content="Show Dialpad"  position="bottom">
                            <PhoneButton className={classnames('bp3-elevation-2', { 'active': showDialPad })} onClick={handleToggleDialpad}>
                                <Icon name="keypad" width={28} color={showDialPad?Colors.WHITE:Colors.GRAY1} />
                            </PhoneButton>
                        </Tooltip>
                        <Tooltip content={onHold?'Remove From Hold':'Place On Hold'} intent={onHold?'warning':'none'}  position="bottom">
                            <PhoneButton className={classnames('bp3-elevation-2', { 'active': onHold })} onClick={handleToggleHold}>
                                <Icon name={onHold?'phoneActive':'phoneHold'} width={28} color={onHold?Colors.WHITE:Colors.GRAY1} />
                            </PhoneButton>
                        </Tooltip>
                        <Tooltip content={muted?'UnMute Microphone':'Mute Microphone'} intent={muted?'warning':'none'}  position="bottom">
                            <PhoneButton className={classnames('bp3-elevation-2', { 'active': muted })} onClick={handleToggleMute}>
                                <Icon name={muted?'micOn':'micOff'} width={28} color={muted?Colors.WHITE:Colors.GRAY1} />
                            </PhoneButton>
                        </Tooltip>
                        <Tooltip content="End Call" intent="danger" position="bottom">
                            <PhoneButton className={classnames('bp3-elevation-2', 'connected')} onClick={handleEndCall}>
                                <Icon name="hangup" width={28} color={Colors.WHITE}/>
                            </PhoneButton>    
                        </Tooltip>
                    </PhoneComponent>  
                </div>
            </PhoneDialog>
        </div>
    );
}

type PhoneWrapperProps = {
    handleNewCall: () => void
}

const PhoneWraper: React.FC<PhoneWrapperProps> = ({handleNewCall}) => {

    const { actions, reaction } = useOvermind();
    const [ phoneActive, setPhoneActive ] = React.useState(false);

    React.useEffect(() => {
        return reaction(
            (state) => state.phone.callStatus,
            (callStatus) => {
                if(callStatus==='ended') {
                    setPhoneActive(false);
                }
            },
            {immediate:true}
        )
    }, [reaction]);

    React.useEffect(() => {
        return reaction(
            (state) => state.conversations.pendingAction,
            (pendingAction) => {
                switch (pendingAction) {
                    case 'call':
                        setPhoneActive(true);
                        actions.conversations.clearPendingAction();
                        break;
                    case 'endcall':
                        setPhoneActive(false);
                        actions.conversations.clearPendingAction();
                        break;
                }
            },
            {immediate:true}
        )
    }, [reaction, actions]);

    return (
        <div>
            {phoneActive?(
                <Phone handleNewCall={handleNewCall}/>
            ):(
                <Button rightIcon={<Icon name="phone" color="#FFF" height={24} />} text="New Call" intent="success" onClick={handleNewCall}/>
            )}
            <IncomingCallDialog/>
            <PhoneSounds/>
        </div>
    )
}

export default PhoneWraper;