import { Contact, ContactDetail } from "state/contacts/state";
import { formatPhoneNumber } from "utils/numbers";
import { capitalizeWord } from "utils/strings";

export const isVerifiedContact = (contact: Contact): boolean => {
    return [contact.first_name,contact.last_name].some((value)=>{
        return value != null;
    })
};

export const getContactLabel = (contact: Contact | null): string | null => {
    if (!contact) return null;
    let isVerified = isVerifiedContact(contact);
    if (isVerified) {
        return `${contact.first_name||''} ${contact.last_name||''}`;
    } else {
        return contact.contactdetails.filter((detail)=>{
            return detail.type === 'phone';
        }).reduce((map, detail)=>{
            return formatPhoneNumber(detail.value);
        },'');
    }
};

export const getContactAvatar = (contact: Contact | null): string | null => {
    if (!contact) return null;
    let isVerified = isVerifiedContact(contact);
    if (isVerified) {
        let avatar = '';
        if ( contact.first_name ) avatar += contact.first_name.charAt(0).toUpperCase();
        if ( contact.last_name ) avatar += contact.last_name.charAt(0).toUpperCase();
        return avatar;
    }
    return null;
};

export const filterContacts = (contacts: Contact[], query: string | number) => {
    return contacts.filter((contact) => {
        let matchingDetails = contact.contactdetails.filter((detail) => {
            return detail.type === 'phone' && detail.value.includes(query.toString());
        });
        return isVerifiedContact(contact) && ( 
            matchingDetails.length || 
            contact.first_name?.toLowerCase().includes(query.toString().toLocaleLowerCase()) || 
            contact.last_name?.toLowerCase().includes(query.toString().toLocaleLowerCase()) 
        );
    });
}

export type FilterContactsByDetailsResult = {
    contact: Contact,
    detail: ContactDetail
}

export const matchNumberToContactDetail = (contacts: Contact[], query: string | number): ContactDetail | null => {
    
    let matchedDetail = null;

    // Let's Go Through Each Contact
    loop: for ( let i0 = 0; i0 < contacts.length; i0++ ) {

        // Get The Current Contact In The Loop
        let contact = contacts[i0];
        
        // Loop Through Each Detail
        for ( let i1 = 0; i1 < contact.contactdetails.length; i1++ ) {
        
            // Get The Current Contact Detail
            let detail = contact.contactdetails[i1];

            // Let's Only Check For Phone Numbers
            if (detail.type === 'phone') {

                // If We Have A Matching Detail, Let's Get It
                if ( detail.value.includes(query.toString()) ) {
                    matchedDetail = detail;
                    break loop;                 
                }

            }

        }

    }

    // Let's Return Our Matched Detail
    return matchedDetail;

}

export const filterContactDetails = (contacts: Contact[], query: string | number): FilterContactsByDetailsResult[] => {

    let filteredDetails: FilterContactsByDetailsResult[] = [];

    // Let's Go Through Each Contact
    contacts.forEach((contact)=>{

        let matchingDetails: FilterContactsByDetailsResult[] = [];
        let matchedByName = false;

        // Let's Check If We Are Filtering By Name
        if ( contact.first_name?.toLowerCase().includes(query.toString().toLocaleLowerCase()) || contact.last_name?.toLowerCase().includes(query.toString().toLocaleLowerCase()) ) {
            // If Any Of The Names Matches, Let's Note That Down So We Include All The Details
            matchedByName = true;
        }

        // Now Let's Go Though Each ContactDetail
        contact.contactdetails.forEach((detail)=>{

            // Let's Only Check For Phone Numbers
            if (detail.type === 'phone') {

                // If We Matched By Name OR If We Have A Matching Detail, Let's Add It
                if (matchedByName || detail.value.includes(query.toString())) {
                    matchingDetails.push({
                        contact,
                        detail
                    });
                }

            }

        });

        // If We Have Any Matching Details, Let's Add Them To The Filtered Results
        if (matchingDetails.length) {
            filteredDetails = filteredDetails.concat(matchingDetails);
        }

    });

    // Let's Return Our Filtered Details
    return filteredDetails;

}

export const getContactPrimaryNumber = (contact: Contact | null): ContactDetail | null => {
    if (!contact) return null;
    let primaryDetailArray = contact.contactdetails.filter((detail) => {
        return detail.type === 'phone';
    })
    if (!primaryDetailArray.length) return null;
    return primaryDetailArray[0];
};

export const getMatchingDetails = (contact: Contact | null, query: string): ContactDetail | null => {
    if (!contact) return null;
    let matchingDetails = contact.contactdetails.filter((detail) => {
        return detail.type === 'phone' && detail.value.includes(query.toString());
    });
    if(!matchingDetails.length) return null;
    return matchingDetails[0];
}

export const getContactByNumber = (contacts: Contact[], query: string) => {
    let results = contacts.filter((contact) => {
        let matchingDetails = contact.contactdetails.filter((detail) => {
            return detail.type === 'phone' && detail.value.includes(query.toString());
        });
        return !!matchingDetails.length;
    });
    if (!results.length) return null;
    let matchedContact = results[0];
    let isVerified = isVerifiedContact(matchedContact);
    if (!isVerified) return null;
    return matchedContact;
}

export const getContactLabelByNumber = (contacts: Contact[], query: string): string | null => {
    let results = contacts.filter((contact) => {
        let matchingDetails = contact.contactdetails.filter((detail) => {
            return detail.type === 'phone' && detail.value.includes(query.toString());
        });
        return !!matchingDetails.length;
    });
    if (!results.length) return null;
    let matchedContact = results[0];
    let isVerified = isVerifiedContact(matchedContact);
    if (!isVerified) return null;
    let label = `${matchedContact.first_name} ${matchedContact.last_name}`;
    let matchedContactDetail = getMatchingDetails(matchedContact, query);
    if (matchedContactDetail) {
        label = `${label} (${capitalizeWord(matchedContactDetail.label)})`
    }
    return label;
}