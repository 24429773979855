import { gql, Query } from 'overmind-graphql';
import { GetUser } from 'state/effects/gql/user/_types/GetUser';

export const getUser: Query<GetUser> = gql`
  query GetUser {
    user: GetMyProfile {
      id
      email
      first_name
      last_name
      time_zone
      company_name
      isRegistered
      user_data
    }
}
` as any;