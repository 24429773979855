import React from 'react';
import styled from 'styled-components';
import { Document, Page } from 'react-pdf';
import printJS from 'print-js'
import { Button, Callout, Colors } from '@blueprintjs/core';

import Icon from 'components/ui/Icon';
import LoadingComponent from 'components/ui/LoadingComponent';

const FaxPreviewWrapper = styled.div`
    height: 100vh;
    position: relative;
    z-index: 25;
`;

const PreviewHeader = styled.div`
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 30px;
`;

const PreviewControls = styled.div`
    display: flex;
    align-items: center;
`;

const PreviewBody = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    z-index: 0;
`;

const PreviewContent = styled.div``;

const PrintIconWrapper = styled.span`
    margin-right: 20px;
`;

const PrintIcon = styled(Icon)`
    cursor: pointer;
    font-size: 21px;
    &:hover {
        color: ${Colors.WHITE};
    }
`;

const SaveIconWrapper = styled.span`
    margin-right: 20px;
`;

const SaveIcon = styled(Icon)`
    cursor: pointer;
    font-size: 23px;
    &:hover {
        color: ${Colors.WHITE};
    }
`;

const CloseIconWrapper = styled.span``;

const CloseIcon = styled(Icon)`
    cursor: pointer;
    font-size: 30px;
    &:hover {
        color: ${Colors.WHITE};
    }
`;

const PageControlsWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const PageControls = styled.div`
    background: ${Colors.WHITE};
    padding: 9px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin-top: 5px;
`;

const PagesSummary = styled.div`
    margin: 0 12px;    
`;

const LoadError = styled(Callout)`
    color: ${Colors.WHITE};
`;

type FaxPreviewProps = {
    fileUrl: string;
    onClose(): void;
}

const FaxPreview: React.FC<FaxPreviewProps> = ({fileUrl,onClose}) => {
    
    const [numPages, setNumPages] = React.useState(0);
    const [pageNumber, setPageNumber] = React.useState(1);
  
    const changePage = (offset: number) => {
        setPageNumber((pageNumber || 1)+ offset);
    }

    const previousPage = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        changePage(-1);
    }

    const nextPage = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        changePage(1);
    }

    const onDocumentLoadSuccess = (pdf: any) => {
      setNumPages(pdf.numPages);
    }

    const onPrint = () => {
        printJS(fileUrl);
    }

    const onDownload = () => {
        var element = document.createElement('a');
        element.setAttribute('href', fileUrl);
        element.setAttribute('target', '_blank');        
        element.style.display = 'none';
        document.body.appendChild(element);        
        element.click();        
        document.body.removeChild(element);
    }

    const renderPageControls = () => {
        if(numPages>1) {
            return (
                <PageControlsWrapper>
                    <PageControls className="bp3-elevation-3">
                        <Button small outlined icon="chevron-left" onClick={previousPage} disabled={pageNumber===1}/>
                        <PagesSummary>{pageNumber} of {numPages}</PagesSummary>
                        <Button small outlined icon="chevron-right" onClick={nextPage} disabled={pageNumber>=numPages}/>
                    </PageControls>
                </PageControlsWrapper>
            )
        } else {
            return null;
        }
    }

    return (
        <FaxPreviewWrapper className="fax-preview-wrapper">
            <PreviewHeader>
                <PreviewControls>
                    <PrintIconWrapper onClick={onPrint}>
                        <PrintIcon name="print" color="#b2b5b7" width={10}/>
                    </PrintIconWrapper>
                    <SaveIconWrapper onClick={onDownload}>
                        <SaveIcon name="save" color="#b2b5b7" width={10} />
                    </SaveIconWrapper>
                    <CloseIconWrapper onClick={onClose}>
                        <CloseIcon name="close" color="#b2b5b7" width={10} />
                    </CloseIconWrapper>
                </PreviewControls>
            </PreviewHeader>
            <PreviewBody onClick={onClose}>
                <PreviewContent>
                    <Document
                        file={fileUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        error={<LoadError intent="danger">Could Not Load Fax Attachment</LoadError>}
                        loading={<LoadingComponent/>}
                    >
                        <Page
                            error={<LoadError intent="danger">Could Not Load Fax Attachment</LoadError>}
                            pageNumber={pageNumber} 
                            loading={<LoadingComponent/>}
                        />
                    </Document>
                    {renderPageControls()}
                </PreviewContent>
            </PreviewBody>
        </FaxPreviewWrapper>
    );
}

export default FaxPreview;