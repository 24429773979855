import Favico from 'favico.js';

declare global {
    interface Window { 
        currentFlash: number | null;
        flashTimer: number | null;
        initialTitle: string;
    }
}

var favicon=new Favico({
    animation:'slide'
});

export const setBadgeCount = (number: number): void => {
    favicon.badge(number);
}

export const clearBadgeCount = (): void => {
    favicon.reset();
}

export const showIncomingCallIcon = () => {
    let imageElement = document.getElementById('call-icon');
    if (imageElement){
        favicon.image(imageElement);
    }
}

export type FlashOptions = {
    message: string;
    interval?: number;
    duration?: number;
}

export const flashTitleBar = ({message, interval = 1500, duration = 10000}: FlashOptions) => {

    // If We Already Have A Flash Timer Going
    if (window.flashTimer) {
        // Kill It
        killCurrentTimer();
    }

    // If We Have A Current Flash Going
    if (window.currentFlash) {
        // Kill It
        killCurrentFlash();
    }

    // If We Don't Have A Flash Going
    if (!window.currentFlash) {

        // Create An Interval To Set The Flash
        window.currentFlash = window.setInterval(() => {
            document.title = (window.initialTitle === document.title) ? message : window.initialTitle;
        }, interval);
        
        // If We Have A Specified Duration We Need A Timer
        // If Duration is 0, We Want A Permanent Flash Until Manually Cleared, So No Timer Needed
        if (duration>0) {

            // Create A Timer To Stop The Flash After A Specified Duration
            window.flashTimer = window.setTimeout(()=>{

                // When The Timer Expires
                if(window.currentFlash) {
                    // Kill The Current Flash
                    killCurrentFlash();
                }

                // Clear The Timer Variable
                window.flashTimer = null;

            },duration);

        }

    }

}

// Function To Kill Current Flash
const killCurrentFlash = () => {
    // If We Have A Current Flash
    if(window.currentFlash) {
        // Clear The Flash Interval
        clearInterval(window.currentFlash);
        // Reset The Page Title To The Initial
        document.title = window.initialTitle;
        // Clear The Variable
        window.currentFlash = null;
    }
}

// Function To Kill Current Timer
const killCurrentTimer = () => {
    // Clear The Current Timer
    if (window.flashTimer) {
        clearTimeout(window.flashTimer);
    }
    // Clear The Variable
    window.flashTimer = null;
}

export const resetTitleBar = () => {

    // Let's Kill Any Flashers & Timers
    killCurrentTimer();
    killCurrentFlash();
    
    // Reset The Page Title To The Initial
    document.title = window.initialTitle;

}