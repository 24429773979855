import React from 'react';
import { Button, Classes, Drawer } from '@blueprintjs/core';
import styled from 'styled-components'

import app from '../../version.json';
import { useOvermind } from 'state';

const UpdateImage = styled.img`
    max-width: 100%;
    border: 1px solid lightgray;
    border-radius: 2px;
`;

const WhatsNew: React.FC = () => {

    const { actions } = useOvermind();

    const [isOpen, toggleOpen] = React.useState(true);

    const onClose = () => {
        toggleOpen(false);
        actions.settings.saveSawUpdatesSetting(true);
    }

    return (
        <Drawer
            title={`What's New in v${app.version}`}
            isOpen={isOpen}
            onClose={onClose}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            hasBackdrop={false}
            size="350px"
        >
            <div className={Classes.DRAWER_BODY}>
                <div className={Classes.DIALOG_BODY}>

                    <UpdateImage src={process.env.PUBLIC_URL + '/updates/MenuItem.1.0.5.png'} alt="Notification Settings"/>
                    <UpdateImage src={process.env.PUBLIC_URL + '/updates/SettingsScreen.0.1.5.png'} alt="Notification Settings"/>

                    <br/>

                    <h2>New Features</h2>
                    <ul>
                        <li>Adds ability to hide call notifications.</li>
                        <li>Adds ability to hide text notifications.</li>
                        <li>Adds ability to disable notifications when logged out.</li>
                        <li>Adds ability to mute incoming call rington.</li>
                        <li>Adds ability to mute incoming text sound.</li>
                        <li>Adds Unread Message counter to conversation list.</li>
                    </ul>

                    <h2>Bug Fixes</h2>
                    <ul>
                        <li>Fixes issue that would cause new message notifications to change currently selected conversation</li>
                    </ul>

                </div>
            </div>
            
            <div className={Classes.DRAWER_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button large text="Hide This" intent="success" onClick={onClose} />
                </div>  
            </div> 

        </Drawer>
    );
}

export default WhatsNew;