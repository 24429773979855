import React from 'react';

type AudioComponentProps = {
    id: string;
    src?: string;
    loop?: boolean;
}

type ControlledAudioComponentProps = {
    src: string;
    loop?: boolean;
}

export const ControlledAudioComponent = React.forwardRef<HTMLAudioElement,AudioComponentProps>(({src,loop}, ref) => {
    return (
        <audio ref={ref} loop={!!loop}>
            <source src={`${process.env.PUBLIC_URL}/${src}`} type="audio/mpeg" />
        </audio>
    )
})

export const AudioComponent: React.FC<AudioComponentProps> = ({id,src,loop}) => {
    if(!src) {
        return (
            <audio id={id} />
        )
    }
    return (
        <audio id={id} loop={!!loop}>
            <source src={`${process.env.PUBLIC_URL}/${src}`} type="audio/mpeg" />
        </audio>
    )
}

const PhoneSounds = () => {
    return (
        <React.Fragment>
            <AudioComponent id="call-audio" />
            <AudioComponent id="dtmf-audio" src="dtmf.mp3" />
            <AudioComponent id="connect-audio" src="connect.mp3" />
            <AudioComponent id="disconnect-audio" src="disconnect.mp3" />
            <AudioComponent id="outbound-audio" src="outgoing.mp3" loop={true} />
            <AudioComponent id="notification-audio" src="message.mp3" />
        </React.Fragment>
    )
}

export default PhoneSounds;