import React from 'react';
import styled from 'styled-components';

const DialPadComponent = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    max-width: 251px;
    margin: 0 auto;
`;

const DigitWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DigitComponent = styled.div`
    border-radius: 50%;
    height: 70px;
    width: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #f3f3f3;
    cursor: pointer;
    &:hover {
        background: #f3f9fd;
        border: 1px solid #94bbd4;
    }
`;

const Digit = styled.div`
    font-weight: 900;
    font-size: 20px;
    text-align: center;
`;

const Opts = styled.div`
    font-size: 10px;
    line-height: 10px;
    height: 10px;
    text-align: center;
`;

type DigitProps = {
    display: number | string;
    digit: number | string;
    opts?: string;
    handlePress(digit: number | string): void;
}

const DigitItem: React.FC<DigitProps> = ({display, digit, opts, handlePress}) => {
    return (
        <DigitWrapper>
            <DigitComponent onClick={()=>handlePress(digit)}>
                <Digit>{display}</Digit>
                <Opts>{opts}</Opts>
            </DigitComponent>
        </DigitWrapper>
    )
}

type DialPadProps = {
    onDigit(digit: number | string): void;
}

const DialPad: React.FC<DialPadProps> = ({onDigit}) => {
    return (
        <DialPadComponent>
            <DigitItem display={1} digit={1} handlePress={onDigit} />
            <DigitItem display={2} digit={2} opts="ABC" handlePress={onDigit} />
            <DigitItem display={3} digit={3} opts="DEF" handlePress={onDigit} />
            <DigitItem display={4} digit={4} opts="GHI" handlePress={onDigit} />
            <DigitItem display={5} digit={5} opts="JKL" handlePress={onDigit} />
            <DigitItem display={6} digit={6} opts="MNO" handlePress={onDigit} />
            <DigitItem display={7} digit={7} opts="PQRS" handlePress={onDigit} />
            <DigitItem display={8} digit={8} opts="TUV" handlePress={onDigit} />
            <DigitItem display={9} digit={9} opts="WXYZ" handlePress={onDigit} />
            <DigitItem display="*" digit={'A'} handlePress={onDigit} />
            <DigitItem display={0} digit={0} handlePress={onDigit} />
            <DigitItem display="#" digit={'B'} handlePress={onDigit} />
        </DialPadComponent>
    );
}

export default DialPad;