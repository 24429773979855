import React from 'react';
import styled from 'styled-components';
import { Colors, Button, Tooltip, Tag, Menu, Popover } from '@blueprintjs/core';
import classnames from 'classnames';

import { useOvermind } from 'state';
import { getContactLabel, getMatchingDetails } from 'state/contacts/utils';
import { MessageEntry, CallLogEntry } from 'state/entries/state';

import { formatPhoneNumber, lookUpAreaCode } from 'utils/numbers';
import { capitalizeWord } from 'utils/strings';
import { formatTimeDuration, formatTimeStamp, formatTimeStampRelative } from 'utils/dates';

import { RenderPreviewIcon } from 'components/entries/EntryPreview';
import Icon from 'components/ui/Icon';
import EntryTypeFax from 'components/entries/EntryTypeFax';
import ContactView from 'components/contacts/ContactView';

const EntryItemComponentHeader = styled.div`
    padding: 10px;
    grid-gap: 5px;
    display: grid;
    grid-template-columns: 20px 90px repeat(7, 1fr) 20px;
    & .center-v {
        align-self: center;
    }
    & .center-h {
        justify-self: center;
    }
`;

const EntryItemComponentRow = styled.tr`
    background-color: ${Colors.WHITE};
    border-right: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    border-left: 0px solid #5c707f;
    position: relative;
    &.first-row  {
        border-top: 1px solid #efefef;
    }
    &.clickable {
        cursor: pointer;
    }
    &.clickable:hover {
        background-color: ${Colors.LIGHT_GRAY3};
    }
    & td.marker {
        width: 13px;
        padding: 0;
        background-color: #5c707f;
    }
    &.activeNumber td.marker {
        background-color: #137cbd;
    }
    &.activeCall {
        color: ${Colors.GREEN3};
        border-color: ${Colors.GREEN3};
    }
    &.activeCall td.marker {
        background-color: ${Colors.GREEN3};
        color: ${Colors.GREEN3};
    }
`;

const EntryItemComponent = styled.div`
    background-color: ${Colors.WHITE};
    border-radius: 0px;
    margin-bottom: 0px;
    border-right: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    padding: 10px;
    padding-left: 15px;
    grid-gap: 5px;
    display: grid;
    grid-template-columns: 20px 90px repeat(7, 1fr) 20px;
    position: relative;
    &.first-row {
        border-top: 1px solid #efefef;
    }
    & .center-v {
        align-self: center;
    }
    & .center-h {
        justify-self: center;
    }
    &.clickable {
        cursor: pointer;
    }
    &.clickable:hover {
        background-color: ${Colors.LIGHT_GRAY1};
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-left: 10px solid #5c707f;
    }
    &.activeNumber:before {
        border-left: 10px solid #137cbd;
    }
    &.activeCall {
        border-color: ${Colors.GREEN3};
        color: ${Colors.GREEN3};
    }
    &.activeCall:before {
        border-color: ${Colors.GREEN3};
    }
`;

type EntryItemProps = {
    entry: CallLogEntry;
    onClick?(): void;
    className?: string;
}

export const EntryItemAdvancedHeader: React.FC = () => {
    return (
        <EntryItemComponentHeader>
            <div className="center-v center-h" style={{paddingLeft: "5px"}}></div>
            <div className="center-v">Direction</div>            
            <div className="center-v">Number</div>
            <div className="center-v">Contact Number</div>
            <div className="center-v">Contact Details</div>
            <div className="center-v">Date</div>
            <div className="center-v">Start Time</div>
            <div className="center-v">End Time</div>
            <div className="center-v">Duration</div>
            <div></div>
            <div className="center-v center-h" style={{paddingRight: "5px"}}></div>
        </EntryItemComponentHeader> 
    )
}

const EntryItemAdvanced: React.FC<EntryItemProps> = ({entry,onClick}) => {

    const { state, actions } = useOvermind();

    const hasOnClickProp = onClick&&typeof onClick === 'function';

    const contact = entry.conversation && entry.conversation.contact_id ? state.contacts.items[entry.conversation.contact_id] : null;

    const fromNumber = entry.conversation ? formatPhoneNumber(entry.conversation.contact_number) : 'Unknown Number';

    const sourceLocation = entry.conversation && entry.conversation.contact_number ? lookUpAreaCode(entry.conversation.contact_number ) : 'Unknown Location';

    let contactLabel = contact ? getContactLabel(contact) : entry.conversation ? fromNumber : 'Unknown Contact';

    let contactDetail = null;
    if ( entry.conversation && contact && contact.contactdetails.length > 1 ) {
        contactDetail = getMatchingDetails(contact, entry.conversation.contact_number); 
    }

    const navigateToConversation = () => {
        if (entry.conversation) {
            actions.app.updateNavigationState({
                route: 'conversations',
                conversation_id: entry.conversation.id
            })
        }
    }

    const initiateCall = () => {
        if (entry.conversation) {
            actions.app.updateNavigationState({
                route: 'conversations',
                conversation_id: entry.conversation.id,
                callback: () => { 
                    if(entry.conversation){
                        actions.conversations.createConversationEntry({
                            type: 'call',
                            destinationNumber: entry.conversation.contact_number
                        });
                    }
                }
            }) 
        }
    }

    const handleClick = () => {
        if(onClick&&hasOnClickProp) {
            onClick()
        }
    }

    const genericEntryActions = () => {
        return (
            <React.Fragment>
                <Tooltip boundary="window" content="Go To Conversation">
                    <Button small outlined icon={<Icon name="link" width={18}/>} onClick={navigateToConversation}/>
                </Tooltip>
                <Tooltip boundary="window" content="Call">
                    <Button small outlined icon={<Icon name="phone" width={18}/>} onClick={initiateCall}/>
                </Tooltip>
                <Tooltip boundary="window" content="Send Message">
                    <Button small outlined icon={<Icon name="envelope" width={18}/>} onClick={navigateToConversation}/>
                </Tooltip>
                {/* <Tooltip boundary="window" content={`Delete ${capitalizeWord(entry.type)}`}>
                    <Button disabled={true} small outlined icon={<Icon name="trash" color={Colors.RED1}/>} onClick={navigateToConversation}/>
                </Tooltip> */}
            </React.Fragment>
        )
    }

    const renderEntryActions = () => {
        switch(entry.type) {
            case 'false':
                return null;
            case 'false1':
                return genericEntryActions();
            case 'false2':
                return (
                    <React.Fragment>
                        <EntryTypeFax entry={entry as MessageEntry} mode="small"/>
                        {genericEntryActions()}
                    </React.Fragment>
                );
            default:
                return null;
        }
    }

    let editContactHandler: Function | null = null;
    const setEditContactHandler = (handler: ()=>void) => {
        editContactHandler = handler;
    }

    const handleEditContact = () => {
        if (editContactHandler) {
            console.log(contact);
            if (contact&&contact.id) {
                actions.contacts.selectContact(contact.id)
            } else {
                actions.contacts.resetContact()
            }
            editContactHandler();
        }
    }

    const handleLaunchCall = async () => {
        if(entry.conversation) {
            await actions.conversations.createConversationEntry({
                type: 'call',
                destinationNumber: entry.conversation.contact_number
            });
        }
    }     

    const renderEntryMenu = () => {
        return(
            <>
                <Popover
                    position="bottom"
                    content={
                        <Menu>
                            <Menu.Item icon={<Icon name="phone" width={20}/>}  text={`Call ${contact? contactInfo : fromNumber}`} onClick={handleLaunchCall}/>
                            <Menu.Divider/>
                            {contact?(
                                <Menu.Item icon={<Icon name="editContact" width={20}/>} text="Edit Contact" onClick={handleEditContact}/>
                            ) : (
                                <Menu.Item icon={<Icon name="addContact" width={20}/>}  text="Save Contact" onClick={handleEditContact}/>
                            )}
                        </Menu>
                    }
                >
                    <Button icon="more" minimal/>
                </Popover>

                <ContactView setEditContactHandler={setEditContactHandler} contactNumber={entry.conversation?.contact_number}/>
            </>
        )
    }

    let contactInfo = contact ? `${contactLabel}${contactDetail?` (${capitalizeWord(contactDetail.label)})`:``}` : `${sourceLocation}`;

    let callData = null;
    try {
        callData = entry.body && entry.body.length ? JSON.parse(entry.body) : null;
    } catch {}

    let startTimeStamp = entry.time_stamp;
    let endTimeStamp = startTimeStamp + ( callData && typeof callData.call_duration ? callData.call_duration : 0 )
    let activeNumber = state.numbers.currentItem?.number_id === entry.number?.id;

    let isActiveCall = entry.id === state.phone.outboundCallEntryId || entry.id === state.phone.inboundCallEntryId;

    let callDuration = isActiveCall ? state.phone.callTimer : callData && typeof callData.call_duration !== 'undefined' ? callData.call_duration : 'n/a';   

    return (
        <EntryItemComponent onClick={handleClick} className={classnames(entry.type,{ clickable: hasOnClickProp, activeNumber: activeNumber, activeCall: isActiveCall})}>
            <div className="center-v center-h" style={{paddingLeft: "5px"}}>
                <RenderPreviewIcon direction={entry.direction} type={entry.type} status={entry.status} />
            </div>
            <div className="center-v"><small style={{color: entry.direction==='in'?Colors.GRAY1:Colors.GRAY4}} className="text-muted">{entry.direction==='in'?'Inbound':'Outbound'}</small></div>
            <div className="center-v"><Tag intent={activeNumber?'primary':'none'}>{entry.number?.label}</Tag></div>
            <div className="center-v"><strong>{fromNumber}</strong></div>
            <div className="center-v">{contactInfo}</div>
            <div className="center-v">{formatTimeStampRelative(entry.time_stamp,false,true)}</div>
            <div className="center-v">{formatTimeStamp(startTimeStamp,'medium')}</div>
            <div className="center-v">{formatTimeStamp(endTimeStamp,'medium')}</div>
            <div className="center-v">{formatTimeDuration(callDuration)}</div>
            <div className="center-v center-h" style={{paddingRight: "5px"}}>
                {isActiveCall?(<Icon style={{marginBottom:'-3px'}} name="phoneOn" width={18} color={Colors.GREEN3}/>):null}
            </div>
            <div>
                {renderEntryActions()}
                {renderEntryMenu()}
            </div>
        </EntryItemComponent> 
    )
}

export const EntryItemAdvancedTable: React.FC<EntryItemProps> = ({entry,onClick,className}) => {

    const { state, actions } = useOvermind();

    const hasOnClickProp = onClick&&typeof onClick === 'function';

    const contact = entry.conversation && entry.conversation.contact_id ? state.contacts.items[entry.conversation.contact_id] : null;

    const fromNumber = entry.conversation ? formatPhoneNumber(entry.conversation.contact_number) : 'Unknown Number';

    const sourceLocation = entry.conversation && entry.conversation.contact_number ? lookUpAreaCode(entry.conversation.contact_number ) : 'Unknown Location';

    let contactLabel = contact ? getContactLabel(contact) : entry.conversation ? fromNumber : 'Unknown Contact';

    let contactDetail = null;
    if ( entry.conversation && contact && contact.contactdetails.length > 1 ) {
        contactDetail = getMatchingDetails(contact, entry.conversation.contact_number); 
    }

    const navigateToConversation = () => {
        if (entry.conversation) {
            actions.app.updateNavigationState({
                route: 'conversations',
                conversation_id: entry.conversation.id
            })
        }
    }

    const initiateCall = () => {
        if (entry.conversation) {
            actions.app.updateNavigationState({
                route: 'conversations',
                conversation_id: entry.conversation.id,
                callback: () => { 
                    if(entry.conversation){
                        actions.conversations.createConversationEntry({
                            type: 'call',
                            destinationNumber: entry.conversation.contact_number
                        });
                    }
                }
            }) 
        }
    }

    const handleClick = () => {
        if(onClick&&hasOnClickProp) {
            onClick()
        }
    }

    const genericEntryActions = () => {
        return (
            <React.Fragment>
                <Tooltip boundary="window" content="Go To Conversation">
                    <Button small outlined icon={<Icon name="link" width={18}/>} onClick={navigateToConversation}/>
                </Tooltip>
                <Tooltip boundary="window" content="Call">
                    <Button small outlined icon={<Icon name="phone" width={18}/>} onClick={initiateCall}/>
                </Tooltip>
                <Tooltip boundary="window" content="Send Message">
                    <Button small outlined icon={<Icon name="envelope" width={18}/>} onClick={navigateToConversation}/>
                </Tooltip>
                {/* <Tooltip boundary="window" content={`Delete ${capitalizeWord(entry.type)}`}>
                    <Button disabled={true} small outlined icon={<Icon name="trash" color={Colors.RED1}/>} onClick={navigateToConversation}/>
                </Tooltip> */}
            </React.Fragment>
        )
    }

    const renderEntryActions = () => {
        switch(entry.type) {
            case 'false':
                return null;
            case 'false1':
                return genericEntryActions();
            case 'false2':
                return (
                    <React.Fragment>
                        <EntryTypeFax entry={entry as MessageEntry} mode="small"/>
                        {genericEntryActions()}
                    </React.Fragment>
                );
            default:
                return null;
        }
    }

    let editContactHandler: Function | null = null;
    const setEditContactHandler = (handler: ()=>void) => {
        editContactHandler = handler;
    }

    const handleEditContact = () => {
        if (editContactHandler) {
            console.log(contact);
            if (contact&&contact.id) {
                actions.contacts.selectContact(contact.id)
            } else {
                actions.contacts.resetContact()
            }
            editContactHandler();
        }
    }

    const handleLaunchCall = async () => {
        if(entry.conversation) {
            await actions.conversations.createConversationEntry({
                type: 'call',
                destinationNumber: entry.conversation.contact_number
            });
        }
    }     

    const renderEntryMenu = () => {
        return(
            <>
                <Popover
                    position="bottom"
                    content={
                        <Menu>
                            <Menu.Item icon={<Icon name="phone" width={20}/>}  text={`Call ${contact? contactInfo : fromNumber}`} onClick={handleLaunchCall}/>
                            <Menu.Divider/>
                            {contact?(
                                <Menu.Item icon={<Icon name="editContact" width={20}/>} text="Edit Contact" onClick={handleEditContact}/>
                            ) : (
                                <Menu.Item icon={<Icon name="addContact" width={20}/>}  text="Save Contact" onClick={handleEditContact}/>
                            )}
                        </Menu>
                    }
                >
                    <Button icon="more" minimal/>
                </Popover>

                <ContactView setEditContactHandler={setEditContactHandler} contactNumber={entry.conversation?.contact_number}/>
            </>
        )
    }

    let contactInfo = contact ? `${contactLabel}${contactDetail?` (${capitalizeWord(contactDetail.label)})`:``}` : `${sourceLocation}`;

    let callData = null;
    try {
        callData = entry.body && entry.body.length ? JSON.parse(entry.body) : null;
    } catch {}

    let startTimeStamp = entry.time_stamp;
    let endTimeStamp = startTimeStamp + ( callData && typeof callData.call_duration ? callData.call_duration : 0 )
    let activeNumber = state.numbers.currentItem?.number_id === entry.number?.id;

    let isActiveCall = entry.id === state.phone.outboundCallEntryId || entry.id === state.phone.inboundCallEntryId;

    let callDuration = isActiveCall ? state.phone.callTimer : callData && typeof callData.call_duration !== 'undefined' ? callData.call_duration : 'n/a';   

    return (
        <EntryItemComponentRow onClick={handleClick} className={classnames(entry.type,className,{ clickable: hasOnClickProp, activeNumber: activeNumber, activeCall: isActiveCall})}>
            <td className="marker"></td>
            <td width={40}>
                <RenderPreviewIcon direction={entry.direction} type={entry.type} status={entry.status} />
            </td>
            <td><small style={{color: entry.direction==='in'?Colors.GRAY1:Colors.GRAY4}} className="text-muted">{entry.direction==='in'?'Inbound':'Outbound'}</small></td>
            <td><Tag intent={activeNumber?'primary':'none'}>{entry.number?.label}</Tag></td>
            <td><strong>{fromNumber}</strong></td>
            <td>{contactInfo}</td>
            <td>{formatTimeStampRelative(entry.time_stamp,false,true)}</td>
            <td>{formatTimeStamp(startTimeStamp,'medium')}</td>
            <td>{isActiveCall?'-':formatTimeStamp(endTimeStamp,'medium')}</td>
            <td className="right">{formatTimeDuration(callDuration)}</td>
            <td>
                {isActiveCall?(<Icon style={{marginBottom:'-3px'}} name="phoneOn" width={18} color={Colors.GREEN3}/>):null}
            </td>
            <td width={40} className="actions">
                {renderEntryActions()}
                {renderEntryMenu()}
            </td>
        </EntryItemComponentRow> 
    )
}

export default EntryItemAdvanced;