import React from "react";
import { Button, Colors, Menu, Popover } from "@blueprintjs/core";
import styled from "styled-components";

import app from '../../version.json'

import { useOvermind } from "state";
import { Extension } from "state/extensions/state";

import ExtensionSelectDialog from 'components/extensions/ExtensionSelectDialog';
import Icon from "./Icon";
import SettingsPanel from "components/user/SettingsPanel";

const MenuContainer = styled.div`
    display: flex;
    align-items: center;
`;

const MenuButton = styled(Button)`
    & .bp3-button-text {
        color: ${Colors.WHITE};
    }
    & .bp3-icon > svg:not([fill]) {
        color: ${Colors.WHITE};
    }
    &.bold {
        font-weight: bold;
    }
    &.bp3-button.bp3-outlined {
        border-color: rgba(255, 255, 255, 0.40);
    }
    &.pad-right {
        margin-right: 6px;
    }
`;

const CurrentExtension = () => {

    const { state: { user: { info } }, reaction } = useOvermind();
    const [currentExtension,setCurrentExtension] = React.useState<Extension|null>(null);

    React.useEffect(() => {
        return reaction(
            (state) => state.extensions.currentItem,
            (currentExtension) => {
                if (currentExtension) {
                    setCurrentExtension(currentExtension);
                }
            },
            {immediate:true}
        )
    }, [reaction]);

    let currentExtensionDisplay = null;

    if (currentExtension) {
        currentExtensionDisplay = `Extension #${currentExtension.extension?.extnum}`;
    }

    return <MenuButton rightIcon="chevron-down" minimal text={`${info?.first_name } ${info?.last_name } - ${currentExtensionDisplay}`} />

}

const ProfileMenu = () => {

    const { actions: { user: { logOut } } } = useOvermind();

    const [ extensionSelectOpen, toggleExtensionSelect ] = React.useState(false);
    const [ isSettingsPanelOpen, toggleSettingsPanel ] = React.useState(false);

    const handleSignOut = () => {
        logOut();
    }
    
    return (
        <MenuContainer>
            <Popover
                position="bottom-right"
                content={
                    <Menu>
                        <Menu.Item icon={<Icon name="settings" width={20} />} onClick={()=>toggleSettingsPanel(true)} text="User Settings" />
                        <Menu.Item icon={<Icon name="extension" width={20} />} onClick={()=>toggleExtensionSelect(true)} text="Select Extension" />
                        <Menu.Divider />
                        <Menu.Item icon={<Icon name="signOut" width={20} />} onClick={handleSignOut} text="Sign Out" />
                        <Menu.Divider />
                        <Menu.Item icon={<Icon name="version" width={20} />}text={`FastPBX Link v${app.version}`} disabled={true} />
                    </Menu>
                }
                modifiers={{arrow:{enabled:false}}}
            >
                <CurrentExtension/>
            </Popover>
            <ExtensionSelectDialog isOpen={extensionSelectOpen} onClose={()=>toggleExtensionSelect(false)}/>
            <SettingsPanel isOpen={isSettingsPanelOpen} onClose={()=>toggleSettingsPanel(false)}/>
        </MenuContainer>
    )
}

export default ProfileMenu;