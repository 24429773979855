import { SubscriptionSettings, WebNotificationAgent } from "./agents";
import * as Sentry from '@sentry/browser';
export {
    setBadgeCount,
    clearBadgeCount,
    flashTitleBar,
    resetTitleBar,
    showIncomingCallIcon
} from './tabs';

type InitOptions = {
    platform: 'web' | 'desktop';
    onInboundText(data: any): void;
    onInboundCall(data: any): void;
    onInboundCallAnswered(data: any): void;
    syncNotificationStatus(status: 'default' | 'granted'| 'denied'): void;
    syncSubscriptionStatus(status: boolean): void;
}

export const agent = (()=>{

    let notficationAgent: WebNotificationAgent | null = null;

    return {
        initialize: async (options: InitOptions) => {

            console.log('Initializing Notification System');

            let agentOptions = {
                statusSyncHandler: options.syncNotificationStatus,
                subscriptionSyncHandler: options.syncSubscriptionStatus,
                textNotificationHandler: options.onInboundText,
                callNotificationHandler: options.onInboundCall,
                callAnsweredNotificationHandler: options.onInboundCallAnswered
            }

            switch (options.platform) {
                case 'web':
                    notficationAgent = new WebNotificationAgent(agentOptions);
                    break;
                case 'desktop':
                    break;
            }
            
            if (notficationAgent) {
                return notficationAgent.initialize();
            } else {
                console.error('No Notification Agent Initialized');
                Sentry.captureException('No Notification Agent Initialized');
            }

        },
        showNativePrompt: () => {
            console.log('Showing Native Prompt');
            if (notficationAgent) {
                notficationAgent.showPrompt();
            }
        },
        registerNotificationListener: () => {
            if (notficationAgent) {
                console.log('Registering Listeners');
                notficationAgent.registerNotificationListener()
            }
        },
        updateSubscription: (settings: SubscriptionSettings) => {
            if (notficationAgent) {
                notficationAgent.updateSubscription(settings);
            }
        },
        getPlayerID: async (): Promise<string|null> => {
            // console.log('Getting PlayerID');
            // if (notficationAgent) {
            //     return await notficationAgent.getPlayerId();
            // } else {
                
            // }
            return Promise.resolve(null);
        }
    }

})()