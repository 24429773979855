export type State = {
    offlineNotifications: boolean;
    receiveCalls: boolean;
    receiveTexts: boolean;
    playCallSounds: boolean;
    playTextSounds: boolean;
    dialingPrefix: string;
    sawUpdates: boolean;
}

export const state: State = {
    offlineNotifications: true,
    receiveCalls: true,
    receiveTexts: true,
    playCallSounds: true,
    playTextSounds: true,
    dialingPrefix: '9',
    sawUpdates: false
}