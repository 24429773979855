import { AsyncAction, Action, pipe, mutate, map } from 'overmind';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/browser';

import { Contact, ContactDetail } from './state';
import { mapArray } from 'state/utils';
import { getContactByNumber, getContactLabelByNumber } from './utils';
import { Conversation } from 'state/conversations/state';
import { NotificationToaster } from 'components/notifications/NotificationToaster';

export const getContacts: AsyncAction = async ({state,effects}) => {
    let { contacts } = await effects.gql.queries.getContacts();
    if (contacts) {
        let data = mapArray(contacts,'id');
        state.contacts.items = data;
    }
}

export const selectContact: Action<Contact['id']> = ({state}, contactId) => {
    state.contacts.currentItemId = contactId;
}

export const resetContact: Action = ({state}) => {
    state.contacts.currentItemId = null;
}

type CreateContactInfo = {
    id?: string;
    first_name?: string;
    last_name?: string;
    contactdetails?: {
        type: string;
        label: string;
        value: string;
    }[]
}

type UpdateContactInfo = {
    id: string;
    first_name?: string;
    last_name?: string;
    contactdetails: {
        id: string;
        type?: string;
        label?: string;
        value?: string;
    }[]
}

type SaveContactInput = {
    conversationId: Conversation['id'] | null,
    contact: CreateContactInfo
}

export const saveContact: AsyncAction<SaveContactInput> = pipe(
    mutate(async ({state,actions}, input) => {
        if (input.contact.id) {
            // Save Contact
            await actions.contacts.updateContact(input.contact as UpdateContactInfo);
        } else {
            // Create Contact
            await actions.contacts.createContact(input.contact);
        }
        if(input.conversationId){
            await actions.conversations.refreshConversation(input.conversationId);
        }
    }),
    // Gets The Contact ID Of The Selected Conversation
    map(({state}) => {
        return state.conversations.currentItem?.contact_id;
    }),
    // Runs The Select Contact Conversation
    mutate(({state, actions}, contactId) => {
        if (contactId&&state.conversations.currentItemId) {
            actions.contacts.selectContact(contactId);
        } else {
            state.contacts.currentItemId = null;
        }
    }),
)

export const createContact: AsyncAction<CreateContactInfo, Contact | null> = async ({state,effects}, input) => {
    try {

        let { contact: createdContact } = await effects.gql.mutations.createContact({input});
        if (createdContact) {
            state.contacts.items[createdContact.id] = createdContact as Contact; 
            NotificationToaster.show({
                intent: 'success',
                message: 'Contact Created'
            });
            return createdContact as Contact;
        }
        return null;

    } catch (e) {

        Sentry.captureException(e);
        NotificationToaster.show({
            icon: 'warning-sign',
            intent: 'danger',
            message: 'Error Creating Contact'
        });
        return null;

    }
}

export const updateContact: AsyncAction<UpdateContactInfo> = async ({state,effects}, input) => {
    try {

        let { contact: updatedContact } = await effects.gql.mutations.updateContact({input}); 
        if (updatedContact) {
            state.contacts.items[updatedContact.id] = updatedContact as Contact;
            NotificationToaster.show({
                intent: 'success',
                message: 'Contact Updated'
            }); 
        }

    } catch (e) {

        Sentry.captureException(e);
        NotificationToaster.show({
            icon: 'warning-sign',
            intent: 'danger',
            message: 'Error Updating Contact'
        });

    }
    
}

export const createEmptyContact: Action<string, Contact | null> = ({state,actions}, contactNumber) => {
    let emptyContact: Contact = {
        id: uuidv4(),
        first_name: null,
        last_name: null,
        contactdetails: [
            {
                id: uuidv4(),
                type: 'phone',
                label: 'primary',
                value: contactNumber
            }
        ]
    }
    state.contacts.items[emptyContact.id] = emptyContact;
    return emptyContact;
}

export const findContactByNumber: Action<string, Contact | null> = ({state,actions,effects}, contactNumber) => {
    let contact = getContactByNumber(state.contacts.itemsList, contactNumber);
    return contact ? contact : null;
}

export const findContactLabelByNumber: Action<string, string | null> = ({state,actions,effects}, contactNumber) => {
    return getContactLabelByNumber(state.contacts.itemsList, contactNumber);
}

export const deleteContact: AsyncAction<Contact['id']> = async ({state,effects}, id) => {
    try {

        let { contact: deletedContact } = await effects.gql.mutations.deleteContact({id});
        if (deletedContact) {
            delete state.contacts.items[deletedContact.id];
            NotificationToaster.show({
                intent: 'success',
                message: 'Contact Deleted'
            });
        }

    } catch (e) {
        Sentry.captureException(e);
        NotificationToaster.show({
            icon: 'warning-sign',
            intent: 'danger',
            message: 'Error Deleting Contact'
        });
    }    
}

export const deleteContactDetail: AsyncAction<ContactDetail['id']> = async ({state,effects}, id) => {
    try {

        let { contactDetail: deletedContactDetail } = await effects.gql.mutations.deleteContactDetail({id});
        if (deletedContactDetail&&deletedContactDetail.contact_id) {
            let filteredContactDetails = state.contacts.items[deletedContactDetail.contact_id].contactdetails.filter((detail)=>{ return detail.id !== deletedContactDetail?.id});
            state.contacts.items[deletedContactDetail.contact_id].contactdetails = filteredContactDetails;
            NotificationToaster.show({
                intent: 'success',
                message: 'Contact Detail Deleted'
            });
        }

    } catch (e) {
        Sentry.captureException(e);
        NotificationToaster.show({
            icon: 'warning-sign',
            intent: 'danger',
            message: 'Error Deleting Contact Detail'
        });
    }
}