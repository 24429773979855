import React from 'react';
import styled from 'styled-components';
import { Colors } from '@blueprintjs/core';
import Icon, { IconName } from 'components/ui/Icon';

import { CallEntry, MessageEntry, FaxEntry, VoicemailEntry } from 'state/entries/state';
import { generateCallSummary, scanForLinks, generateFaxSummary, generateVoicemailSummary } from 'state/entries/utils';

type EntryPreviewProps = {
    entry: CallEntry | MessageEntry | FaxEntry | VoicemailEntry
}

type CallPreviewProps = {
    call: CallEntry
}

type MessagePreviewProps = {
    message: MessageEntry
}

type FaxPreviewProps = {
    fax: FaxEntry
}

type VoicemailPreviewProps = {
    voicemail: VoicemailEntry
}

type DirectionalArrowProps = {
    direction: string | 'in' | 'out',
    color?: string;
}

export const PreviewWrapperComponent = styled.div`
    display: flex;
    align-items: center;
`;
 
export const PreviewIcons = styled.div`
    margin-right: 4px;
    display: flex;
    align-items: center;
`;

const PreviewIconComponent= styled.div`
    margin-right: 2px;
    display: flex;
    align-items: center;
`;

export const PreviewIconCore = ({icon,color}: {icon: IconName, color: string}) => {
    return (
        <PreviewIconComponent>
            <Icon block={true} name={icon} color={color} width={18} />
        </PreviewIconComponent>
    )
}

export const PreviewIcon = (props: any) => {
    return <PreviewIconCore {...props} className="bp3-text-muted" />
}

export const DirectionalArrow: React.FC<DirectionalArrowProps> = ({direction,color='#006698'}) => {
    return <PreviewIcon icon={direction==='in'?'arrowLeft':'arrowRight'} color={color} />
}

type RenderPreviewIconProps = {
    type: string | 'call' | 'text' | 'fax' | 'voicemail';
    status: string;
    direction?: string;
}

export const RenderPreviewIcon: React.FC<RenderPreviewIconProps> = ({type,status,direction = 'in'}) => {
    switch (type) {
        case 'call':
            return <DirectionalArrow direction={direction} color={(status==='missed'||status==='declined')?Colors.RED3:Colors.GREEN3} />
        case 'text':
            return <PreviewIcon icon="envelope" color={status==='read'?Colors.GRAY4:Colors.BLUE3}/>
        case 'fax':
            return <DirectionalArrow direction={direction} color={(status==='missed'||status==='declined')?Colors.RED3:Colors.GREEN3} />
        case 'voicemail':
            return <PreviewIcon icon="voicemail" color={status==='read'?Colors.GRAY4:Colors.BLUE3}/>
        default:
            return null;
    }
}

const CallPreview: React.FC<CallPreviewProps> = ({call}) => {
    return (
        <PreviewWrapperComponent>
            <PreviewIcons>
                <DirectionalArrow direction={call.direction} color={(call.status==='missed'||call.status==='declined')?Colors.RED3:Colors.GREEN3} />
                <PreviewIcon icon="phone" color={(call.status==='missed'||call.status==='declined')?Colors.RED3:Colors.GREEN3} />
            </PreviewIcons>
            <span className="bp3-text-muted">{generateCallSummary(call)}</span>
        </PreviewWrapperComponent>
    )
}

const MessagePreview: React.FC<MessagePreviewProps>  = ({message}) => {
    return (
        <PreviewWrapperComponent>
            <PreviewIcons>
                <DirectionalArrow direction={message.direction} color={message.status==='read'?Colors.GRAY4:Colors.BLUE3} />
                <PreviewIcon icon="envelope" color={message.status==='read'?Colors.GRAY4:Colors.BLUE3}/>
            </PreviewIcons>
            <div className="bp3-text-overflow-ellipsis bp3-text-muted">{scanForLinks(message.body)}</div>
        </PreviewWrapperComponent>
    )
}

const FaxPreview: React.FC<FaxPreviewProps>  = ({fax}) => {
    return (
        <PreviewWrapperComponent>
            <PreviewIcons>
                <DirectionalArrow direction={fax.direction} color={fax.status==='read'?Colors.GRAY4:Colors.BLUE3} />
                <PreviewIcon icon="fax" color={fax.status==='read'?Colors.GRAY4:Colors.BLUE3}/>
            </PreviewIcons>
            <span className="bp3-text-muted">{generateFaxSummary(fax)}</span>
        </PreviewWrapperComponent>
    )
}

const VoicemailPreview: React.FC<VoicemailPreviewProps>  = ({voicemail}) => {
    return (
        <PreviewWrapperComponent>
            <PreviewIcons>
                <DirectionalArrow direction={voicemail.direction} color={voicemail.status==='read'?Colors.GRAY4:Colors.BLUE3} />
                <PreviewIcon icon="voicemail" color={voicemail.status==='read'?Colors.GRAY4:Colors.BLUE3}/>
            </PreviewIcons>
            <span className="bp3-text-muted">{generateVoicemailSummary(voicemail)}</span>
        </PreviewWrapperComponent>
    )
}

const EntryPreviewComponent = styled.div`

`;

const EntryPreview: React.FC<EntryPreviewProps> = ({entry}) => {
    const renderPreview = () => {
        switch (entry.type) {
            case 'call':
                return <CallPreview call={entry as CallEntry}/>
            case 'text':
                return <MessagePreview message={entry as MessageEntry}/>
            case 'fax':
                return <FaxPreview fax={entry as FaxEntry}/>
            case 'voicemail':
                return <VoicemailPreview voicemail={entry as VoicemailEntry}/>
        }
    }
    return (
        <EntryPreviewComponent>
            {renderPreview()}
        </EntryPreviewComponent>
    )
}

export default EntryPreview;