export const capitalizeWord = (value: string): string => {
    if (typeof value !== 'string') return ''
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export const capitalizeWords = (value: string): string => {
    if (typeof value !== 'string') return '';
    let strArr = value.split(' ');
    if (strArr.length===1){
        return value.charAt(0).toUpperCase() + value.slice(1)
    }
    return strArr.map((s)=>{
        return s.charAt(0).toUpperCase() + s.slice(1)
    }).join(' ');
}