import moment from 'moment';

import { State, CallEntry, MessageAttachments, Entry, FaxEntry, VoicemailEntry } from "./state";
import { formatTimeDuration } from "utils/dates";

export const sortByActivity = (items: Entry[], displayOrder: State['displayOrder']) => {
    if ( displayOrder === 'newestOnBottom' ) {
        return items.sort((a,b)=>{
            return a.time_stamp - b.time_stamp;
        });
    } else {
        return items.sort((a,b)=>{
            return b.time_stamp - a.time_stamp;
        });
    }
}

export const generateCallSummary = (call: CallEntry) => {
    let callData = JSON.parse(call.body);
    let summary = '';
    switch (call.status) {
        case 'missed':
        case 'declined':
                summary = 'Missed Call';
                break;
        case 'connected':
        case 'connecting':
            summary = call.direction === 'in' ? 'Called You' : 'You Called';            
            break;
        case 'ended':
            summary = call.direction === 'in' ? 'Called You' : 'You Called'; 
            if (callData.call_duration>0) {             
                let duration = formatTimeDuration(callData.call_duration).toString();   
                summary = `${summary} - Lasted: ${duration}`;
            }
            break;
    }
    return summary;
}

export const generateFaxSummary = (fax: FaxEntry) => {
    let summary = '';
    switch (fax.status) {
        case 'new':
        case 'read':
        case 'unread':
            summary = fax.direction === 'in' ? 'Sent You A Fax' : 'You Sent A Fax';            
            break;
    }
    return summary;
}

export const generateVoicemailSummary = (voicemail: VoicemailEntry) => {
    let summary = '';
    switch (voicemail.status) {
        case 'read':
        case 'unread':
            summary = 'Left You A Voicemail';            
            break;
    }
    return summary;
}

export const filterImageAttachments = (attachments: MessageAttachments[]): string[] => {
    return attachments.filter((attachemnt)=>{
        let extension = attachemnt.filename.split('.').pop();
        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return true;
            default:
                return false;
        }
    }).map((attachment)=>{
        return attachment.url;
    })
}

type DateGroup = {
    label: string;
    entries: Entry[]
}

export const groupEntriesByDate = (entries: Entry[]): DateGroup[] => {

    let entriesGrouped = entries.reduce((dates: { [index: string]: any[] }, entry) => {
        let date = moment.unix(entry.time_stamp);
        let dateLabel = date.format('MM-DD-YY');
        if (!dates[dateLabel]) {
            dates[dateLabel] = [];
        }
        dates[dateLabel].push(entry);
        return dates;
    }, {});

    let dateGroups = Object.keys(entriesGrouped).map((date: string) => {

        let theDate = moment(date, "MM-DD-YY");
        let dateLabel = theDate.format('dddd, MMMM Do, YYYY');

        if ( theDate.isSame(moment(),'day') ) {
            dateLabel = 'Today';
        };

        if ( theDate.isSame(moment().subtract(1,'days'),'day') ) {
            dateLabel = 'Yesterday';
        };

        return {
            label: dateLabel,
            entries: entriesGrouped[date]
        };
    });

    return dateGroups;

}

export const scanForLinks = (text: string | null): string => {
    if (!text) return '';
    let linkWarning = 'Warning! Opening links from unknown contacts could be dangerous and expose you to spyware, viruses, or event data theft. Please be careful before opening any links from unknown sources.';
    // eslint-disable-next-line
    var exp = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    // eslint-disable-next-line
    return text.replace(exp, `<a href='$1' target='_blank' onclick='return confirm(\"${linkWarning}\")'>Attached Link <span class='bp3-icon-standard bp3-icon-share'></span></a>`);
}