import { OnInitialize } from 'overmind'

export const onInitialize: OnInitialize = async ({state,actions,effects}, overmind) => {

    // Register Window Status Event Listener
    actions.notifications.registerVisibilityListener();

    // Get GQL Token Or Redirect To Login
    let token = effects.user.getToken();
    if (!token) {
        window.location.hash = '/login';
        return;
    } else {
        state.user.loggedIn = true;
    }

    // Bootstrap App & Modules
    actions.app.bootstrapApp();
}