import React from "react";
import { Colors, Tooltip } from "@blueprintjs/core";
import styled from "styled-components";

import { useOvermind } from "state";
import Icon from "./Icon";

const AlertsContainer = styled.div`
    margin-right: 5px;
    display: flex;
    align-items: center;
`;

const IconWrapper = styled.div`
    border-radius: 50%;
    background: ${props => props.color};
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NoMicrophoneAlert = () => (
    <Tooltip boundary="window" content="No Microphone Detected" position="bottom">
        <IconWrapper color={Colors.RED3}>
            <Icon name="micOff" width={20} color={Colors.WHITE}/>
        </IconWrapper>
    </Tooltip>
)

const AlertIcons = () => {

    const { state } = useOvermind();

    return (
        <AlertsContainer>
            {state.app.microphone.scanned&&!state.app.microphone.online?(<NoMicrophoneAlert/>):null}
        </AlertsContainer>
    )
}

export default AlertIcons;