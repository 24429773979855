import React from 'react';
import styled from 'styled-components';
import CustomScroll from 'react-custom-scroll';

import { useOvermind } from 'state';

import ConversationListItem from 'components/conversations/ConversationListItem';
import { Conversation } from 'state/conversations/state';
import { Button } from '@blueprintjs/core';

const ConversationListComponent = styled.div`
    height: calc(100vh - 183px);
    background: ${props => props.theme.lightBG};
    border-top: 1px solid ${props => props.theme.altBorder};
`;

const LoadMoreWrapper = styled.div`
    padding: 10px;
    display: flex;
    justify-content: center;
`;

type ConversationListProps = {
    conversations: Conversation[]
}

const ConversationList: React.FC<ConversationListProps> = ({conversations}) => {

    const { state, actions, reaction } = useOvermind();
    const [scrollPosition, setScrollPosition] = React.useState(0);

    React.useEffect(() => {
        return reaction(
            (state) => state.conversations.listScrollToPosition,
            (scrollTo) => {
                if (scrollTo>0) {
                    setScrollPosition(scrollTo);
                } else {
                    setScrollPosition(0);
                }
            },
            { immediate: false }
        )
    }, [reaction]);

    const onLoadMore = () => {
        actions.conversations.loadMore();
    }

    return (
        <ConversationListComponent>
            <CustomScroll 
                heightRelativeToParent="100%"
                scrollTo={scrollPosition}
            >
                {conversations.map((conversation, key)=>(
                    <ConversationListItem key={key} conversation={conversation}/>
                ))}
                {!state.conversations.allLoaded&&conversations.length>=state.conversations.limit&&<LoadMoreWrapper><Button text="Load More" onClick={onLoadMore}/></LoadMoreWrapper>}
            </CustomScroll>    
        </ConversationListComponent>
    );

}

export default ConversationList;