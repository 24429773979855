import React from 'react';
import Lightbox from 'react-image-lightbox';
import styled from 'styled-components'

type ThumbnailProps = {
    url: string;
}

type ImageAttachmentsProps = {
    images: string[];
} 

const ImageThumbnails = styled.div`
    display: flex;
`;

const ThumbNail = styled.div<ThumbnailProps>`
    background-image: ${props => `url('${props.url}')`};
    background-size: cover;
    background-position: center center;
    width: 150px;
    height: 150px;
    cursor: pointer;
`;

const ImageAttachments: React.FC<ImageAttachmentsProps> = ({images}) => {
    
    const [ isOpen, toggleOpen ] = React.useState(false);
    const [ imageIndex, setImageIndex ] = React.useState(0);

    const handleThumbnailClick = (index: number) => {
        setImageIndex(index);
        toggleOpen(true);
    }

    return (
        <div>
            <ImageThumbnails>
                {images.map( (url, k) => <ThumbNail key={k} url={url} onClick={() => handleThumbnailClick(k)} />)}
            </ImageThumbnails>    
            {isOpen && (
                <Lightbox
                    imagePadding={60}
                    mainSrc={images[imageIndex]}
                    nextSrc={images[(imageIndex + 1) % images.length]}
                    prevSrc={images[(imageIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => toggleOpen(false)}
                    onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
                />
            )}
      </div>
    );
}

export default ImageAttachments;