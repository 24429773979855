import { gql, Query } from 'overmind-graphql';
import { OutBoundCallMutationVariables, OutBoundCallMutation } from './_types/OutBoundCallMutation';
import { InBoundCallMutation, InBoundCallMutationVariables } from './_types/InBoundCallMutation';
import { UpdateCallData, UpdateCallDataVariables } from './_types/UpdateCallData';

export const makeOutBoundCall: Query<OutBoundCallMutation,OutBoundCallMutationVariables> = gql`
  mutation OutBoundCallMutation(
    $to_prefix: String
    $to_number: String!,
    $from_number: String!,
    $extension_id: String!
  ) {
    sipinfo: OutBoundCallMutation (
        to_prefix: $to_prefix,
        to_number: $to_number,
        from_number: $from_number,
        extension_id: $extension_id
    ) {
       requestUser
        PAsssertedIdentity
        XUA
        name
        username
        domain
        password
        proxy 
        transport
        regServer
        regTimeout
        regOnAdd
        regHeaders
        XCustomHeader
        regContactParams
        contact {
            first_name
            last_name
        }
    }
  }
` as any;

export const handleInBoundCall: Query<InBoundCallMutation, InBoundCallMutationVariables> = gql`
  mutation InBoundCallMutation(
    $inbound_call_uuid: String!
    $request_action: String!
  ) {
    sipinfo: InBoundCallMutation (
        inbound_call_uuid: $inbound_call_uuid,
        request_action: $request_action
    ) {
        requestUser
        PAsssertedIdentity
        XUA
        name
        username
        domain
        password
        proxy
        transport
        regServer
        regTimeout
        regOnAdd
        regHeaders
        XCustomHeader
        regContactParams
        contact {
            first_name
            last_name
        }
    }
  }
` as any;

export const updateCallData: Query<UpdateCallData, UpdateCallDataVariables> = gql`
  mutation UpdateCallData(
    $message_id: String!,
    $call_status: String,
    $call_duration: Int,
    $answered_by: String,
    $declined_by: String,
    $missed_by: String
  ) 
  {
    entry: UpdateCallData (
      message_id: $message_id,
      call_status: $call_status,
      call_duration: $call_duration,
      answered_by: $answered_by,
      declined_by: $declined_by,
      missed_by: $missed_by
    ) {
      id
      body
      type
      direction
      status
      time_stamp
      attachments {
        url
        filename
        extension
      }
      conversation {
        id
        status
        contact_number
        contact_id
      }
      number {
        id
        label
        didnumber
      }
    }
  }
` as any;