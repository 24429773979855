import { Action, AsyncAction, pipe, mutate } from 'overmind';
import * as Sentry from '@sentry/browser';

import { NotificationPayload } from 'state/notifications/state';
import { UpdateCallDataVariables } from 'state/effects/gql/calls/_types/UpdateCallData';
import { Entry } from 'state/entries/state';
import { CallStatusPayload } from './effects';
import { NotificationToaster } from 'components/notifications/NotificationToaster';

export const updateCalldata: AsyncAction<UpdateCallDataVariables> = async ({state,actions,effects}, payload) => {
    try {

        let { entry } = await effects.gql.mutations.updateCallData(payload);
        if ( entry ) {
            if ( window.location.hash.indexOf('/calls') > 0 ) {
                state.entries.items[entry.id] = entry as Entry;
            }
            if ( state.numbers.currentItem?.number?.id === entry.number?.id && state.conversations.currentItemId === entry.conversation?.id) {
                state.entries.items[entry.id] = entry as Entry;
            }
            Object.values(state.conversations.items).forEach((conversation)=>{
                if ( entry && conversation.last_entry && conversation.last_entry.id === entry.id) {
                    conversation.last_entry = entry as Entry;
                }
            })
        }

    } catch (e) {
        Sentry.captureException(e);
        NotificationToaster.show({
            icon: 'warning-sign',
            intent: 'danger',
            message: 'Error Updating Call Data'
        });
    }
    
} 

export const makeOutBoundCall: AsyncAction<string> = async ({state, actions, effects},toNumber) => {
    try {
        console.log(state.phone.currentDialingPrefix);
        if(state.numbers.currentItem?.number?.didnumber&&state.extensions.currentItem?.extension?.id) {
            let settings = {
                to_prefix: state.phone.currentDialingPrefix || state.settings.dialingPrefix,
                to_number: toNumber,
                from_number: state.numbers.currentItem?.number?.didnumber,
                extension_id: state.extensions.currentItem?.extension?.id,
            };
            console.log(settings)
            let { sipinfo } = await effects.gql.mutations.makeOutBoundCall(settings);
            effects.phone.client.createAgent({
                destination: settings.to_prefix + settings.to_number,
                source: state.numbers.currentItem?.number?.didnumber,
                callEntryId: state.phone.outboundCallEntryId,
                getSipUser: () => {
                    return sipinfo
                }
            });
            effects.phone.client.connectAgent();
            window.setTimeout(()=>{
                effects.phone.client.connectCall();
            },1000);
            state.phone.currentDialingPrefix = state.settings.dialingPrefix;
        }    

    } catch (e) {
        Sentry.captureException(e);
        NotificationToaster.show({
            icon: 'warning-sign',
            intent: 'danger',
            message: 'Error Initializing OutBound Call'
        });
    }
}

export const handleInboundCall: AsyncAction<NotificationPayload['data']> = pipe(
    mutate(async ({state,actions}, notification)=>{
        if (notification.call_uuid&&state.phone.networkStatus!=='connected') {
            let contact = actions.contacts.findContactByNumber(notification.from);
            state.phone.incomingCall = true;
            state.phone.incomingCallFrom = notification.from;
            state.phone.incomingCallTo = notification.to;
            state.phone.incomingCallContactId = contact && contact.id ? contact.id : null;
            state.phone.incomingCallToLabel = notification.to_label || null;
            state.phone.inboundCallEntryId = notification.message_id;
            state.phone.incomingCallId = notification.call_uuid;
            state.phone.activeCallConversationId = notification.conversation_id;
            state.phone.activeCallNumberId = notification.number_id
        } else if (state.phone.networkStatus==='connected') {
            await actions.phone.passOnIncomingCall(notification.message_id)
        }
    }),
    mutate(async ({state,actions}, notification)=>{
        if ( state.numbers.currentItem && state.numbers.currentItem.number && state.numbers.currentItem.number.didnumber ) {
            console.log('Notification', notification)
            await actions.conversations.getConversations();
        }
        if (state.conversations.currentItemId === notification.conversation_id && ( window.location.hash === "#/" || window.location.hash === "" ) ) {
            await actions.conversations.loadConversation({ id: notification.conversation_id });
        }
        if ( window.location.hash === '#/calls' ) {
            actions.entries.getCallLogEntries()
        }
    }),
)

export const handleInboundCallAnswered: AsyncAction<NotificationPayload['data']> = pipe(
    mutate(({state, actions}, notification)=>{
        if (notification.call_uuid) {
            state.phone.incomingCall = false;
            state.phone.incomingCallFrom = null;
            state.phone.incomingCallTo = null;
            state.phone.incomingCallContactId = null;
            state.phone.incomingCallToLabel = null;
            state.phone.inboundCallEntryId = null;
            state.phone.incomingCallId = null;
            state.phone.activeCallNumberId = null;
            state.phone.activeCallConversationId = null;
        }
    }) 
)

export const updateCallTimer: Action<number> = ({state}, time)=>{
    state.phone.callTimer = time;
}

export const resetCallTimer: Action = ({state})=>{
    state.phone.callTimer = 0;
}

export const acceptIncomingCall: AsyncAction = async ({state, actions})=>{
    if (state.phone.inboundCallEntryId) {
        await actions.phone.updateCalldata({
            message_id: state.phone.inboundCallEntryId,
            call_status: 'accepted',
            answered_by: state.user.info?.id
        });
        // if(state.phone.incomingCallContactId) {
        //     actions.contacts.selectContact(state.phone.incomingCallContactId)
        // }
    }
    // Set Pending Action For Next UI Interactions
    state.conversations.pendingAction = 'call';
    state.conversations.pendingActionDirection = 'in';
}

export const rejectIncomingCall: AsyncAction = async ({state,actions}) => {
    if (state.phone.inboundCallEntryId) {
        await actions.phone.updateCalldata({
            message_id: state.phone.inboundCallEntryId,
            call_status: 'declined',
            declined_by: state.user.info?.id
        });
    }
    // Reset Incoming Call State
    state.phone.incomingCall = false;
    state.phone.incomingCallId = null;
    state.phone.incomingCallFrom = null;
    state.phone.incomingCallTo = null;
    state.phone.incomingCallContactId = null;
    state.phone.incomingCallToLabel = null;
    state.phone.inboundCallEntryId = null;
    state.phone.activeCallConversationId = null;
    state.phone.activeCallDirection = '';
}

export const passOnIncomingCall: AsyncAction<string> = async ({state,actions}, inboundCallEntryId) => {
    await actions.phone.updateCalldata({
        message_id: inboundCallEntryId,
        call_status: 'missed',
        missed_by: state.user.info?.id
    });
}

export const missedIncomingCall: AsyncAction = async ({state,actions}) => {
    if (state.phone.inboundCallEntryId) {
        await actions.phone.updateCalldata({
            message_id: state.phone.inboundCallEntryId,
            call_status: 'missed',
            missed_by: state.user.info?.id
        });
    }
    // Reset Incoming Call State
    state.phone.incomingCall = false;
    state.phone.incomingCallId = null;
    state.phone.incomingCallFrom = null;
    state.phone.incomingCallTo = null;
    state.phone.incomingCallContactId = null;
    state.phone.incomingCallToLabel = null;
    state.phone.inboundCallEntryId = null;
}

export const transferCall: Action<string> = ({state, actions, effects}, destination) => {
    effects.phone.client.transferCall('17869100574');
}

export const holdCall: Action = ({state, actions, effects}) => {
    effects.phone.client.holdCall();
}

export const unHoldCall: Action = ({state, actions, effects}) => {
    effects.phone.client.unHoldCall();
}

export const connectInboundCall: AsyncAction = async ({state, actions, effects}) => {
    if (!state.phone.incomingCallId) return;
    try {

        let payload = {
            inbound_call_uuid: state.phone.incomingCallId,
            request_action: 'answer' 
        }

        let { sipinfo } = await effects.gql.mutations.handleInBoundCall(payload);

        if(sipinfo&&sipinfo.requestUser) {

            effects.phone.client.createAgent({
                destination: sipinfo?.requestUser,
                source: state.phone.incomingCallTo || '',
                callEntryId: state.phone.inboundCallEntryId,
                getSipUser: () => {
                    return sipinfo
                }
            });

            effects.phone.client.connectAgent();

            state.phone.incomingCall = false;
            state.phone.incomingCallId = null;
            state.phone.incomingCallFrom = null;
            state.phone.incomingCallTo = null;
            state.phone.incomingCallContactId = null;
            state.phone.incomingCallToLabel = null;

            window.setTimeout(()=>{
                effects.phone.client.connectCall();
            },1000);

        }    

    } catch (e) {
        Sentry.captureException(e);
        NotificationToaster.show({
            icon: 'warning-sign',
            intent: 'danger',
            message: 'Error Initializing InBound Call'
        });
    }
}

export const sendDTMF: Action<number> = ({effects}, digit) => {
    effects.phone.client.sendDTMF(digit);
}

export const endCall: Action = ({effects}) => {
    effects.phone.client.endCall();
}

export const muteCall: Action = ({effects}) => {
    effects.phone.client.muteCall();
}

export const unMuteCall: Action = ({effects}) => {
    effects.phone.client.unMuteCall();
}

export const onNetworkStatusUpdate: Action<string> = ({state}, value) => {
    state.phone.networkStatus = value;
}

export const onCallStatusUpdate: AsyncAction<CallStatusPayload> = async ({state, actions}, payload) => {
    state.phone.callStatus = payload.status;
    if (payload.callEntryId) {
        await actions.phone.updateCalldata({
            message_id: payload.callEntryId,
            call_status: payload.status,
            call_duration: payload.duration
        });
    }
    if (payload.status==='ended') {
        state.phone.outboundCallEntryId = null;
        state.phone.inboundCallEntryId = null;
        state.phone.activeCallConversationId = null;
        state.phone.activeCallDirection = '';
        state.phone.activeCallConversationId = null;
        state.phone.activeCallNumberId = null;
        state.phone.callTimer = 0;
    }
}