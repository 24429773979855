import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import {CodeLogin,PasswordLogin} from 'LoginRoot';
import AppRoot from 'AppRoot';

const Navigation = () => {

    return (
        <Router>
            <Routes>
                <Route path="*" element={<AppRoot/>} />
                <Route path="login" element={<PasswordLogin/>} />
                <Route path="login/scan" element={<CodeLogin/>} />
            </Routes>
        </Router>
    );
}

export default Navigation;