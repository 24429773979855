import React from 'react';
import styled from 'styled-components';
import { Tab, Tabs } from '@blueprintjs/core';

import { useOvermind } from 'state';
import { State as ConversationState } from 'state/conversations/state';

import ConversationList from 'components/conversations/ConversationList';
import LoadingComponent from 'components/ui/LoadingComponent';

const WrapperComponent = styled.div`
    background: ${props => props.theme.lightBG};
    height: calc(100vh - 107px);
    border-right: 1px solid #CED9E0;
    display: grid;
`;

const ConversationTabsComponent = styled.div`
    background: ${props => props.theme.lightBG};
    border-right: 1px solid #CED9E0;
    grid-area: ConversationTabs;
`;

type ConversationTabsProps = {
    className?: any;
}

const ConversationTabs: React.FC<ConversationTabsProps> = ({className}) => {

    const { state, actions, reaction } = useOvermind();

    React.useEffect(() => {
        return reaction(
            (state) => state.numbers.currentItemId,
            (currentNumber) => {
                if (currentNumber) {
                    actions.conversations.loadConversations();
                }
            },
            {immediate:true}
        )
    }, [reaction,actions.conversations]);

    const onTabChange = (newTabId: ConversationState['currentView']) => {
        actions.conversations.loadView(newTabId);
    }

    const renderLoading = () => (
        <WrapperComponent className={className}>
            <LoadingComponent/>
        </WrapperComponent>
    );

    const renderConversations = () => (
        <ConversationTabsComponent>            
            <Tabs id="conversations" large renderActiveTabPanelOnly onChange={onTabChange} selectedTabId={state.conversations.currentView} className={className} >
                <Tab id="inbox" title="All" panel={<ConversationList conversations={state.conversations.itemsList}/>}/>
                <Tab id="unread" title="Unread" panel={<ConversationList conversations={state.conversations.itemsList} />} />
                <Tab id="archived" title="Archived" panel={<ConversationList conversations={state.conversations.itemsList} />} />            
                <Tabs.Expander />
            </Tabs>
        </ConversationTabsComponent>
    );

    return state.conversations.loading ? renderLoading() : renderConversations();

}

const ConversationTabsWrapper = styled(ConversationTabs)`
    & > .bp3-tab-list {
        padding: 15px 20px 0;
    }
`;

export default ConversationTabsWrapper;