import { State } from "./state";

export const saveCurrentNumber = (currentItemId: State['currentItemId']) => {
    if(currentItemId) {
        localStorage.setItem('numbers.currentItemId', currentItemId);
    }
}

export const loadCurrentNumber = () => {
    return localStorage.getItem('numbers.currentItemId');
}