import { State } from "./state";

export const saveCurrentExtension = (currentItemId: State['currentItemId']) => {
    if(currentItemId) {
        localStorage.setItem('extensions.currentItemId', currentItemId);
    }
}

export const loadCurrentExtension = () => {
    return localStorage.getItem('extensions.currentItemId');
}