import { map } from 'overmind';

export const getFirstConversationID = () => map(({state}): string | null => {
    let nextConversationId = null;

    if (state.conversations.itemsList.length) {
        nextConversationId = state.conversations.itemsList[0].id;
    }

    if (state.conversations.preloadItemId) {
        nextConversationId = state.conversations.preloadItemId;
    }

    return nextConversationId;
});