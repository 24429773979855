import React from 'react';
import { Provider as StateProvider } from 'overmind-react';
import { createOvermind } from 'overmind';

import { config } from 'state';

import Layout from 'Layout';

const AppRoot = () => {
    const overmind = createOvermind(config,{
        devtools: process.env.NODE_ENV==='development'
    });
    return (
        <StateProvider value={overmind}>
            <Layout/>
        </StateProvider>
    );
}

export default AppRoot;