import React from 'react';
import styled from 'styled-components';
import { Button, FileInput, Dialog, Classes, TextArea, Popover } from '@blueprintjs/core';
import emojis from 'emoji-mart/data/google.json';
import { NimblePicker } from 'emoji-mart';

import { useOvermind } from 'state';
import Icon from 'components/ui/Icon';

const ConversationInputComponent = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

const InputControls = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

const InputElementsWrapper = styled.span`
    & .bp3-button, & .bp3-popover-wrapper {
        margin-right: 5px;
    }
    & .bp3-button:last-child {
        margin-right: 0 !important;
    }
`;

type DialogProps = {
    file: File | null;
    isOpen: boolean;
    onFileSelect(files: FileList): void;
    onClose(): void;
}

type AttachmentDialogProps = {
    onImageSend(): void;
} & DialogProps;

type FaxDialogProps = {
    onFaxSend(): void;
} & DialogProps;

const AttachmentDialog: React.FC<AttachmentDialogProps> = ({file, onFileSelect, isOpen, onClose, onImageSend}) => {

    return (
        <Dialog
            title='Send Image Attachment'
            isOpen={isOpen}
            onClose={onClose}
        >
            <div className={Classes.DIALOG_BODY}>
                <FileInput 
                    fill
                    large
                    text={file?file.name:'Select Image'}
                    buttonText='Select'
                    hasSelection={!!file}
                    onInputChange={(e: any)=>onFileSelect(e.target.files)}
                />
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button text='Send Image' intent='primary' large onClick={onImageSend}/>
                </div>
            </div>
        </Dialog>
    );

}

const FaxDialog: React.FC<FaxDialogProps> = ({file, onFileSelect, isOpen, onClose, onFaxSend}) => {

    return (
        <Dialog
            title='Send Fax Attachment'
            isOpen={isOpen}
            onClose={onClose}
        >
            <div className={Classes.DIALOG_BODY}>
                <FileInput 
                    fill
                    large
                    text={file?file.name:'Select PDF'}
                    buttonText='Select'
                    hasSelection={!!file}
                    onInputChange={(e: any)=>onFileSelect(e.target.files)}
                />
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button text='Send Fax' intent='primary' large onClick={onFaxSend}/>
                </div>
            </div>
        </Dialog>
    );

}

const ConversationInput: React.FC = () => {

    const { actions } = useOvermind();
    const [ message, setMessage ] = React.useState('');
    const [ file, setFile ] = React.useState<File|null>(null);
    const [ faxOpen, toggleFax ] = React.useState(false);
    const [ attachmentsOpen, toggleAttachments ] = React.useState(false);
    const [ emojiPickerOpen, toggleEmojiPicker ] = React.useState(false);

    const textInput = React.useRef<HTMLTextAreaElement|null>();

    const handleSubmit = () => {
        if (message.length) {
            actions.entries.createEntry({
                type: 'text',
                body: message,
                file: file
            });
            setMessage('');
            setFile(null);
        }
    }

    const onImageSend = () => {
        actions.entries.createEntry({
            type: 'text',
            file: file
        });
        setFile(null);
        toggleAttachments(false);
    }

    const onFaxSend = () => {
        actions.entries.createEntry({
            type: 'fax',
            file: file
        });
        setFile(null);
        toggleFax(false);
    }

    const handleEnterKey = (e: any) => {
        if (e.ctrlKey && e.key === "Enter") {
            handleSubmit();
            if (textInput.current){
                textInput.current.style.height = 'auto';
            }
        }
    }

    const onFileSelect = (e: FileList) => {
        setFile(e[0])
    }

    const onAttachmentsDialogClose = () => {
        toggleAttachments(false);
    }

    const onFaxDialogClose = () => {
        toggleFax(false);
    }

    const insertEmoji = (emoji: any) => {
        setMessage(message+emoji.native);
    }

    const handleToggleEmojiPicker = (state: any) => {
        if (typeof state === 'boolean') {
            toggleEmojiPicker(state);
        }
    }

    const InputElements = () => {
        return (
            <InputElementsWrapper>
                <Popover position="bottom" isOpen={emojiPickerOpen} onInteraction={handleToggleEmojiPicker}>
                <Button outlined minimal icon={<Icon name='smile' color='#5c7080' width={20}/>}/>
                    <div>
                        {/* 
                        //@ts-ignore */}
                        <NimblePicker autoFocus sheetSize={16} set='google' data={emojis} onClick={(emoji, event) => insertEmoji(emoji)} onSelect={handleToggleEmojiPicker}/>
                    </div>
                </Popover>
                <Button outlined minimal icon={<Icon name='paperclip' color='#5c7080' width={20}/>} onClick={()=>toggleAttachments(true)}/>
                {/* <Button outlined minimal icon={<Icon name='fax' color='#5c7080' width={20}/>} onClick={()=>toggleFax(true)}/> */}
            </InputElementsWrapper>
        )
    }

    return (
        <ConversationInputComponent>
            <TextArea
                growVertically
                placeholder="Create a new message"
                inputRef={ref=>{textInput.current=ref}}
                value={message}
                onChange={(e: any)=>setMessage(e.target.value)}
                onKeyPress={handleEnterKey}
            />
            <InputControls>
                <InputElements/>
                <Button intent='primary' text='Send Message' rightIcon={<Icon name="send" color="#FFF" width={24}/>} onClick={handleSubmit}/>
            </InputControls>
            <AttachmentDialog isOpen={attachmentsOpen} onClose={onAttachmentsDialogClose} file={file} onFileSelect={onFileSelect} onImageSend={onImageSend}/>
            <FaxDialog isOpen={faxOpen} onClose={onFaxDialogClose} file={file} onFileSelect={onFileSelect} onFaxSend={onFaxSend}/>
        </ConversationInputComponent>
    );
}

export default ConversationInput;