type State = {
    environment: string;
    loading: boolean;
    error: string | null;
    route: string;
    online: boolean;
    microphone: {
        scanned: boolean;
        online: boolean;
    }
}

export const state: State = {
    environment: 'production',
    loading: true,
    error: null,
    route: 'conversations',
    online: true,
    microphone: {
        scanned: false,
        online: false
    }
}