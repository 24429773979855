import { Action, pipe, mutate, waitUntil, catchError } from "overmind";
import * as Sentry from '@sentry/browser';
import { generateDeviceId } from 'utils/env';
import { NotificationToaster } from "components/notifications/NotificationToaster";
import { audioActions as audio } from './audio';

export { audio };

type NewNavigationStateOptions = {
    route: string;
    conversation_id?: string;
    callback?(): any;
}

export const updateNavigationState: Action<NewNavigationStateOptions> = pipe(
    mutate(({state}, options) => {

        state.app.route = options.route;
    
        if ( options.conversation_id ) {
            state.conversations.preloadItemId = options.conversation_id;
        }
    
        state.entries.items = {};
        state.entries.loading = true;
        state.conversations.loading = true;
        state.conversations.loadingItem = true;
        state.conversations.offset = 0;

    }),
    waitUntil((state)=>{
        return state.conversations.loadingItem === false;
    }),
    mutate(({state}, options) => {    
        if ( options.callback && typeof options.callback ==='function') {
            options.callback();
        }
    }),
)

// BootStrap The App By Performing Loading Sequence
export const bootstrapApp: Action = pipe(
    // Set Env
    mutate(({state})=>{
        state.app.environment = process.env.NODE_ENV || 'production';
    }),
    // Set App State To Loading
    mutate(({state})=>{
        state.app.loading = true;
    }),
    mutate(({state})=>{
        let currentRoute = window.location.hash.replace('#/','');
        if (currentRoute.length) {
            state.app.route = currentRoute;
        }
    }),
    // Bootstrap GQL Client
    mutate(({effects})=>{
        let token = effects.user.getToken();
        effects.gql.initialize({
            endpoint: (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_GQL_API_URL : process.env.REACT_APP_GQL_API_URL_DEV) || '',
            headers: () => ({
                'Authorization': `Bearer ${token}`,
                'device-id': generateDeviceId()
            })
        });
    }),
    // Bootstrap Notifications Agent
    mutate(async ({state, actions, effects})=>{
        try {
            console.log('Bootstrap Notifications');
            await effects.notifications.agent.initialize({
                platform: window.platform,
                onInboundText: actions.conversations.onConversationUpdated,
                onInboundCall: actions.phone.handleInboundCall,
                onInboundCallAnswered: actions.phone.handleInboundCallAnswered,
                syncNotificationStatus: actions.notifications.syncNotificationStatus,
                syncSubscriptionStatus: actions.notifications.syncSubscriptionStatus
            });
        } catch (e) {
            Sentry.captureException(e);
        }
    }),
    // Bootstrap SIP Phone Instance
    mutate(({actions,effects})=>{
        effects.phone.client.initialize({
            onNetworkStatusUpdate: actions.phone.onNetworkStatusUpdate,
            onCallStatusUpdate: actions.phone.onCallStatusUpdate
        });
    }),
    // Catch Any Errors
    catchError(({state,actions}, error) => {        
        switch (error.message) {
            default:
                Sentry.captureException(error);
                state.app.error = error.message;
                break;
        }
    }),
    // Set App State To Loaded
    mutate(({state, actions})=>{
        state.app.loading = false;
        actions.app.bootstrapSession();
    }),
);

export const bootstrapSession: Action = pipe(
    waitUntil((state)=>{
        console.log('Waiting For Notification Bootstrap');
        console.log(state.notifications.permission);
        return ( state.notifications.permission === 'granted' || state.notifications.permission === 'denied' );
    }),
    mutate(({state, actions})=>{
        state.app.loading = true;
    }),
    // Get User Info
    mutate(async ({state, actions})=>{
        try {
            let user = await actions.user.loadUser();
            if (user) {
                // Set Sentry User For Error Logging
                Sentry.setUser({
                    id: user.id,
                    email: user.email,
                    username: `${user.first_name}-${user.last_name}`
                });
            }
        } catch (e) {
            throw new Error(e)
        }
    }),
    // Bootstrap Local Settings
    mutate(async ({actions})=>{
        await actions.settings.loadUserSettings();
    }),
    // Bootstrap Extensions
    mutate(async ({actions})=>{
        await actions.extensions.bootstrapExtensions();
    }),
    // Bootstrap Numbers
    mutate(async ({actions})=>{
        await actions.numbers.bootstrapNumbers();
    }),
    // Load Contacts Numbers
    mutate(async ({actions})=>{
        await actions.contacts.getContacts();
    }),
    // Update Notification Subscription Settings
    mutate(({actions,effects})=>{
        actions.notifications.updateNotificationHooks();
        effects.notifications.agent.registerNotificationListener();
    }),
    // Catch Any Errors
    catchError(({state,actions}, error) => {       
        switch (error.message) {
            case 'Token algorithm not supported':
            case 'Token Is Not Active':
            case 'Token & Device ID Mismatch':
                state.app.error = 'Session In Expired';
                actions.user.logOut();
                break;
            default:
                Sentry.captureException(error);
                state.app.error = error.message;
                break;
        }
    }),
    mutate(({state, actions})=>{
        state.app.loading = false;
        actions.app.bootstrapUtils();
    }),
);

export const bootstrapUtils: Action = pipe(
    mutate(async ({actions, effects})=>{
        await effects.app.audio.initialize({
            detectAudioDevices: actions.app.audio.detectAudioDevices
        })
    }),
    mutate(({state})=>{
        if (state.notifications.permission!=='granted') {
            NotificationToaster.show({
                icon: 'warning-sign',
                intent: 'danger',
                timeout: 0,
                message:'Notifications are disabled! You will not be able to receive incoming texts or calls.'
            });
        }
    }),
)