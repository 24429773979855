import { gql, Query } from 'overmind-graphql';
import { CreateEntry, CreateEntryVariables } from './_types/CreateEntry';

export const createEntry: Query<CreateEntry, CreateEntryVariables> = gql`
    mutation CreateEntry ($input: SendMessageInput!, $file: FileUpload) {
        entry: CreateMessageMutation(input: $input, file: $file) {
            id
            type
            status
            direction
            body
            time_stamp
            attachments {
                url
                filename
                extension
            }
        }
    }
` as any;