import React from 'react';
import styled from 'styled-components';
import { H3, Button, Menu, Popover } from '@blueprintjs/core';
import classnames from 'classnames';

import { useOvermind } from 'state';
import { getContactLabel, getMatchingDetails } from 'state/contacts/utils';
import { formatPhoneNumber, lookUpAreaCode } from 'utils/numbers';
import { Contact, ContactDetail } from 'state/contacts/state';
import { capitalizeWord } from 'utils/strings';
import ContactView from 'components/contacts/ContactView';
import Icon from 'components/ui/Icon';

const ConversationViewHeaderComponent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid ${props => props.theme.altBorder};
`;

const ContactHeading = styled.div`

`;

const ConversationTitle = styled(H3)`
    margin-bottom: 0;
`;

const ConversationSubTitle = styled.span`

`;

const ConversationActions = styled.div`
    display: flex;
    & > * {
        margin-right: 5px;
    }
    & > *:last-child {
        margin-right: 0;
    }
`;

type ContactMenuProps = {
    contact: Contact;
    onDetailSelect(contactDetail: ContactDetail): void;
}

const ConversationViewHeader: React.FC = () => {

    const { state, actions } = useOvermind();

    const conversation = state.conversations.currentItem;
    const contact = state.contacts.currentItem;

    if (!conversation) return null;

    const handleLaunchCall = async () => {
        await actions.conversations.createConversationEntry({
            type: 'call',
            destinationNumber: conversation.contact_number
        });
    }

    const handleArchive = async () => {
        await actions.conversations.archiveConversation(conversation.id);
    }

    const handleUnArchive = async () => {
        await actions.conversations.unArchiveConversation(conversation.id);
    }

    const handleDelete = async () => {
        await actions.conversations.deleteConversation(conversation.id);
    }

    let contactLabel = contact ? getContactLabel(contact) : formatPhoneNumber(conversation.contact_number);

    let contactLocation = lookUpAreaCode(conversation.contact_number );

    let conversationTitleLabel = '';
    let conversationSubTitle = contactLocation;
    if ( contact && contact.contactdetails.length > 1 ) {
        let contactDetail = getMatchingDetails(contact, conversation.contact_number);
        if (contactDetail) {
            conversationTitleLabel = ` (${capitalizeWord(contactDetail.label)})`;
            conversationSubTitle = formatPhoneNumber(contactDetail.value);
        } else {
            conversationSubTitle = formatPhoneNumber(conversation.contact_number);
        }
    }

    let editContactHandler: Function | null = null;
    const setEditContactHandler = (handler: ()=>void) => {
        editContactHandler = handler;
    }

    const handleEditContact = () => {
        if (editContactHandler) {
            editContactHandler();
        }
    }

    return (
        <ConversationViewHeaderComponent>
            
            <ContactHeading className={classnames({'bp3-skeleton':state.conversations.loadingItem})}>
                <ConversationTitle>{contactLabel}{conversationTitleLabel}</ConversationTitle>
                <ConversationSubTitle>{conversationSubTitle}</ConversationSubTitle>
            </ContactHeading>
            
            <ConversationActions className={classnames({'bp3-skeleton':state.conversations.loadingItem})}>

                <Popover
                    position="bottom"
                    content={
                        <Menu>
                            <Menu.Item icon={<Icon name="phone" width={20}/>}  text={`Call ${contactLabel}${conversationTitleLabel}`} onClick={handleLaunchCall}/>
                            <Menu.Divider/>
                            {contact?(
                                <Menu.Item icon={<Icon name="editContact" width={20}/>} text="Edit Contact" onClick={handleEditContact}/>
                            ) : (
                                <Menu.Item icon={<Icon name="addContact" width={20}/>}  text="Save Contact" onClick={handleEditContact}/>
                            )}
                            <Menu.Divider/>
                            {conversation.status==='archived'?(
                                <React.Fragment>
                                    <Menu.Item icon={<Icon name="restore" width={20}/>} text="UnArchive Conversation" onClick={handleUnArchive}/>
                                    <Menu.Item intent="danger" icon={<Icon name="trash" width={20}/>}  text="Delete Conversation" onClick={handleDelete}/>
                                </React.Fragment>
                            ):(
                                <Menu.Item icon={<Icon name="archive" width={20}/>} text="Archive Conversation" onClick={handleArchive}/>
                            )}
                        </Menu>
                    }
                >
                    <Button icon="more" outlined/>
                </Popover>

                <ContactView setEditContactHandler={setEditContactHandler}/>

            </ConversationActions>

        </ConversationViewHeaderComponent>
    );
}

export default ConversationViewHeader;