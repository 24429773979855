import React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/browser';
import ErrorComponent from './ErrorComponent';

const WrapperComponent = styled.div`
    background: ${props => props.theme.mainBG};
    height: 100vh;
    display: grid;
`;

type ErrorBoundaryState = {
    eventId: string;
    hasError?: boolean;
    error: string;
}

class ErrorBoundary extends React.Component<{},ErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = { 
            eventId: '',
            error: ''
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({eventId});
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <WrapperComponent>
                    <ErrorComponent error={this.state.error}/>
                </WrapperComponent>
            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary