import { derived } from 'overmind';
import { GetAnsweredCallsLog_entries } from 'state/effects/gql/entries/_types/GetAnsweredCallsLog';
import { GetFilteredEntries_entries } from 'state/effects/gql/entries/_types/GetFilteredEntries';
import { sortByActivity } from 'state/entries/utils';

interface GenericEntry {
    id: string;
    direction: 'in' | 'out' | string;
    type: 'call' | 'text' | 'fax' | 'voicemail' | string;
    time_stamp: number;
    conversation?: {
        id?: string;
    }
}

export interface CallEntry extends GenericEntry {
    status: 'connecting' | 'connected' | 'missed' | 'declined' | 'ended' | string;
    duration: number;
    body: string;
}

export interface MessageAttachments {
    url: string;
    filename: string; 
    extension: string;
}

export interface MessageEntry extends GenericEntry {
    status: 'read' | 'unread'| string;
    body: string | null;
    attachments?: MessageAttachments[]
}

export type FilteredEntry = GetFilteredEntries_entries;
export type CallLogEntry = GetAnsweredCallsLog_entries;

export type FaxEntry = MessageEntry;
export type VoicemailEntry = MessageEntry;

export type Entry = CallEntry | MessageEntry | FaxEntry | VoicemailEntry;

export type State = {
    loading: boolean;
    loadingMore: boolean;
    moreLoaded: number | null;
    sending: boolean;
    limit: number;
    offset: number;
    sort: 'asc' | 'desc';
    message_type: 'text' | 'call' | 'fax' | 'voicemail' | 'all';
    direction: 'in' | 'out' | 'all';
    displayOrder: 'newestOnTop' | 'newestOnBottom';
    items: {
        [key: string]: Entry
    },
    itemsList: Entry[]
}

export const state: State = {
    loading: false,
    loadingMore: false,
    moreLoaded: null,
    sending: false,
    limit: 20, 
    offset: 0,
    sort: 'desc',
    message_type: 'all',
    direction: 'all',
    displayOrder: 'newestOnBottom',
    items: {},
    itemsList: derived( (state: State) => sortByActivity(Object.values(state.items), state.displayOrder) ),
}