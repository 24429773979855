export type NotificationPayload = {
    data: {
        type: 'InboundText' | 'InboundCall' | 'InboundCallAnswered',
        conversation_id: string;
        number_id: string;
        message_id: string;
        to: string;
        from: string;
        call_uuid?: string;
        to_label?: string;
    }
}

type State = {
    windowVisible: boolean;
    permission: 'default' | 'granted' | 'denied';
}

export const state: State = { 
    windowVisible: true,
    permission: 'default'
}