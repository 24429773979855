import { AsyncAction } from "overmind";
import { NotificationToaster } from "components/notifications/NotificationToaster";

type AudioInitOptions = {
    detectAudioDevices(): void;
}

export const audioEffects = (()=>{

    return {
        initialize: async (options: AudioInitOptions) => {

            try {
                console.log('Initializing Audio System');

            options.detectAudioDevices();

            navigator.mediaDevices.ondevicechange = () => {
                options.detectAudioDevices();
            }
            } catch (e) {
                console.error(e)
            }

        }
    }

})()

const detectAudioDevices: AsyncAction = async ({state}) => {
    console.log('Scanning For Audio Devices');
    let devices: (InputDeviceInfo | MediaDeviceInfo)[] = await navigator.mediaDevices.enumerateDevices();
    let detectedMicrophone = devices.find((device)=>{
        return device.kind === 'audioinput';
    });
    state.app.microphone.scanned = true;
    if (detectedMicrophone) {
        console.log('Input Device Found');
        state.app.microphone.online = true;
        NotificationToaster.dismiss('microphone-error');
    } else {
        console.log('No Input Device Found');
        NotificationToaster.show({
            icon: 'warning-sign',
            intent: 'danger',
            timeout: 0,
            message:'No Microphone Detected! You will not be able to make or receive calls.'
        },'microphone-error');
    }
}

export const audioActions = {
    detectAudioDevices
}